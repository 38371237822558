import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import $ from 'jquery';
import { appName, copyRight, apiUrl } from '../../utils/Variables';
import * as Reqst from '../../utils/Reqst';
import Sanitize from '../../utils/Sanitize';
import { notify } from 'react-notify-toast';
import { Helmet } from 'react-helmet';
import { Row, Col } from 'react-bootstrap';
import swal from 'sweetalert';
import Cookies from 'universal-cookie';
import ReactFileReader from 'react-file-reader';
import { Button, Tooltip, Loading, Notification, Select, DatePicker } from 'element-react';
import NOIMAGE from '../../assets/img/noimage.jpg';
import AppTitle from '../../containers/AppTitle';
import FullPageLoader from '../../containers/FullPageLoader';
import SimpleReactValidator from 'simple-react-validator';

class EditChurch extends Component {
	constructor(props) {
		super(props);
		this.state = {
			saveText: 'Update',
			isRedirect: false,
			dataLoading: false,
			churchdata: [],
			dioceses : [],
			parishes : [],
			ch_name : '',
			ch_place : '',
			ch_city : '',
			ch_addr : '',
			ch_pin : '',
			ch_phone : '',
			ch_mobile : '',
			ch_mail : '',
			show: false,
			ch_web : '',
			files : '',
			churchview : false,
			photoname : '',
			ch_mrghall : 0,
			ch_councel : 0,
			ch_legal : 0,
			ch_bookstall : 0,
		    ch_med : 0,
			ch_mrgbue : 0,
			files : '',
			photoname : '',
			files1 : '',
			photoname1 : '',
			files2 : '',
			photoname2 : '',
			files3 : '',
			photoname3 : '',
			ispasalica : 0,
			isshrine : 0,
			iscathedral : 0,
			ispc : 0,
			issubsta : 0,
			expired: false,
			dioLoading: false,
			d_erected: new Date()
		}
		this.validator = new SimpleReactValidator();
		this.onChange = this.onChange.bind(this);
		this.onDioChange = this.onDioChange.bind(this);
		this.handleFiles = this.handleFiles.bind(this);
		this.handleFiles1 = this.handleFiles1.bind(this);
		this.handleFiles2 = this.handleFiles2.bind(this);
		this.handleFiles3 = this.handleFiles3.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	onChange(e){
		this.setState({[e.target.name] : e.target.value});
	}

	onDioChange(e){
		this.state.diocese = e.target.value;
		var d = this.state.churchdata;
		d['diociese'] = e.target.value;
		this.setState({churchdata : d});
		this.loadParish();
	}

	handleFiles(files){
		this.setState({ files: files.base64,photoname : files.fileList[0]['name']});
	}
	
	handleFiles1(files){
		this.setState({ files1: files.base64,photoname1 : files.fileList[0]['name']});
	}
	
	handleFiles2(files){
		this.setState({ files2: files.base64,photoname2 : files.fileList[0]['name']});
	}
	
	handleFiles3(files){
		this.setState({ files3: files.base64,photoname3 : files.fileList[0]['name']});
	}

	handleSubmit(e){
		try{
		
			e.preventDefault();
			 if (this.validator.allValid()) {
				this.setState({saveText: 'Updating..'});
				var token = Sanitize.getStoreageItem('accessToken');
				Reqst.axPost('api/v1/updatechurch',$("#frmChurch").serialize(),token).then(res=>{
					if(res['data'].status=='1')
					{
						document.getElementById('frmChurch').reset();
						Notification({message:res['data'].message,type: 'success'});
						this.setState({saveText:'Update'});
						this.setState({ files: '',photoname : ''});
						this.setState({ files1: '',photoname1 : ''});
						this.setState({ files2: '',photoname2 : ''});
						this.setState({ files3: '',photoname3 : ''});
						this.loadChurchData();
					}
					else if(res['data'].status=='2')
					{
						  alert(res['data'].message);
						  this.setState({saveText:'Update'});
						  this.setState({expired:true});
					}
					else
					{
						Notification({message:res['data'].message,type: 'error'});
						this.setState({saveText:'Update'});
					}
				});
		  }
		   else {
			this.validator.showMessages();
			// rerender to show messages for the first time
			this.forceUpdate();
		 }
		}
		catch(e){
			console.log(e);
		}
	}
	
	componentDidMount(){
		this.loadDioceses();
		this.loadChurchData();
	}

	loadChurchData(){
		this.setState({churchdata : [],dioLoading:true});
		var token = Sanitize.getStoreageItem('accessToken');
		var churchid = this.props.match.params.id;
		Reqst.axGet('api/v1/getchurchbyid/'+churchid,token).then(res=>{
			
			let result = res['data'];
			
			if(result){
				if(result.status=='1')
				{
					var res = result.data;
					this.setState({churchdata: res, dioLoading:false,diocese : res['diociese']});
					this.setState({
						ch_name : res['name'],
						ch_id : res['id'],
						ch_domain : res['domain'],
						ch_addr : res['address'],
						ch_pin : res['pincode'],
						country : res['country'],
						ch_dist : res['diociese'],
						region : res['state'],
						ch_phone : res['phone'],
						ch_mobile : res['mobile'],
						ch_mail : res['email'],
						ch_web : res['website'],
						ch_parish : res['parish'],
						ch_prist : res['parishprist'],
						ch_asstprist : res['asstparishprist'],
						ch_fams : res['nooffamilies'],
						ch_about : res['content'],
						ch_secmob : res['secondarymobile'],
						ch_geoip : res['geoip'],
						ch_fest_month : res['festivaldays'],
						ch_fest_day : res['churchfestivaldate'],
						ch_type : res['type'],
						ch_mrghall : res['mrghall'],
						ch_councel : res['councelctr'],
						ch_legal : res['legalctr'],
						ch_bookstall : res['bookstall'],
						ch_med : res['medicalctr'],
						ch_mrgbue : res['mrgbeuree'],
						photo : res['cimage1'],
						photo1 : res['cimage2'],
						photo2 : res['cimage3'],
						photo3 : res['cimage4'],
						ispasalica : res['ispasalica'],
						isshrine : res['isshrine'],
						iscathedral : res['iscathedtral'],
						ispc : res['ispc'],
						issubsta : res['issubstation']
				   });
					this.loadParish();
				}
				else if(result.status=='2')
				{
					alert(result.message);
					this.setState({dioLoading:false});
					this.setState({expired:true});
				}
				else
				{
					Notification({message:result.message,type: 'error'});
					this.setState({dioLoading:false});
				}
			}
			else{
				
			Notification({message:'Something went wrong Try again...',type: 'error'});
			this.setState({parishLoading:false});
			}
		});
	}
	
	loadDioceses(){
		
		this.setState({dioceses : [],dioLoading:true});
		var token = Sanitize.getStoreageItem('accessToken');
		Reqst.axGet('api/v1/getdioceseslist',token).then(res=>{
			
			let result = res['data'];
			
			if(result){
				if(result.status=='1')
				{
					this.setState({dioceses: result.data, dioLoading:false});
				}
				else if(result.status=='2')
				{
					alert(result.message);
					this.setState({dioLoading:false});
					this.setState({expired:true});
				}
				else
				{
					Notification({message:result.message,type: 'error'});
					this.setState({dioLoading:false});
				}
			}
			else{
				
					Notification({message:'Something went wrong Try again...',type: 'error'});
					this.setState({dioLoading:false});
			}
		});
	}

	loadParish(){
		
		this.setState({parishes : [],parishLoading:true});
		var diocese = this.state.diocese;
		var token = Sanitize.getStoreageItem('accessToken');
		Reqst.axGet('api/v1/getparishbydio/'+diocese,token).then(res=>{
			let result = res['data'];
			if(result){
				if(result.status=='1')
					{
						this.setState({parishes: result.data,parishLoading:false});
					}
					else if(result.status=='2')
					{
						alert(result.message);
						this.setState({parishLoading:false});
						this.setState({expired:true});
					}
					else
					{
						Notification({message:result.message,type: 'error'});
						this.setState({parishLoading:false});
					}
				}
				else{
					
				Notification({message:'Something went wrong Try again...',type: 'error'});
				this.setState({parishLoading:false});
				}
		});
	}

	getValue(field){
		var d = this.state.churchdata;
		return d[field];
	}

	setValue(field,e){
		var d = this.state.churchdata;
		d[field] = e.target.value;
		this.setState({churchdata : d,[e.target.name] : e.target.value});
	}

	setChkValue(field,e){
		var d = this.state.churchdata;
		if(e.target.checked){
			d[field] = 1;
		}
		else{
			d[field] = 0;
		}
		this.setState({churchdata : d});
	}
	
	render() {


		if (this.state.expired) {
			return (<Redirect to="/login" />);
		}
		var dlist = this.state.dioceses;
		var plist = this.state.parishes;

		return (
			<div>

				<Helmet>
					<title>{appName} | Configuration</title>
				</Helmet>


				<div className="topbar-header">
					<div className="topbar-header__content">

						<AppTitle
							menuLevel1="Configuration"
							menuLevel2="Churches"
							menuLevel3="Edit Church"
							menuLevel2link="#/configuration/churches"
							isbreadcumb={true}
						/>
						<div className="topbar_buttons">

						</div>
					</div>
				</div>

				<div className="main_content">
					<section>
						<div className="" >

							<div className="card" >
								<h4 className="card-header bg-info text-white">Edit Church</h4>
								<div className="card-body" >
									<form action="" method="post" encType="multipart/form-data" id="frmChurch" onSubmit={this.handleSubmit} >
										<input type="hidden" name="i__id" value={Sanitize.getStoreageItem('userId')} />
										<input type="hidden" name="i_churchid" value={this.getValue('id')} />
										<input type="hidden" name="photo1" value={this.getValue('cimage1')} />
										<input type="hidden" name="photo2" value={this.getValue('cimage2')} />
										<input type="hidden" name="photo3" value={this.getValue('cimage3')} />
										<input type="hidden" name="photo4" value={this.getValue('cimage4')} />
										<input type="hidden" name="photoname" value={this.state.photoname} />
										<input type="hidden" name="photo" value={this.state.photo} /> 
										<input type="hidden" name="ch_mrgbue" value={this.state.ch_mrgbue} />
										<input type="hidden" name="ch_mrghall" value={this.state.ch_mrghall} />
										<input type="hidden" name="ch_councel" value={this.state.ch_councel} />
										<input type="hidden" name="ch_legal" value={this.state.ch_legal} />
										<input type="hidden" name="ch_bookstall" value={this.state.ch_bookstall} />
										<input type="hidden" name="ch_med" value={this.state.ch_med} />
										<input type="hidden" name="photoname" value={this.state.photoname} />
										<input type="hidden" name="files" value={this.state.files} />
										<input type="hidden" name="photoname1" value={this.state.photoname1} />
										<input type="hidden" name="files1" value={this.state.files1} />
										<input type="hidden" name="photoname2" value={this.state.photoname2} />
										<input type="hidden" name="files2" value={this.state.files2} />
										<input type="hidden" name="photoname3" value={this.state.photoname3} />
										<input type="hidden" name="files3" value={this.state.files3} />
										<input type="hidden" name="ispasalica" value={this.state.ispasalica} />
										<input type="hidden" name="isshrine" value={this.state.isshrine} />
										<input type="hidden" name="iscathedral" value={this.state.iscathedral} />
										<input type="hidden" name="ispc" value={this.state.ispc} />
										<input type="hidden" name="issubsta" value={this.state.issubsta} />
										<Row>
											<Col md={6} >
												<div className="form-group" >
													<label className="label-text" >Name of the Church <span className="text-danger">*</span></label>
													<input type="text" name="ch_name" id="ch_name" className="form-control single-line-input" onChange={this.setValue.bind(this,'name')} value={this.getValue('name')} required />
													{this.validator.message('Church Name', this.state.ch_name, 'required')}
												</div>
											</Col>
											<Col md={6} >
												<div className="form-group" >
													<label className="label-text" >Type <span className="text-danger">*</span></label>&nbsp;&nbsp;&nbsp;&nbsp;
													<input type="checkbox" name="ispasalica" id="ispasalica" checked={this.getValue('ispasalica') == 1 ? true : false} onChange={this.setChkValue.bind(this,'ispasalica')} />	&nbsp;&nbsp;
													<label className="label-text" > Basalica   </label>
													&nbsp;&nbsp;
													<input type="checkbox" name="isshrine" id="isshrine" checked={this.getValue('isshrine') == 1 ? true : false} onChange={this.setChkValue.bind(this,'isshrine')} />
													&nbsp;&nbsp;
													<label className="label-text" > Shrine   </label>
													&nbsp;&nbsp;
													<input type="checkbox" name="iscathedral" id="iscathedral" checked={this.getValue('iscathedtral') == 1 ? true : false} onChange={this.setChkValue.bind(this,'iscathedtral')} />
													&nbsp;&nbsp;
													<label className="label-text" > Cathedral   </label>
													&nbsp;&nbsp;
													<input type="checkbox" name="ispc" id="ispc" checked={this.getValue('ispc') == 1 ? true : false} onChange={this.setChkValue.bind(this,'ispc')} />
													&nbsp;&nbsp;
													<label className="label-text" > Parish Church   </label>
													&nbsp;&nbsp;
													<input type="checkbox" name="issubsta" id="issubsta" checked={this.getValue('issubstation') == 1 ? true : false} onChange={this.setChkValue.bind(this,'issubstation')} />
													&nbsp;&nbsp;
													<label className="label-text" > Sub Station  </label>
												</div>
											</Col>
											<Col md={6} >
												<div className="form-group" >
													<label className="label-text" >Diocese<span className="text-danger">*</span></label>
													<select name="ch_dist" onChange={this.onDioChange} value={this.getValue('diociese')} id="ch_dist" required className="form-control single-line-input" >
														<option value="">Select Diocese</option>
														{
															dlist.map(function (item) {
																return <option value={item.id }>{item.name}</option>;
															})
														}
													</select>
													{this.validator.message('Diocese', this.state.diocese, 'required')}
												</div>
											</Col>
											<Col md={6} >
												<div className="form-group" >
													<label className="label-text" >Parish<span className="text-danger">*</span></label>
													<select name="ch_parish" id="ch_parish" onChange={this.setValue.bind(this,'parish')} value={this.getValue('parish')} required className="form-control single-line-input" >
														<option value="">Select Parish</option>
														{
															plist.map(function (item) {
																return <option value={item.id }>{item.name}</option>;
															})
														}
													</select>
													{this.validator.message('Church Parish', this.state.ch_parish, 'required')}
												</div>
											</Col>
											<Col md={6} >
												<div className="form-group" >
													<label className="label-text" >No of Families<span className="text-danger"></span></label>
													<input type="text" name="ch_fams" id="ch_fams" onChange={this.setValue.bind(this,'nooffamilies')} value={this.getValue('nooffamilies')} className="form-control single-line-input integer"  />
													{this.validator.message('Church Parish', this.state.ch_fams, '')}
												</div>
											</Col>
											<Col md={6} >
												<div className="form-group" >
													<label className="label-text" >Parish Prist<span className="text-danger"></span></label>
													<input type="text" name="ch_prist" id="ch_prist" onChange={this.setValue.bind(this,'parishprist')} value={this.getValue('parishprist')} className="form-control single-line-input"  />
													{this.validator.message('Church Parish Prist', this.state.ch_prist, '')}
												</div>
											</Col>
											<Col md={6} >
												<div className="form-group" >
													<label className="label-text" >Assistant Parish Prist<span className="text-danger"></span></label>
													<input type="text" name="ch_asstprist" id="ch_asstprist" className="form-control single-line-input" onChange={this.setValue.bind(this,'asstparishprist')} value={this.getValue('asstparishprist')} />
													{this.validator.message('Church Parish Prist', this.state.ch_asstprist, '')}
												</div>
											</Col>
											<Col md={6} >
												<div className="form-group" >
													<label className="label-text" >Address<span className="text-danger"></span></label>
													<textarea name="ch_addr" id="ch_addr" className="form-control" onChange={this.setValue.bind(this,'address')} value={this.getValue('address')}  rows="3" ></textarea>
													{this.validator.message('Church Address', this.state.ch_addr, '')}
												</div>
											</Col>
											
											<Col md={6} >
												<div className="form-group" >
													<label className="label-text" >Pincode</label>
													<input type="text" name="ch_pin" id="ch_pin" className="form-control single-line-input" onChange={this.setValue.bind(this,'pincode')} value={this.getValue('pincode')} />
												</div>
											</Col>
											
											<Col md={6} >
												<div className="form-group" >
													<label className="label-text" >Phone</label>
													<input type="text" name="ch_phone" id="ch_phone" className="form-control single-line-input" onChange={this.setValue.bind(this,'phone')} value={this.getValue('phone')} />
												</div>
											</Col>
											<Col md={6} >
												<div className="form-group" >
													<label className="label-text" >Mobile<span className="text-danger"></span></label>
													<input type="text" name="ch_mobile" id="ch_mobile" className="form-control single-line-input integer 10digitonly" onChange={this.setValue.bind(this,'mobile')} value={this.getValue('mobile')}  />
													{this.validator.message('Church Mobile', this.state.ch_mobile, '')}
												</div>
											</Col>
											<Col md={6} >
												<div className="form-group" >
													<label className="label-text" >Secondary Mobile<span className="text-danger"></span></label>
													<input type="text" name="ch_secmob" id="ch_secmob" className="form-control single-line-input integer 10digitonly" onChange={this.setValue.bind(this,'secondarymobile')} value={this.getValue('secondarymobile')} />
													{this.validator.message('Church Mobile', this.state.ch_secmob, '')}
												</div>
											</Col>
											<Col md={6} >
												<div className="form-group" >
													<label className="label-text" >Email<span className="text-danger"></span></label>
													<input type="email" name="ch_mail" id="ch_mail" className="form-control single-line-input"  onChange={this.setValue.bind(this,'email')} value={this.getValue('email')}  />
													{this.validator.message('Church Email', this.state.ch_mail, '')}
												</div>
											</Col>
											<Col md={6} >
												<div className="form-group" >
													<label className="label-text" >Domain<span className="text-danger"></span></label>
													<input type="text" name="ch_domain" id="ch_domain" className="form-control single-line-input" onChange={this.setValue.bind(this,'domain')} value={this.getValue('domain')} />
													{this.validator.message('Domain', this.state.ch_domain, '')}
												</div>
											</Col>
											<Col md={6} >
												<div className="form-group" >
													<label className="label-text" >Website<span className="text-danger"></span></label>
													<input type="url" name="ch_web" id="ch_web" className="form-control single-line-input" onChange={this.setValue.bind(this,'website')} value={this.getValue('website')} />
													{this.validator.message('Church Website', this.state.ch_web, '')}
												</div>
											</Col>
											
											<Col md={6} >
												<div className="form-group" >
													<label className="label-text" >Geo IP Loaction<span className="text-danger"></span></label>
													<input type="text" name="ch_geoip" id="ch_geoip" className="form-control single-line-input" onChange={this.setValue.bind(this,'geoip')} value={this.getValue('geoip')} />
													{this.validator.message('Church Mobile', this.state.ch_geoip, '')}
												</div>
											</Col>
											
											<Col md={6} >
												<div className="form-group" >
													
												</div>
											</Col>
											
											<Col md={12} >
												<div className="form-group" >
													<label className="label-text" >Facilities / Services<span className="text-danger"></span></label>
												</div>
											</Col>
											
										
											<Col md={2} >
												<div className="form-group" >
												<input type="checkbox" name="ch_bookstall" id="ch_bookstall"  checked={this.getValue('bookstall') == 1 ? true : false} onChange={this.setChkValue.bind(this,'bookstall')} />&nbsp;&nbsp;
													
													<label className="label-text" > Book Stall   </label>
													
													{this.validator.message('Church Phone', this.state.ch_bookstall, '')}
												</div>
											</Col>
											<Col md={2} >
												<div className="form-group" >
												<input type="checkbox" name="ch_councel" id="ch_councel" checked={this.getValue('councelctr') == 1 ? true : false} onChange={this.setChkValue.bind(this,'councelctr')}  />&nbsp;&nbsp;
													
													<label className="label-text" > Counceling Centre </label>
													
													{this.validator.message('Church Phone', this.state.ch_councel, '')}
												</div>
											</Col>
											<Col md={2} >
												<div className="form-group" >
												<input type="checkbox" name="ch_legal" id="ch_legal" checked={this.getValue('legalctr') == 1 ? true : false} onChange={this.setChkValue.bind(this,'legalctr')}  />&nbsp;&nbsp;
													<label className="label-text" > Legal Centre   </label>
													
													
													{this.validator.message('Church Phone', this.state.ch_legal, '')}
												</div>
											</Col>
											
											<Col md={2} >
												<div className="form-group" >
												<input type="checkbox" name="ch_mrgbue" id="ch_mrgbue" checked={this.getValue('mrgbeuree') == 1 ? true : false} onChange={this.setChkValue.bind(this,'mrgbeuree')}  />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
													<label className="label-text" > Marriage Bureau </label>
													{this.validator.message('Church Phone', this.state.ch_mrgbue, '')}
												</div>
											</Col>
											<Col md={2} >
												<div className="form-group" >
												<input type="checkbox" name="ch_mrghall" id="ch_mrghall"  checked={this.getValue('mrghall') == 1 ? true : false} onChange={this.setChkValue.bind(this,'mrghall')} />&nbsp;&nbsp;
													<label className="label-text" > Marriage Hall </label>
													{this.validator.message('Church Phone', this.state.ch_mrghall, '')}
												</div>
											</Col>
											
											<Col md={2} >
												<div className="form-group" >
												<input type="checkbox" name="ch_med" id="ch_med"  checked={this.getValue('medicalctr') == 1 ? true : false} onChange={this.setChkValue.bind(this,'medicalctr')} />&nbsp;&nbsp;
													<label className="label-text" > Medical Centre  </label>
													{this.validator.message('Church Phone', this.state.ch_med, '')}
												</div>
											</Col>
									
											<Col md={12}>
												<div className="form-group" >
													<label className="label-text" >About<span className="text-danger"></span></label>
													<div className="md-form">
													<textarea type="text" id="form7" className="md-textarea form-control" name="ch_about" rows="5" value={this.getValue('content')} onChange={this.setValue.bind(this,'content')} ></textarea>
													{this.validator.message('Content', this.state.ch_about, '')}
													</div>
												</div>
											</Col>
										
											<Col md={3} >
												<div className="form-group" >
													<label className="label-text" >Photo 1<span className="text-danger"></span></label>
													<ReactFileReader handleFiles={this.handleFiles} fileTypes='image/*' base64={true}>
														<img src={this.state.files!='' ? this.state.files : this.getValue('cimage1')!='' ? apiUrl+this.getValue('cimage1') : NOIMAGE} style={{border : '1px solid #cccccc',width : '100%',cursor : 'pointer'}} />
													</ReactFileReader>
												</div>
											</Col>
											
											<Col md={3} >
												<div className="form-group" >
													<label className="label-text" >Photo 2<span className="text-danger"></span></label>
													<ReactFileReader handleFiles={this.handleFiles1} fileTypes='image/*' base64={true}>
														<img src={this.state.files1!='' ? this.state.files1 : this.getValue('cimage2')!='' ? apiUrl+this.getValue('cimage2') : NOIMAGE} style={{border : '1px solid #cccccc',width : '100%',cursor : 'pointer'}} />
													</ReactFileReader>
												</div>
											</Col>
											
											<Col md={3} >
												<div className="form-group" >
													<label className="label-text" >Photo 3<span className="text-danger"></span></label>
													<ReactFileReader handleFiles={this.handleFiles2} fileTypes='image/*' base64={true}>
														<img src={this.state.files2!='' ? this.state.files2 : this.getValue('cimage3')!='' ? apiUrl+this.getValue('cimage3') : NOIMAGE} style={{border : '1px solid #cccccc',width : '100%',cursor : 'pointer'}} />
													</ReactFileReader>
												</div>
											</Col>
											
											<Col md={3} >
												<div className="form-group" >
													<label className="label-text" >Photo 4<span className="text-danger"></span></label>
													<ReactFileReader handleFiles={this.handleFiles3} fileTypes='image/*' base64={true}>
														<img src={this.state.files3!='' ? this.state.files3 : this.getValue('cimage4')!='' ? apiUrl+this.getValue('cimage4') : NOIMAGE} style={{border : '1px solid #cccccc',width : '100%',cursor : 'pointer'}} />
													</ReactFileReader>
												</div>
											</Col>
											
										</Row>	
										<Row>
											<Col md={12} Col xs={12} >
												<div className="btn-list text-right mt-4">
													<button type="submit" name="i__save" className="btn btn-success btn-sm" disabled={this.state.saveText!='Update'} >
														<i className="fa fa-check"></i> {this.state.saveText}
													</button>
												</div>
											</Col>
										</Row>

									</form>
								</div>
							</div>
						</div>
					</section>
				</div>
			</div>
		);
	}

}
export default EditChurch;