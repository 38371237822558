import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import $ from 'jquery';
import {appName,copyRight,imagePath,apiUrl} from '../../utils/Variables';
import * as Reqst from '../../utils/Reqst';
import Sanitize from '../../utils/Sanitize';
import {notify} from 'react-notify-toast';
import {Helmet} from 'react-helmet';
import swal from 'sweetalert';
import Cookies from 'universal-cookie';
import ReactFileReader from 'react-file-reader';
import {Button,Tooltip,Loading,Notification,Select,DateRangePicker,Alert } from 'element-react';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import NOIMAGE from '../../assets/img/noimage.jpg';
import {Row,Col,Table,Modal} from 'react-bootstrap';
import AppTitle from '../../containers/AppTitle';
import FullPageLoader from '../../containers/FullPageLoader';
import SimpleReactValidator from 'simple-react-validator';
const tabletd ={
	
	border : '1px solid #063977',borderCollapse : 'collapse',textAlign: 'center',color : '#063977',textTransform:'capitalize'
};
const tabletdemail ={
	
	border : '1px solid #063977',borderCollapse : 'collapse',textAlign: 'center',color : '#063977'
};

class Reports extends Component{
	constructor(props){
		super(props);
		this.state = {
			saveText: 'Search',
			isRedirect: false,
			dataLoading : false,
			dioceses : [],
			expired : false,
			dioLoading : false,
			distLoading : false,
			searchData : [],
			districts : [],
			p_diocese : [],
			p_types : [],
			p_parish : [],
			p_services : [],
			parish : [],
			daterange : [], //this.getTodays(),
			daterange1 : [],//this.getTodays(),
			parishLoading : false,
			p_reg_from : '',
			p_reg_to : '',
			p_ord_from : '',
			p_ord_to : '',
			d_erected : new Date()
		}
		this.validator = new SimpleReactValidator();
		this.handleInputChange = this.handleInputChange.bind(this);
		this.onSelectChange = this.onSelectChange.bind(this);
		this.onDateChange = this.onDateChange.bind(this);
		this.onDateChange1 = this.onDateChange1.bind(this);
		this.handleInputBlur = this.handleInputBlur.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}
	
	printDocument() {
		
		//$(".print-report").hide();
		var content = document.getElementById("print_report");
		var pri = document.getElementById("print_frame").contentWindow;
		pri.document.open();
		pri.document.write(content.innerHTML);
		pri.document.close();
		pri.focus();
		pri.print();
	}
	
	onDateChange(date) { 
			 
			
			if(date){
			 
				 this.setState({daterange:date});
				 
				 var firstDay = date[0];
				 
				 var lastDay = date[1];
							
					firstDay = this.getdateoforginal(firstDay);
					lastDay = this.getdateoforginal(lastDay);
				
					this.setState({p_reg_from:firstDay,p_reg_to:lastDay});
			}
      }
	  
	  onDateChange1(date) { 
			 
			
			 if(date){
				 this.setState({daterange1:date});
				 
				 var firstDay = date[0];
				 
				 var lastDay = date[1];
							
					firstDay = this.getdateoforginal(firstDay);
					lastDay = this.getdateoforginal(lastDay);
				
					this.setState({p_ord_from:firstDay,p_ord_to:lastDay});
			 }
      }
	
		getTodays(){
		   
				var dates = [];
			   var d = new Date();
               var date = d.setDate(d.getDate() - 1);
			   
			   var yesterday = new Date(date);
			   
			  var today = new Date();
			  
			   dates.push(yesterday);
			   dates.push(today);
			   
		
			   return dates;
		   
		   
	   }
	
	componentDidMount(){
		
		this.loadDioceses();
		this.loadParish();
	}
	
	loadParish() {

		this.setState({ parish: [], parishLoading: true });
		var token = Sanitize.getStoreageItem('accessToken');
		Reqst.axGet('api/v1/getparishlist', token).then(res => {

			let result = res['data'];

			if (result) {
				if (result.status == '1') {
					this.setState({ parish: result.data, parishLoading: false });
				}
				else if (result.status == '2') {
					alert(result.message);
					this.setState({ parishLoading: false });
					this.setState({ expired: true });
				}
				else {
					Notification({ message: result.message, type: 'error' });
					this.setState({ parishLoading: false });
				}
			}
			else {

				Notification({ message: 'Something went wrong Try again...', type: 'error' });
				this.setState({ parishLoading: false });
			}
		});
	}
	
	getdateoforginal(date) {

		if (date != '' && date != null) {
			var date1 = new Date(date);
			var year = date1.getFullYear();
			var month = (date1.getMonth() + 1);
			var day = date1.getDate()
			var hours = date1.getHours();
			var minutes = date1.getMinutes();
			var seconds = date1.getSeconds();
			month = month < 10 ? '0' + month : month;
			day = day < 10 ? '0' + day : day;
			var strTime = year + '-' + month + '-' + day;
			return strTime;
		}
		else {

			return '- - - -';
		}

	}
	
	getdateofcustom(date) {

		if (date != '' && date != null && date!='0000-00-00' && date!='1970-01-01') {
			var date1 = new Date(date);
			var year = date1.getFullYear();
			var month = (date1.getMonth() + 1);
			var day = date1.getDate()
			var hours = date1.getHours();
			var minutes = date1.getMinutes();
			var seconds = date1.getSeconds();
			month = month < 10 ? '0' + month : month;
			day = day < 10 ? '0' + day : day;
			var strTime = day + '-' + month + '-' + year;
			return strTime;
		}
		else {

			return '- - - -';
		}

	}
	
	loadDioceses(){
		
		this.setState({dioceses : [],dioLoading:true});
		var token = Sanitize.getStoreageItem('accessToken');
		Reqst.axGet('api/v1/getdioceseslist',token).then(res=>{
			
				let result = res['data'];
				
				if(result){
						if(result.status=='1')
						{
							this.setState({dioceses: result.data, dioLoading:false});
						}
						else if(result.status=='2')
						{
							alert(result.message);
							this.setState({dioLoading:false});
							this.setState({expired:true});
						}
						else
						{
							Notification({message:result.message,type: 'error'});
							this.setState({dioLoading:false});
						}
				 }
				 else{
					 
							Notification({message:'Something went wrong Try again...',type: 'error'});
							this.setState({parishLoading:false});
				  }
			});
	}
	

	
	
	handleInputChange(e){
		this.setState({[e.target.name]: e.target.value});
	}
	
	
	
	onSelectChange(type,value){
		
		if(type=='parish'){
			
		  this.setState({p_parish: value});
		}
		else if(type=='Diocese'){
			
		  this.setState({p_diocese: value});
		}
		else if(type=='Type'){
			
		  this.setState({p_types: value});
		}
		else if(type=='Service'){
			
		  this.setState({p_services: value});
		}
		
	}
	
	handleInputBlur(e){
		if (!this.validator.allValid()) {
			this.validator.showMessages();			
			this.forceUpdate();
		}
	}
	
	onClear(){
		
		this.state.daterange = [];
		this.state.p_types = [];
		this.state.p_services = [];
		this.state.p_parish = [];
		this.state.p_diocese = [];
		this.state.p_reg_from = '';
		this.state.p_reg_to = '';

	}
	
	handleSubmit(e){
		try{
		
			e.preventDefault();
			 if (this.validator.allValid()) {
				this.setState({saveText: 'Searching..'});
				this.setState({searchData:[]});
				var token = Sanitize.getStoreageItem('accessToken');
				Reqst.axPost('api/v1/getchurchreports',$("#frmParish").serialize(),token).then(res=>{
					if(res['data'].status=='1')
					{
						this.onClear();
						document.getElementById("frmParish").reset();
						this.setState({searchData:res['data'].data});
						this.setState({saveText:'Search'});
						
					}
					else if(res['data'].status=='2')
					{
						  alert(res['data'].message);
						  this.setState({saveText:'Search'});
						  this.setState({expired:true});
					}
					else
					{
						Notification({message:res['data'].message,type: 'error'});
						this.setState({saveText:'Search'});
					}
				});
		  }
		   else {
			this.validator.showMessages();
			// rerender to show messages for the first time
			this.forceUpdate();
		 }
		}
		catch(e){
			console.log(e);
		}
	}
	
	render(){
		
		const types = [
			{
				value: 'basalica',
				label: 'Basalica'
			},
			{
				value: 'shrine',
				label: 'Shrine'
			},
			{
				value: 'cathedral',
				label: 'Cathedral'
			},
			{
				value: 'parish_church',
				label: 'Parish Church'
			},
			{
				value: 'sub_station',
				label: 'Sub Station'
			}
		];
		
		const services = [
			{
				value: 'book_stall',
				label: 'Book Stall'
			},
			{
				value: 'counceling_centre',
				label: 'Counceling Centre'
			},
			{
				value: 'legal_centre',
				label: 'Legal Centre'
			},
			{
				value: 'marriage_bureau',
				label: 'Marriage Bureau'
			},
			{
				value: 'marriage_hall',
				label: 'Marriage Hall'
			},
			{
				value: 'medical_centre',
				label: 'Medical Centre'
			}
		];
		
		if(this.state.expired)
		{
			return (<Redirect to="/login" />);
		}
		
		
		
		return(
			<div>
				
				<Helmet>
					<title>{appName} | Configuration | Church Reports</title>
				</Helmet>
				
				
				<div className="topbar-header">
						<div className="topbar-header__content">
						
						   <AppTitle
								menuLevel1="Configuration"
								menuLevel2="Churches"
								menuLevel3="Reports"
								menuLevel2link="#/configuration/churches"
								isbreadcumb={true}
							/>
						
				       
						<div className="topbar_buttons">
								
						</div>
					 </div>
				</div>
				
				      <div className="main_content">
				              <div className="card" >
											<h4 className="card-header bg-info text-white">Reports
											 {this.state.searchData.length > 0 ? 
												<span className="pull-right">
												
												  <ReactHTMLTableToExcel
													id="test-table-xls-button"
													className="el-button el-button--success el-button--small fa fa-print"
													table="table-to-xls_report"
													filename="priests_reports"
													sheet="tablexls"
													Button size="xs"
													buttonText="  Export"/>
													&nbsp;
												   <Button size="small" type="danger" onClick={() => this.printDocument() }><i className="fa fa-print"></i> Print</Button>
												</span>
											 : '' }
											</h4>
											<div className="card-body" >
													<form action="" method="post" encType="multipart/form-data" id="frmParish" onSubmit={this.handleSubmit} >
														<input type="hidden" name="i__id" value={Sanitize.getStoreageItem('userId')} />
														<input type="hidden" name="p_diocese_form" value={this.state.p_diocese} />
														<input type="hidden" name="p_parish_form" value={this.state.p_parish} />
														<input type="hidden" name="p_types" value={this.state.p_types} />
														<input type="hidden" name="p_services" value={this.state.p_services} />
														<input type="hidden" name="p_reg_from" value={this.state.p_reg_from} />
														<input type="hidden" name="p_reg_to" value={this.state.p_reg_to} />
														
													
														<div className="row">
														  <div className="col-md-6">
														  
														     <div className="row" style={{ marginBottom: 10 }}  >
																	<div className="col-md-3" >
																		<label>Diocese<span className="text-danger"></span></label>
																	</div>
																	<div className="col-md-9" >
																	<Select value={this.state.p_diocese} onChange={this.onSelectChange.bind(this,'Diocese')} loading={this.state.dioLoading} filterable={true} multiple={true} style={{width:'100%'}}>
																		{
																			this.state.dioceses.map(el => {
																				return <Select.Option key={el.id} label={el.name} value={el.id} />
																			})
																		}
																	</Select>
																	
																		{this.validator.message('Diocese', this.state.p_diocese, '')}
																		
																	</div>
																</div>
																
																<div className="row" style={{ marginBottom: 10 }}  >
																	<div className="col-md-3" >
																		<label>Mobile<span className="text-danger"></span></label>
																	</div>
																	<div className="col-md-9" >
																		<input type="text" name="p_mobile" id="p_mobile" className="form-control"  onChange={this.handleInputChange} onBlur={this.handleInputBlur}  />
																		{this.validator.message('Mobile', this.state.p_mobile, 'integer')}
																	</div>
																</div>
																
																<div className="row" style={{ marginBottom: 10 }}  >
																	<div className="col-md-3" >
																		<label>Priest Name<span className="text-danger"></span></label>
																	</div>
																	<div className="col-md-9" >
																		<input type="text" name="p_priest" id="p_priest" className="form-control"  onChange={this.handleInputChange} onBlur={this.handleInputBlur}  />
																		{this.validator.message('Priest', this.state.p_priest, '')}
																	</div>
																</div>
																
																<div className="row" style={{ marginBottom: 10 }}  >
																	<div className="col-md-3" >
																		<label>Registered on<span className="text-danger"></span></label>
																	</div>
																	<div className="col-md-9" >
																		<DateRangePicker
																				  value={this.state.daterange}
																				  placeholder="Pick a range"
																				  format="yyyy-MM-dd"
																				  align="right"
																				  ref={e=>this.daterangepicker2 = e}
																				  onChange={(date) => { this.onDateChange(date) }}
																		 />
																	</div>
																</div>
																
																<div className="row" style={{ marginBottom: 10 }}  >
																	<div className="col-md-3" >
																		<label>Service<span className="text-danger"></span></label>
																	</div>
																	<div className="col-md-9" >
																	<Select value={this.state.p_services} onChange={this.onSelectChange.bind(this,'Service')} multiple={true} style={{width:'100%'}}>
																		{
																			services.map(el => {
																				return <Select.Option key={el.value} label={el.label} value={el.value} />
																			})
																		}
																	</Select>
																	
																		{this.validator.message('Service', this.state.p_services, '')}
																		
																	</div>
																</div>
																

														  
																
															</div>
															<div className="col-md-6">
															
															 <div className="row" style={{ marginBottom: 10 }}  >
																	<div className="col-md-3" >
																		<label>Parish<span className="text-danger"></span></label>
																	</div>
																	<div className="col-md-9" >
																	<Select value={this.state.p_parish} onChange={this.onSelectChange.bind(this,'parish')} loading={this.state.parishLoading} filterable={true} multiple={true} style={{width:'100%'}}>
																		{
																			this.state.parish.map(el => {
																				return <Select.Option key={el.id} label={el.name} value={el.id} />
																			})
																		}
																	</Select>
																	
																		{this.validator.message('parish', this.state.p_parish, '')}
																		
																	</div>
																</div>
																
																<div className="row" style={{ marginBottom: 10 }}  >
																	<div className="col-md-3" >
																		<label>Name<span className="text-danger"></span></label>
																	</div>
																	<div className="col-md-9" >
																		<input type="text" name="p_name" id="p_name" className="form-control"  onChange={this.handleInputChange} onBlur={this.handleInputBlur}  />
																		{this.validator.message('Parish Name', this.state.p_name, '')}
																	</div>
																</div>
																
																<div className="row" style={{ marginBottom: 10 }}  >
																	<div className="col-md-3" >
																		<label>Email<span className="text-danger"></span></label>
																	</div>
																	<div className="col-md-9" >
																		<input type="text" name="p_mail" id="p_mail" className="form-control"  onChange={this.handleInputChange} onBlur={this.handleInputBlur}  />
																		{this.validator.message('Email', this.state.p_mail, 'email')}
																	</div>
																</div>
																
																<div className="row" style={{ marginBottom: 10 }}  >
																	<div className="col-md-3" >
																		<label>Type<span className="text-danger"></span></label>
																	</div>
																	<div className="col-md-9" >
																	<Select value={this.state.p_types} onChange={this.onSelectChange.bind(this,'Type')} multiple={true} style={{width:'100%'}}>
																		{
																			types.map(el => {
																				return <Select.Option key={el.value} label={el.label} value={el.value} />
																			})
																		}
																	</Select>
																	
																		{this.validator.message('Type', this.state.p_types, '')}
																		
																	</div>
																</div>
																
																<div className="row" style={{ marginBottom: 10 }}  >
																	<div className="col-md-3" >
																		<label>Assistant Priest<span className="text-danger"></span></label>
																	</div>
																	<div className="col-md-9" >
																		<input type="text" name="p_asst_priest" id="p_asst_priest" className="form-control"  onChange={this.handleInputChange} onBlur={this.handleInputBlur}  />
																		{this.validator.message('Assistant Priest', this.state.p_asst_priest, '')}
																	</div>
																</div>
																
																
														
													    </div>
													 </div>	
															
													
															<div className="row" style={{ marginBottom: 10 }}  >
																<div className="col-md-12" >
																	<div className="text-right" >
																		
																		
																		<button type="submit" name="i__save" className="btn btn-success btn-sm" disabled={this.state.saveText!='Search'} >
																			<i className="fa fa-check"></i> {this.state.saveText}
																		</button>
																		
																	</div>
																</div>
															</div>
												 
													 </form>
													 
													  <br/> <br/>
													 <div id="print_report">
														
													 {this.state.searchData.length > 0 ? 
													    
													   <div>
															<Alert title={"Total Churches - "+this.state.searchData.length} type="info" closable={true} showIcon={true} />
														
														<br/>
														<Table responsive style={{border : '1px solid black',borderCollapse : 'collapse'}} width="100%" align="center" id="table-to-xls_report"> 
															<thead>
															  <tr>
																  <th style={tabletd}>S.No</th>
																  <th style={tabletd}>Name</th>
																  <th style={tabletd}>Diocese</th>
																  <th style={tabletd}>Parish</th>
																  <th style={tabletd}>Mobile</th>
																  <th style={tabletd}>Email</th>
																  <th style={tabletd}>Parish Priest</th>
																  <th style={tabletd}>Assistant Priest</th>

															  </tr>
															</thead>
															<tbody>
																{this.state.searchData.map((item,index) =>
													 
																	<tr key = {item}>
																		<td style={tabletd}>{index+1}</td>
																		<td style={tabletd}>{item.name}</td>
																		<td style={tabletd}>{item.dname}</td>
																		<td style={tabletd}>{item.pname}</td>
																		<td style={tabletd}>{item.mobile}</td>
																		<td style={tabletdemail}>{item.email}</td>
																		<td style={tabletd}>{item.parishprist}</td>
																		<td style={tabletd}>{item.asstparishprist}</td>
												
																		
																	</tr>
																)}
															</tbody>
														</Table>
													</div>
													 : '' }
													 <iframe name="print_frame" id="print_frame" width="0" height="0" frameborder="0" src="about:blank"></iframe>
													 </div>
											</div>
										</div>
								</div>
							</div>
		);
	}
	
}
export default Reports;