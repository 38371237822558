import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import $ from 'jquery';
import {appName,copyRight,imagePath} from '../../utils/Variables';
import * as Reqst from '../../utils/Reqst';
import Sanitize from '../../utils/Sanitize';
import {notify} from 'react-notify-toast';
import {Helmet} from 'react-helmet';
import swal from 'sweetalert';
import Cookies from 'universal-cookie';
import ReactFileReader from 'react-file-reader';
import {Button,Tooltip,Loading,Notification,Select } from 'element-react';
import NOIMAGE from '../../assets/img/noimage.jpg';
import AppTitle from '../../containers/AppTitle';
import FullPageLoader from '../../containers/FullPageLoader';
import SimpleReactValidator from 'simple-react-validator';

class EditUser extends Component{
	constructor(props){
		super(props);
		this.state = {
			saveText: 'Update',
			isRedirect: false,
			showLoader: false,
			dioceses : [],
			dioLoading : false,
			cmpData: [],
			formode : false,
			dataLoading : false,
			dataView: [],
			logo: '',
			viewpass : false,
			expired : false,
			updatedLogo: '',
			tid : this.props.match.params.id,
			u_diocese : []
		}
			this.validator = new SimpleReactValidator();
			this.handleInputChange = this.handleInputChange.bind(this);
			this.onSelectChange = this.onSelectChange.bind(this);
			this.handleInputBlur = this.handleInputBlur.bind(this);
		    this.handleSubmit = this.handleSubmit.bind(this);
	}
	
	componentDidMount(){
		
		this.loadData();
		this.loadDioceses();
	}
	
	loadData(){
		
		this.setState({dataLoading:true});
		var token = Sanitize.getStoreageItem('accessToken');
		Reqst.axGet('api/v1/getuser/'+this.state.tid,token).then(res=>{
			
				let result = res['data'];
				
				if(result){
						if(result.status=='1')
						{
							this.setState({dataLoading:false});
							var dio = [];
							if(result.data['diocese']!=null){
								
								dio = result.data['diocese'];
							}
							
							this.setState({
								tid: result.data['id'],
								u_name: result.data['name'],
								u_mobile: result.data['mobile'],
								u_mail: result.data['email'],
								u_diocese: dio,
								u_phone: result.data['phone'],
								u_city: result.data['city'],
								u_usename: result.data['username'],
								u_role: result.data['role'],
								u_pass : result.data['password'],
								u_addr: result.data['address']
							})
						}
						else if(result.status=='2')
						{
							alert(result.message);
							this.setState({dataLoading:false});
							this.setState({expired:true});
						}
						else
						{
							Notification({message:result.message,type: 'error'});
							this.setState({dataLoading:false});
						}
				 }
				 else{
					 
							Notification({message:'Something went wrong Try again...',type: 'error'});
							this.setState({dataLoading:false});
				  }
			});
	}
	
	handleInputChange(e){
		this.setState({[e.target.name]: e.target.value});
	}
	
	handleInputBlur(e){
		if (!this.validator.allValid()) {
			this.validator.showMessages();			
			this.forceUpdate();
		}
	}
	
	onSelectMultuple(value){
		
		alert(value);
	}
	
	onSelectChange(type,value){
		

		if(type=='Diocese'){
		
		  this.setState({u_diocese: value});
		}
		else if(type=='Role'){
			
		  this.setState({u_role: value});
		}
		
	}
	
	loadDioceses(){
		
		this.setState({dioceses : [],dioLoading:true});
		var token = Sanitize.getStoreageItem('accessToken');
		Reqst.axGet('api/v1/getdioceseslist',token).then(res=>{
			
				let result = res['data'];
				
				if(result){
						if(result.status=='1')
						{
							this.setState({dioceses: result.data, dioLoading:false});
						}
						else if(result.status=='2')
						{
							alert(result.message);
							this.setState({dioLoading:false});
							this.setState({expired:true});
						}
						else
						{
							Notification({message:result.message,type: 'error'});
							this.setState({dioLoading:false});
						}
				 }
				 else{
					 
							Notification({message:'Something went wrong Try again...',type: 'error'});
							this.setState({parishLoading:false});
				  }
			});
	}
	
	viewPassword(){
		this.setState({viewpass : !this.state.viewpass});
	}
	
	
	getnovalues(str) {

		if (str != '' && str != null) {
			return str;
		}
		else {

			return 'Not Assigned';
		}

	}

	
	
	handleSubmit(e){
		try{
		
			e.preventDefault();
			this.setState({saveText: 'Updating..'});
			var token = Sanitize.getStoreageItem('accessToken');
			Reqst.axPost('api/v1/update_user',$("#frmUser").serialize(),token).then(res=>{
				if(res['data'].status=='1')
				{
					Notification({message:res['data'].message,type: 'success'});
					this.setState({saveText:'Update',viewpass : false});
					this.loadData();
				}
				else if(res['data'].status=='2')
				{
					  alert(res['data'].message);
					  this.setState({saveText:'Update'});
					  this.setState({expired:true});
				}
				else
				{
					Notification({message:res['data'].message,type: 'error'});
					this.setState({saveText:'Update'});

				}
			});
		}
		catch(e){
			console.log(e);
		}
	}
	
	render(){
		
		
		
		if(this.state.expired)
		{
			return (<Redirect to="/login" />);
		}
		
		const roles = [
			{
				value: 'admin',
				label: 'Admin'
			},
			{
				value: 'diocese',
				label: 'Diocese User'
			}
		];
		
		
		return(
			<div>
				
				<Helmet>
					<title>{appName} | Users | Update User</title>
				</Helmet>
				
				
				<div className="topbar-header">
						<div className="topbar-header__content">
						
						   <AppTitle
								menuLevel1="Users"
								menuLevel2="Update User"
								menuLevel1link="#/users"
								isbreadcumb={true}
							/>
						
				       
						<div className="topbar_buttons">
								
						</div>
					 </div>
				</div>
				
				<div className="main_content">
			
				<div>
				     {this.state.dataLoading ?
					   
						   <div className="loader_div">
						     <Loading />
						   </div>
						:
				<form action="" method="post" encType="multipart/form-data" id="frmUser" onSubmit={this.handleSubmit} >
			
				 <section>
					<div className="container" >
						<div className="row">
							<div className="col-md-8 offset-md-2">
								<div className="card" >
									<h4 className="card-header bg-info text-white">Update User</h4>
									<div className="card-body" >
										                <input type="hidden" name="i__id" value={Sanitize.getStoreageItem('userId')} />
														 <input type="hidden" name="tid" value={this.state.tid} />
														<input type="hidden" name="u_diocese_form" value={this.state.u_diocese} />
														<input type="hidden" name="u_role_form" value={this.state.u_role} />
														
										<div className="row" style={{ marginBottom: 10 }}  >
											<div className="col-md-3" >
											    <label>Name<span className="text-danger">*</span></label>
											 </div>
											 <div className="col-md-9" >
											      <input type="text" name="u_name" value={this.state.u_name} id="u_name" className="form-control"  onChange={this.handleInputChange} onBlur={this.handleInputBlur} required />
												  {this.validator.message('User Name', this.state.u_name, 'required')}
											</div>
										</div>
										
										                      <div className="row" style={{ marginBottom: 10 }}  >
																	<div className="col-md-3" >
																		<label>Role<span className="text-danger">*</span></label>
																	</div>
																	<div className="col-md-9" >
																	<Select value={this.state.u_role} required onChange={this.onSelectChange.bind(this,'Role')} style={{width:'100%'}}>
																		{
																			roles.map(el => {
																				return <Select.Option key={el.value} label={el.label} value={el.value} />
																			})
																		}
																	</Select>
																	
																		{this.validator.message('Role', this.state.u_role, 'required')}
																		
																	</div>
																</div>
										
										{this.state.u_role == 'diocese' ?
										
										                      <div className="row" style={{ marginBottom: 10 }}  >
																	<div className="col-md-3" >
																		<label>Diocese<span className="text-danger">*</span></label>
																	</div>
																	<div className="col-md-9" >
																	<Select value={this.state.u_diocese} onChange={this.onSelectChange.bind(this,'Diocese')} loading={this.state.dioLoading} filterable={true} multiple={true}  style={{width:'100%'}} required >
																		{
																			this.state.dioceses.map(el => {
																				return <Select.Option key={el.id} label={el.name} value={el.id} />
																			})
																		}
																	</Select>
																	
																		{this.validator.message('Diocese', this.state.u_diocese, 'required')}
																		
																	</div>
																</div>
																
										: '' }

										<div className="row" style={{ marginBottom: 10 }}  >
											<div className="col-md-3" >
											    <label>Mobile<span className="text-danger">*</span></label>
											 </div>
											 <div className="col-md-9" >
											      <input type="text" value={this.state.u_mobile} name="u_mobile" id="u_mobile" className="form-control"  onChange={this.handleInputChange} onBlur={this.handleInputBlur} required />
												  {this.validator.message('User Mobile', this.state.u_mobile, 'required|integer|min:10|max:10')}
											</div>
										</div>
										
										<div className="row" style={{ marginBottom: 10 }}  >
											<div className="col-md-3" >
											    <label>Email<span className="text-danger">*</span></label>
											 </div>
											 <div className="col-md-9" >
											      <input type="text" value={this.state.u_mail} name="u_mail" id="u_mail" className="form-control"  onChange={this.handleInputChange} onBlur={this.handleInputBlur} required />
												  {this.validator.message('Email', this.state.u_mail, 'email')}
											</div>
										</div>
										
										<div className="row" style={{ marginBottom: 10 }}  >
											<div className="col-md-3" >
											    <label>Phone<span className="text-danger"></span></label>
											 </div>
											 <div className="col-md-9" >
											      <input type="text" value={this.state.u_phone} name="u_phone" id="u_phone" className="form-control"  onChange={this.handleInputChange} onBlur={this.handleInputBlur}  />
												  {this.validator.message('Phone', this.state.u_phone, 'integer')}
											</div>
										</div>
										
										<div className="row" style={{ marginBottom: 10 }}  >
											<div className="col-md-3" >
											    <label>City<span className="text-danger"></span></label>
											 </div>
											 <div className="col-md-9" >
											      <input type="text" value={this.state.u_city} name="u_city" id="u_city" className="form-control"  onChange={this.handleInputChange} onBlur={this.handleInputBlur}  />
												  {this.validator.message('City', this.state.u_city, '')}
											</div>
										</div>
										
										                      <div className="row" style={{ marginBottom: 10 }}  >
																	<div className="col-md-3" >
																		<label>Address<span className="text-danger"></span></label>
																	</div>
																	<div className="col-md-9" >
																	     <textarea name="u_addr" id="u_addr" value={this.state.u_addr} className="form-control" onChange={this.handleInputChange} onBlur={this.handleInputBlur} ></textarea>
																		{this.validator.message('Address', this.state.u_addr, '')}
																		
																	</div>
																</div>
												
								
											
											<div className="row" style={{ marginBottom: 10 }}  >
												<div className="col-md-3" >
													<label>Username <span className="text-danger">*</span></label>
												</div>
												<div className="col-md-9" >
													<input type="text" value={this.state.u_usename} name="u_usename" id="u_usename" className="form-control"  onChange={this.handleInputChange} onBlur={this.handleInputBlur} required />
												     {this.validator.message('Username', this.state.u_usename, 'required')}
												</div>
											</div>
											
											<div className="row" style={{ marginBottom: 10 }}  >
												<div className="col-md-3" >
													<label>Password <span className="text-danger">*</span></label>
												</div>
												<div className="col-md-9" >
													<input type={this.state.viewpass == true ? "text" : "password"} value={this.state.u_pass} name="u_pass" id="u_pass" className="form-control"  placeholder="Password" onChange={this.handleInputChange} onBlur={this.handleInputBlur} required />
													{this.validator.message('Password', this.state.u_pass, 'required')}
													 <span className="text-primary"  onClick={this.viewPassword.bind(this)} style={{position : 'absolute',top : '7px',right : '25px'}}><i className={this.state.viewpass == true ? "fa fa-eye-slash fa-lg" : "fa fa-eye fa-lg"} ></i></span>
												</div>
											</div>
										
											<div className="row" style={{ marginBottom: 10 }}  >
												<div className="col-md-12" >
													<div className="text-right" >
													
														<button type="submit" name="i__save" className="btn btn-success btn-sm" disabled={this.state.saveText!='Update'} >
															<i className="fa fa-check"></i> {this.state.saveText}
														</button>
														
													</div>
												</div>
											</div>
										
									</div>
								</div>
							</div>
						</div>
					</div>
				  </section>
				
				  </form>
					 }
				  </div>
				</div>
			</div>
		);
	}
	
}
export default EditUser;