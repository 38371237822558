import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import $ from 'jquery';
import { appName, copyRight, imagePath } from '../../utils/Variables';
import * as Reqst from '../../utils/Reqst';
import Sanitize from '../../utils/Sanitize';
import { notify } from 'react-notify-toast';
import { Helmet } from 'react-helmet';
import swal from 'sweetalert';
import Cookies from 'universal-cookie';
import ReactFileReader from 'react-file-reader';
import { Button, Tooltip, Loading, Notification, Select, DatePicker } from 'element-react';
import NOIMAGE from '../../assets/img/noimage.jpg';
import AppTitle from '../../containers/AppTitle';
import FullPageLoader from '../../containers/FullPageLoader';
import SimpleReactValidator from 'simple-react-validator';


class Priest extends Component {
	constructor(props) {
		super(props);
		this.state = {
			saveText: 'Save',
			isRedirect: false,
			dataLoading: false,
			diocese: [],
			parishes: [],
			province: [],
			states: [],
			expired: false,
			dioceseLoading: false,
			parishLoading: false,
			provinceLoading: false,
			stateLoading: false,
			d_type: '',
			files: '',
			photoname: '',
			d_state: '35',
			d_country: '101',
			d_erected: '',
			d_dobaptism: '',
			d_dodiocan: '',
			d_doordinate: '',
			d_dob: '',
		}
		this.validator = new SimpleReactValidator();
		this.handleInputChange = this.handleInputChange.bind(this);
		this.onSelectChange = this.onSelectChange.bind(this);
		this.onDateChange = this.onDateChange.bind(this);
		this.handleInputBlur = this.handleInputBlur.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleFiles = this.handleFiles.bind(this);
	}

	componentDidMount() {
		this.loadDiocese();
		this.loadParish();
		this.loadProvince();
	}

	handleFiles(files) {
		this.setState({ files: files.base64, photoname: files.fileList[0]['name'] });
	}

	getdateoforginal(date) {

		if (date != '' && date != null) {
			var date1 = new Date(date);
			var year = date1.getFullYear();
			var month = (date1.getMonth() + 1);
			var day = date1.getDate()
			var hours = date1.getHours();
			var minutes = date1.getMinutes();
			var seconds = date1.getSeconds();
			month = month < 10 ? '0' + month : month;
			day = day < 10 ? '0' + day : day;
			var strTime = year + '-' + month + '-' + day;
			return strTime;
		}
		else {

			return '';
		}

	}


	loadDiocese() {

		this.setState({ diocese: [], dioceseLoading: true });
		var token = Sanitize.getStoreageItem('accessToken');
		Reqst.axGet('api/v1/getdioceseslist', token).then(res => {

			let result = res['data'];

			if (result) {
				if (result.status == '1') {
					this.setState({ diocese: result.data, dioceseLoading: false });
				}
				else if (result.status == '2') {
					alert(result.message);
					this.setState({ dioceseLoading: false });
					this.setState({ expired: true });
				}
				else {
					Notification({ message: result.message, type: 'error' });
					this.setState({ dioceseLoading: false });
				}
			}
			else {

				Notification({ message: 'Something went wrong Try again...', type: 'error' });
				this.setState({ dioceseLoading: false });
			}
		});
	}

	loadParish() {

		this.setState({ parishes: [], parishLoading: true });
		var token = Sanitize.getStoreageItem('accessToken');
		Reqst.axGet('api/v1/getparishlist', token).then(res => {

			let result = res['data'];

			if (result) {
				if (result.status == '1') {
					this.setState({ parishes: result.data, parishLoading: false });
				}
				else if (result.status == '2') {
					alert(result.message);
					this.setState({ parishLoading: false });
					this.setState({ expired: true });
				}
				else {
					Notification({ message: result.message, type: 'error' });
					this.setState({ parishLoading: false });
				}
			}
			else {

				Notification({ message: 'Something went wrong Try again...', type: 'error' });
				this.setState({ parishLoading: false });
			}
		});
	}

	loadProvince() {

		this.setState({ province: [], provinceLoading: true });
		var token = Sanitize.getStoreageItem('accessToken');
		Reqst.axGet('api/v1/getprovincelist', token).then(res => {

			let result = res['data'];

			if (result) {
				if (result.status == '1') {
					this.setState({ province: result.data, provinceLoading: false });
				}
				else if (result.status == '2') {
					alert(result.message);
					this.setState({ provinceLoading: false });
					this.setState({ expired: true });
				}
				else {
					Notification({ message: result.message, type: 'error' });
					this.setState({ provinceLoading: false });
				}
			}
			else {

				Notification({ message: 'Something went wrong Try again...', type: 'error' });
				this.setState({ provinceLoading: false });
			}
		});
	}

	handleInputChange(e) {
		this.setState({ [e.target.name]: e.target.value });
	}

	onDateChange(type, value) {
		if (type == 'dob') {
			this.setState({ d_dob: value });
		}
		else if (type == 'Dobaptism') {
			this.setState({ d_dobaptism: value });
		}
		else if (type == 'Dodiocan') {
			this.setState({ d_dodiocan: value });
		}
		else if (type == 'Doordination') {
			this.setState({ d_doordinate: value });
		}
	}

	onSelectChange(type, value) {

		if (type == 'Province') {

			this.setState({ d_province: value });
		}
		else if (type == 'Diocese') {

			this.setState({ d_diocese: value });
		}
	}

	handleInputBlur(e) {
		if (!this.validator.allValid()) {
			this.validator.showMessages();
			this.forceUpdate();
		}
	}

	onClear() {


		document.getElementById("frmDiocese").reset();
	}

	handleSubmit(e) {
		try {

			e.preventDefault();
			if (this.validator.allValid()) {
				this.setState({ saveText: 'Saving..' });
				var token = Sanitize.getStoreageItem('accessToken');
				Reqst.axPost('api/v1/add_priest', $("#frmDiocese").serialize(), token).then(res => {
					if (res['data'].status == '1') {
						Notification({ message: res['data'].message, type: 'success' });
						document.getElementById("frmDiocese").reset();
						this.setState({ saveText: 'Save', files: '', photoname: '' });
					}
					else if (res['data'].status == '2') {
						alert(res['data'].message);
						this.setState({ saveText: 'Save' });
						this.setState({ expired: true });
					}
					else if (res['data'].status == '3') {
						Notification({ message: res['data'].message, type: 'success' });
						this.setState({ saveText: 'Save' });
					}
					else {
						Notification({ message: res['data'].message, type: 'error' });
						this.setState({ saveText: 'Save' });
					}
				});
			}
			else {
				this.validator.showMessages();
				// rerender to show messages for the first time
				this.forceUpdate();
			}
		}
		catch (e) {
			console.log(e);
		}
	}

	render() {


		if (this.state.expired) {
			return (<Redirect to="/login" />);
		}



		return (
			<div>

				<Helmet>
					<title>{appName} | Configuration</title>
				</Helmet>


				<div className="topbar-header">
					<div className="topbar-header__content">

						<AppTitle
							menuLevel1="Configuration"
							menuLevel2="Priest"
							menuLevel3="Add Priest"
							menuLevel2link="#/configuration/priest"
							isbreadcumb={true}
						/>


						<div className="topbar_buttons">

						</div>
					</div>
				</div>

				<div className="main_content">
					<section>
						<div className="" >

							<div className="card" >
								<h4 className="card-header bg-info text-white">Add Priest</h4>
								<div className="card-body" >
									<form action="" method="post" encType="multipart/form-data" id="frmDiocese" onSubmit={this.handleSubmit} >
										<input type="hidden" name="i__id" value={Sanitize.getStoreageItem('userId')} />
										<input type="hidden" name="d_province_form" value={this.state.d_province} />
										<input type="hidden" name="files" value={this.state.files} />
										<input type="hidden" name="photoname" value={this.state.photoname} />
										<input type="hidden" name="d_diocese_form" value={this.state.d_diocese} />
										<input type="hidden" name="d_parish_form" value={this.state.d_parish} />
										<input type="hidden" name="d_state_form" value={this.state.d_state} />
										<input type="hidden" name="d_dobaptism_form" value={this.getdateoforginal(this.state.d_dobaptism)} />
										<input type="hidden" name="d_dodiocan_form" value={this.getdateoforginal(this.state.d_dodiocan)} />
										<input type="hidden" name="d_doordinate_form" value={this.getdateoforginal(this.state.d_doordinate)} />
										<input type="hidden" name="d_dob_form" value={this.getdateoforginal(this.state.d_dob)} />

										<div className="row">
											<div className="col-md-6">

												<div className="row" style={{ marginBottom: 10 }}  >
													<div className="col-md-3" >
														<label>Name<span className="text-danger">*</span></label>
													</div>
													<div className="col-md-9" >
														<input type="text" name="d_name" id="d_name" className="form-control" onChange={this.handleInputChange} onBlur={this.handleInputBlur} required />
														{this.validator.message('Name', this.state.d_name, 'required|alpha_space')}
													</div>
												</div>

												<div className="row" style={{ marginBottom: 10 }}  >
													<div className="col-md-3" >
														<label>Mobile<span className="text-danger">*</span></label>
													</div>
													<div className="col-md-9" >
														<input type="text" name="d_mobile" id="d_mobile" className="form-control" onChange={this.handleInputChange} onBlur={this.handleInputBlur} required />
														{this.validator.message('Mobile', this.state.d_mobile, 'required|integer|min:10|max:10')}
													</div>
												</div>

												<div className="row" style={{ marginBottom: 10 }}  >
													<div className="col-md-3" >
														<label>Province<span className="text-danger"></span></label>
													</div>
													<div className="col-md-9" >
														<Select onChange={this.onSelectChange.bind(this, 'Province')} style={{ width: '100%' }}>
															{
																this.state.province.map(el => {
																	return <Select.Option key={el.id} label={el.provincename} value={el.id} />
																})
															}
														</Select>

														{this.validator.message('Province', this.state.d_province, '')}
													</div>
												</div>

												<div className="row" style={{ marginBottom: 10 }}  >
													<div className="col-md-3" >
														<label>Diocese<span className="text-danger"></span></label>
													</div>
													<div className="col-md-9" >
														<Select onChange={this.onSelectChange.bind(this, 'Diocese')} style={{ width: '100%' }}>
															{
																this.state.diocese.map(el => {
																	return <Select.Option key={el.id} label={el.name} value={el.id} />
																})
															}
														</Select>

														{this.validator.message('Diocese', this.state.d_diocese, '')}
													</div>
												</div>

												<div className="row" style={{ marginBottom: 10 }}  >
													<div className="col-md-3" >
														<label>Date of Birth<span className="text-danger"></span></label>
													</div>
													<div className="col-md-9" >
														<DatePicker
															value={this.state.d_dob}
															placeholder="Pick a day"
															format="yyyy-MM-dd"
															align="right"
															onChange={(date) => { this.onDateChange('dob', date) }}
															style={{ width: '100%' }}
														/>
														{this.validator.message('Dob', this.state.d_dob, '')}

													</div>
												</div>

												<div className="row" style={{ marginBottom: 10 }}  >
													<div className="col-md-3" >
														<label>Place of Birth<span className="text-danger"></span></label>
													</div>
													<div className="col-md-9" >
														<input type="text" name="d_pob" id="d_pob" className="form-control" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
														{this.validator.message('Placeofbirth', this.state.d_pob, 'integer|min:10|max:10')}
													</div>
												</div>

												<div className="row" style={{ marginBottom: 10 }}  >
													<div className="col-md-3" >
														<label>Parish of birth<span className="text-danger"></span></label>
													</div>
													<div className="col-md-9" >
														<input type="text" name="d_parishob" id="d_parishob" className="form-control" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
														{this.validator.message('Parishofbirth', this.state.d_parishob, 'integer|min:10|max:10')}
													</div>
												</div>

												<div className="row" style={{ marginBottom: 10 }}  >
													<div className="col-md-3" >
														<label>Date of Baptism<span className="text-danger"></span></label>
													</div>
													<div className="col-md-9" >
														<DatePicker
															value={this.state.d_dobaptism}
															placeholder="Pick a day"
															format="yyyy-MM-dd"
															align="right"
															onChange={(date) => { this.onDateChange('Dobaptism', date) }}
															style={{ width: '100%' }}
														/>
														{this.validator.message('Dobaptism', this.state.d_dobaptism, '')}

													</div>
												</div>


												<div className="row" style={{ marginBottom: 10 }}  >
													<div className="col-md-3" >
														<label>Place of Baptism<span className="text-danger"></span></label>
													</div>
													<div className="col-md-9" >
														<input type="text" name="d_placeofbap" id="d_placeofbap" className="form-control" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
														{this.validator.message('Placeofbaptism', this.state.d_placeofbap, 'integer|min:10|max:10')}
													</div>
												</div>

												<div className="row" style={{ marginBottom: 10 }}  >
													<div className="col-md-3" >
														<label>Parish of Baptism<span className="text-danger"></span></label>
													</div>
													<div className="col-md-9" >
														<input type="text" name="d_parishobap" id="d_parishobap" className="form-control" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
														{this.validator.message('Parishofbaptism', this.state.d_parishobap, 'integer|min:10|max:10')}
													</div>
												</div>

												<div className="row" style={{ marginBottom: 10 }}  >
													<div className="col-md-3" >
														<label>Date of Diocan<span className="text-danger"></span></label>
													</div>
													<div className="col-md-9" >
														<DatePicker
															value={this.state.d_dodiocan}
															placeholder="Pick a day"
															format="yyyy-MM-dd"
															align="right"
															onChange={(date) => { this.onDateChange('Dodiocan', date) }}
															style={{ width: '100%' }}
														/>
														{this.validator.message('Dodiocan', this.state.d_dodiocan, '')}

													</div>
												</div>

												<div className="row" style={{ marginBottom: 10 }}  >
													<div className="col-md-3" >
														<label>Place of Diocan<span className="text-danger"></span></label>
													</div>
													<div className="col-md-9" >
														<input type="text" name="d_placeofdiocan" id="d_placeofdiocan" className="form-control" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
														{this.validator.message('Placeofdiocan', this.state.d_placeofdiocan, 'integer|min:10|max:10')}
													</div>
												</div>

												<div className="row" style={{ marginBottom: 10 }}  >
													<div className="col-md-3" >
														<label>Bishop of Diocan<span className="text-danger"></span></label>
													</div>
													<div className="col-md-9" >
														<input type="text" name="d_bishopofdiocan" id="d_bishopofdiocan" className="form-control" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
														{this.validator.message('Bishopofdiocan', this.state.d_bishopofdiocan, 'integer|min:10|max:10')}
													</div>
												</div>

												<div className="row" style={{ marginBottom: 10 }}  >
													<div className="col-md-3" >
														<label>Date of Ordination<span className="text-danger"></span></label>
													</div>
													<div className="col-md-9" >
														<DatePicker
															value={this.state.d_doordinate}
															placeholder="Pick a day"
															format="yyyy-MM-dd"
															align="right"
															onChange={(date) => { this.onDateChange('Doordination', date) }}
															style={{ width: '100%' }}
														/>
														{this.validator.message('Doordination', this.state.d_doordinate, '')}

													</div>
												</div>

												<div className="row" style={{ marginBottom: 10 }}  >
													<div className="col-md-3" >
														<label>Place of Ordination<span className="text-danger"></span></label>
													</div>
													<div className="col-md-9" >
														<input type="text" name="d_placeofordinate" id="d_placeofordinate" className="form-control" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
														{this.validator.message('Placeofordination', this.state.d_placeofordinate, 'integer|min:10|max:10')}
													</div>
												</div>

												<div className="row" style={{ marginBottom: 10 }}  >
													<div className="col-md-3" >
														<label>Bishop of Ordination<span className="text-danger"></span></label>
													</div>
													<div className="col-md-9" >
														<input type="text" name="d_bishopofordinate" id="d_bishopofordinate" className="form-control" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
														{this.validator.message('Bishopofordination', this.state.d_bishopofordinate, 'integer|min:10|max:10')}
													</div>
												</div>


												<div className="row" style={{ marginBottom: 10 }}  >
													<div className="col-md-3" >
														<label>Blood Group<span className="text-danger"></span></label>
													</div>
													<div className="col-md-9" >
														<input type="text" name="d_bloodgroup" id="d_bloodgroup" className="form-control" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
														{this.validator.message('Bloodgroup', this.state.d_bloodgroup, 'integer|min:10|max:10')}
													</div>
												</div>


												<div className="row" style={{ marginBottom: 10 }}  >
													<div className="col-md-3" >
														<label>Email<span className="text-danger"></span></label>
													</div>
													<div className="col-md-9" >
														<input type="text" name="d_email" id="d_email" className="form-control" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
														{this.validator.message('Email', this.state.d_email, 'email')}
													</div>
												</div>

												<div className="row" style={{ marginBottom: 10 }}  >
													<div className="col-md-3" >
														<label>Whatsapp<span className="text-danger"></span></label>
													</div>
													<div className="col-md-9" >
														<input type="text" name="d_whatsapp" id="d_whatsapp" className="form-control" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
														{this.validator.message('Whatsappno', this.state.d_whatsapp, 'integer')}
													</div>
												</div>

												<div className="row" style={{ marginBottom: 10 }}  >
													<div className="col-md-3" >
														<label>Others<span className="text-danger"></span></label>
													</div>
													<div className="col-md-9" >
														<input type="text" name="d_others" id="d_others" className="form-control" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
														{this.validator.message('Others', this.state.d_others, 'integer')}
													</div>
												</div>
											</div>
											<div className="col-md-6">

												<div className="row" style={{ marginBottom: 10 }}  >
													<div className="col-md-3" >
														<label>Emergency Care<span className="text-danger"></span></label>
													</div>
													<div className="col-md-9" >
														<input type="text" name="d_emcare" id="d_emcare" className="form-control" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
														{this.validator.message('Emercencycare', this.state.d_emcare, 'integer|min:10|max:10')}
													</div>
												</div>

												<div className="row" style={{ marginBottom: 10 }}  >
													<div className="col-md-3" >
														<label>Emergency No<span className="text-danger"></span></label>
													</div>
													<div className="col-md-9" >
														<input type="text" name="d_emno" id="d_emno" className="form-control" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
														{this.validator.message('Emergencyno', this.state.d_emno, 'integer|min:10|max:10')}
													</div>
												</div>

												<div className="row" style={{ marginBottom: 10 }}  >
													<div className="col-md-3" >
														<label>Emergency Contact Person<span className="text-danger"></span></label>
													</div>
													<div className="col-md-9" >
														<input type="text" name="d_emcp" id="d_emcp" className="form-control" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
														{this.validator.message('EmercencyContactPerson', this.state.d_emcp, 'integer|min:10|max:10')}
													</div>
												</div>
												<div className="row" style={{ marginBottom: 10 }}  >
													<div className="col-md-3" >
														<label>Parish</label>
													</div>
													<div className="col-md-9" >
														<Select value={this.state.d_parish} onChange={this.onSelectChange.bind(this, 'Parish')} style={{ width: '100%' }}>
															{
																this.state.parishes.map(el => {
																	return <Select.Option key={el.id} label={el.name} value={el.id} />
																})
															}
														</Select>
														{/*this.validator.message('Parish', this.state.d_parish, '')*/}
													</div>
												</div>
												<div className="row" style={{ marginBottom: 10 }}  >
													<div className="col-md-3" >
														<label>Publication Title 1<span className="text-danger"></span></label>
													</div>
													<div className="col-md-9" >
														<input type="text" name="d_pubtitle" id="d_pubtitle" className="form-control" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
														{this.validator.message('Publicationtitle', this.state.d_pubtitle, 'integer|min:10|max:10')}
													</div>
												</div>


												<div className="row" style={{ marginBottom: 10 }}  >
													<div className="col-md-3" >
														<label>Publication Type 1<span className="text-danger"></span></label>
													</div>
													<div className="col-md-9" >
														<input type="text" name="d_pubtype" id="d_pubtype" className="form-control" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
														{this.validator.message('Publicationtype', this.state.d_pubtype, 'integer|min:10|max:10')}
													</div>
												</div>

												<div className="row" style={{ marginBottom: 10 }}  >
													<div className="col-md-3" >
														<label>Publication Year 1<span className="text-danger"></span></label>
													</div>
													<div className="col-md-9" >
														<input type="text" name="d_pubyear" id="d_pubyear" className="form-control" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
														{this.validator.message('Publicationyear', this.state.d_pubyear, 'integer|min:10|max:10')}
													</div>
												</div>

												<div className="row" style={{ marginBottom: 10 }}  >
													<div className="col-md-3" >
														<label>Publication Link 1<span className="text-danger"></span></label>
													</div>
													<div className="col-md-9" >
														<input type="text" name="d_publink" id="d_publink" className="form-control" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
														{this.validator.message('PublicationLink', this.state.d_publink, 'integer|min:10|max:10')}
													</div>
												</div>

												<div className="row" style={{ marginBottom: 10 }}  >
													<div className="col-md-3" >
														<label>Publication Title 2<span className="text-danger"></span></label>
													</div>
													<div className="col-md-9" >
														<input type="text" name="d_pubtitle_two" id="d_pubtitle_two" className="form-control" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
														{this.validator.message('Publicationtitletwo', this.state.d_pubtitle_two, 'integer|min:10|max:10')}
													</div>
												</div>

												<div className="row" style={{ marginBottom: 10 }}  >
													<div className="col-md-3" >
														<label>Publication Type 2<span className="text-danger"></span></label>
													</div>
													<div className="col-md-9" >
														<input type="text" name="d_pubtype_two" id="d_pubtype_two" className="form-control" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
														{this.validator.message('Publicationtypetwo', this.state.d_pubtype_two, 'integer|min:10|max:10')}
													</div>
												</div>

												<div className="row" style={{ marginBottom: 10 }}  >
													<div className="col-md-3" >
														<label>Publication Year 2<span className="text-danger"></span></label>
													</div>
													<div className="col-md-9" >
														<input type="text" name="d_pubyear_two" id="d_pubyear_two" className="form-control" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
														{this.validator.message('Publicationyeartwo', this.state.d_pubyear_two, 'integer|min:10|max:10')}
													</div>
												</div>

												<div className="row" style={{ marginBottom: 10 }}  >
													<div className="col-md-3" >
														<label>Publication Link 2<span className="text-danger"></span></label>
													</div>
													<div className="col-md-9" >
														<input type="text" name="d_publink_two" id="d_publink_two" className="form-control" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
														{this.validator.message('PublicationLinktwo', this.state.d_publink_two, 'integer|min:10|max:10')}
													</div>
												</div>

												<div className="row" style={{ marginBottom: 10 }}  >
													<div className="col-md-3" >
														<label>Image<span className="text-danger"></span></label>
													</div>
													<div className="col-md-4" >
														<div className="form-group" >
															<ReactFileReader handleFiles={this.handleFiles} fileTypes='image/*' base64={true}>
																<img src={this.state.files != '' ? this.state.files : NOIMAGE} style={{ border: '1px solid #cccccc', width: '100%', cursor: 'pointer' }} />
															</ReactFileReader>
														</div>
													</div>
												</div>

											</div>
										</div>


										<div className="row" style={{ marginBottom: 10 }}  >
											<div className="col-md-12" >
												<div className="text-right" >


													<button type="submit" name="i__save" className="btn btn-success btn-sm" disabled={this.state.saveText != 'Save'} >
														<i className="fa fa-check"></i> {this.state.saveText}
													</button>

												</div>
											</div>
										</div>

									</form>
								</div>
							</div>
						</div>
					</section>
				</div>
			</div>
		);
	}

}
export default Priest;