
import axios from 'axios';
import JWT from 'jwt-simple';
import {apiUrl,PSJSC} from './Variables';

const BaseURL = apiUrl;

export function GetData(type, userData) {

	return new Promise((resolve, reject) =>{
		fetch(BaseURL+type, {
			method: 'GET',
			body: JSON.stringify(userData)
		})
		.then((response) => response.json())
		.then((res) => {
			resolve(res);
		})
		.catch((error) => {
			reject(error);
		});
	});
}

export function PostData(type, userData) {
	
	return new Promise((resolve, reject) =>{
		fetch(BaseURL+type, {
			method: 'POST',
			body: JSON.stringify(userData)
		})
		.then((response) => response.json())
		.then((res) => {
			resolve(res);
		})
		.catch((error) => {
			reject(error);
		});
	});
}

export function axGet(type,tokenid){
	
	return new Promise((resolve, reject) =>{
		axios.get(BaseURL+type, {
			method: 'GET',
			headers : {'Authorization-Token': tokenid}
		})
		.then((response) =>  {
			resolve(response);
		})
		.catch((error) => {
			reject(error);
		});
	});

}

export function axPost(type,userData,tokenid){
	
		
	if(navigator.onLine){
		
		return new Promise((resolve, reject) =>{
			axios.post(BaseURL+type,userData,{
				headers : {'Authorization-Token': tokenid}
			}).then((response) =>  {
				resolve(response);
			})
			.catch((error) => {
				reject(error);
			});
		});
		
	}
}


export function Token(data){
	var token = JWT.encode(data, PSJSC);
	return token;
}

export function TokenData(data){
	var token = JWT.decode(data, PSJSC);
	return token;
}

export function getTokenData(fieldName,fromData='own'){
	
	var data = localStorage.getItem('dbs_retailer_data');
	
	if( fromData=='wl' ) data = localStorage.getItem('dbs_retailer_wldata');
	
	if(!data) return false;
	
	let s = JWT.decode(data, PSJSC);
	
	if(Object.keys(s).length>0)
	{
		return (s[fieldName]) ? s[fieldName] : false;
	}
	else
	{
		return false;
	}
}

export function TimeStamp(){
	var date = new Date();
	var timestamp = [];
	timestamp[0] = date.getTime();
	var token = JWT.encode(timestamp, PSJSC);
	return token;
}