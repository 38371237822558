import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import $ from 'jquery';
import {appName,copyRight,imagePath} from '../../utils/Variables';
import * as Reqst from '../../utils/Reqst';
import Sanitize from '../../utils/Sanitize';
import {notify} from 'react-notify-toast';
import {Helmet} from 'react-helmet';
import swal from 'sweetalert';
import Cookies from 'universal-cookie';
import ReactFileReader from 'react-file-reader';
import {Button,Tooltip,Loading,Notification } from 'element-react';
import NOIMAGE from '../../assets/img/noimage.jpg';
import AppTitle from '../../containers/AppTitle';
import FullPageLoader from '../../containers/FullPageLoader';

class Settings extends Component{
	constructor(props){
		super(props);
		this.state = {
			saveText: 'Update Info',
			isRedirect: false,
			showLoader: false,
			cmpData: [],
			formode : false,
			dataLoading : false,
			dataView: [],
			logo: '',
			viewpass : false,
			expired : false,
			updatedLogo: '',
		}
		this.logoOnChange = this.logoOnChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}
	
	componentDidMount(){
		
		let d = JSON.parse(Sanitize.getStoreageItem('userData'));
		this.setState({cmpData: d});
		this.loadData();
	}
	
	viewPassword(){
		this.setState({viewpass : !this.state.viewpass});
	}
	
	loadData(){
		
		this.setState({dataLoading:true});
		let d = JSON.parse(Sanitize.getStoreageItem('userData'));
		var token = Sanitize.getStoreageItem('accessToken');
		Reqst.axGet('api/v1/getuser/'+d.id,token).then(res=>{
			
				let result = res['data'];
				
				if(result){
						if(result.status=='1')
						{
							this.setState({dataView: result.data, dataLoading:false});
						}
						else if(result.status=='2')
						{
							alert(result.message);
							this.setState({dataLoading:false});
							this.setState({expired:true});
						}
						else
						{
							Notification({message:result.message,type: 'error'});
							this.setState({dataLoading:false});
						}
				 }
				 else{
					 
							Notification({message:'Something went wrong Try again...',type: 'error'});
							this.setState({dataLoading:false});
				  }
			});
	}
	
	getnovalues(str) {

		if (str != '' && str != null) {
			return str;
		}
		else {

			return 'Not Assigned';
		}

	}

	getData(field){
		let d = this.state.dataView;
		return d[field];
	}
	
	setData(field,e){
		var v = e.target.value;
		let d = this.state.dataView;
		d[field] = v;
		this.setState({dataView:d});
	}
	
	logoOnChange(files){
		
		this.setState({logo: files.base64, updatedLogo: files.base64});
	}
	
	handleSubmit(e){
		try{
		
			e.preventDefault();
			this.setState({showLoader:true, saveText: 'Updating..'});
			var token = Sanitize.getStoreageItem('accessToken');
			Reqst.axPost('api/v1/updateuser',$("#frmSettings").serialize(),token).then(res=>{
				if(res['data'].status=='1')
				{
					Notification({message:res['data'].message,type: 'success'});
					Sanitize.setStorage('userData', JSON.stringify(res['data'].user));
					Sanitize.setStorage('userId', res['data'].userid);
					this.setState({showLoader:false, saveText:'Update Info',formode:false,viewpass : false});
					this.loadData();
					$('html, body').animate({scrollTop:0}, 'slow');
				}
				else if(res['data'].status=='2')
				{
					  alert(res['data'].message);
					  this.setState({saveText:'Update Info'});
					  this.setState({expired:true});
				}
				else
				{
					Notification({message:res['data'].message,type: 'error'});
					this.setState({showLoader:false, saveText:'Update Info'});
					$('html, body').animate({scrollTop:0}, 'slow');
				}
			});
		}
		catch(e){
			console.log(e);
		}
	}
	
	render(){
		
		
		
		if(this.state.expired)
		{
			return (<Redirect to="/login" />);
		}
		
		let img = NOIMAGE;
		const logo = this.getData('logo');
		if(logo && logo.length>5) img = imagePath.uploadPath+logo;
		if(this.state.updatedLogo.length>6) img = this.state.updatedLogo;
		
		return(
			<div>
				
				<Helmet>
					<title>{appName} | Settings</title>
				</Helmet>
				
				
				<div className="topbar-header">
						<div className="topbar-header__content">
						
						   <AppTitle
								menuLevel1="Settings"
								isbreadcumb={true}
							/>
						
				       
						<div className="topbar_buttons">
								
						</div>
					 </div>
				</div>
				
				<div className="main_content">
				{!this.state.formode ? 
				  <div className="col-md-8 offset-md-2">
				    <div className="card" >
							
					   <div className="card-body" >
					   
					   {this.state.dataLoading ?
					   
						   <div className="loader_div">
						     <Loading />
						   </div>
						:
					     <div className="row">
					   
					      <div className="col-md-3">
							 <img src={img} style={{ maxHeight:'124px', width:'100%',borderRadius:'5px'}} />
						   </div>
						  
						   <div className="col-md-4">
								<Tooltip className="item" content="Company" placement="left" >
									<span style={{textTransform:'capitalize'}}><span className="text-info"><i className="fa fa-building" style={{ fontWeight: 'bold', fontSize: '18px' }}> </i></span>&nbsp;&nbsp;{this.getData('name')}</span>
								</Tooltip>
								<Tooltip className="item" content="Mobile" placement="left" >
									<span style={{textTransform:'capitalize'}}><span className="text-info"><i className="fa fa-mobile" style={{ fontWeight: 'bold', fontSize: '18px' }}> </i></span>&nbsp;&nbsp;&nbsp;&nbsp;{this.getnovalues(this.getData('mobile'))}</span>
								</Tooltip>
								<Tooltip className="item" content="Phone" placement="left" >
									<span style={{textTransform:'capitalize'}}><span className="text-info"><i className="fa fa-phone" style={{ fontWeight: 'bold', fontSize: '18px' }}> </i></span>&nbsp;&nbsp;{this.getnovalues(this.getData('phone'))}</span>
								</Tooltip>
								<Tooltip className="item" content="Email" placement="left" >
									<span><span className="text-info"><i className="fa fa-envelope" style={{ fontWeight: 'bold', fontSize: '18px' }}> </i></span>&nbsp;{this.getnovalues(this.getData('email'))}</span>
								</Tooltip>
								
								<Tooltip className="item" content="City" placement="left" >
									<span style={{textTransform:'capitalize'}}><span className="text-info"><i className="fa fa-map-o" style={{ fontWeight: 'bold', fontSize: '18px' }}> </i></span>&nbsp;&nbsp;{this.getnovalues(this.getData('city'))}</span>
								</Tooltip>
						   </div>
						  
						   <div className="col-md-4">
						   
						       
								
								<Tooltip className="item" content="Address" placement="left" >
									<span style={{textTransform:'capitalize'}}><span className="text-info"><i className="fa fa-map-marker" style={{ fontWeight: 'bold', fontSize: '18px' }}> </i></span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{this.getnovalues(this.getData('address'))}</span>
								</Tooltip>
								<Tooltip className="item" content="Pincode" placement="left" >
									<span style={{textTransform:'capitalize'}}><span className="text-info"><i className="fa fa-map-pin" style={{ fontWeight: 'bold', fontSize: '18px' }}> </i></span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{this.getnovalues(this.getData('pincode'))}</span>
								</Tooltip>
								<Tooltip className="item" content="State" placement="left" >
									<span style={{textTransform:'capitalize'}}><span className="text-info"><i className="fa fa-location-arrow" style={{ fontWeight: 'bold', fontSize: '18px' }}> </i></span>&nbsp;&nbsp;&nbsp;&nbsp;{this.getnovalues(this.getData('state'))}</span>
								</Tooltip>
								<Tooltip className="item" content="Country" placement="left" >
									<span style={{textTransform:'capitalize'}}><span className="text-info"><i className="fa fa-globe" style={{ fontWeight: 'bold', fontSize: '18px' }}> </i></span>&nbsp;&nbsp;&nbsp;&nbsp;{this.getnovalues(this.getData('country'))}</span>
								</Tooltip>
								
								
								
						   </div>
						   
						   <div className="col-md-1">
						   
							   <span className="pull-right">
									<Tooltip className="item" content="Update" placement="bottom" >
											<Button type="text" size="large" onClick={() => this.setState({ formode: true})}>
												<i className="fa fa-edit" />
											</Button>
										</Tooltip>
								</span>
						  </div>
								
					    </div>
					    }
					  </div>
				     </div>
				 </div>
				
				: '' }
				
				{this.state.formode ? 
				<div>
				<form action="" method="post" encType="multipart/form-data" id="frmSettings" onSubmit={this.handleSubmit} >
			
				 <section>
					<div className="container" >
						<div className="row">
							<div className="col-md-8 offset-md-2">
								<div className="card" >
									<h4 className="card-header bg-info text-white">Settings</h4>
									<div className="card-body" >
											
											<input type="hidden" name="i__id" value={this.getData('id')} />
											<input type="hidden" name="i__logo" value={this.state.updatedLogo} />
											
										<div className="row" style={{ marginBottom: 10 }}  >
											<div className="col-md-9">
										
												<div className="row" style={{ marginBottom: 10 }}  >
													<div className="col-md-4" >
														<label>Company Name <span className="text-danger">*</span></label>
													</div>
													<div className="col-md-8" >
														<input type="text" name="i__name" id="i__name" className="form-control" required placeholder="Company Name" value={this.getData('name')} onChange={this.setData.bind(this,'name')} />
													</div>
												</div>
												
												<div className="row" style={{ marginBottom: 10 }} >
													<div className="col-md-4" >
														<label>Address <span className="text-danger">*</span></label>
													</div>
													<div className="col-md-8" >
														<textarea name="i__address" id="i__address" className="form-control" required placeholder="Address" rows="4" value={this.getData('address')} onChange={this.setData.bind(this,'address')} ></textarea>
													</div>
												</div>
											</div>
											<div className="col-md-3" >
												<label>Logo</label>
												<ReactFileReader base64={true} handleFiles={this.logoOnChange} >
													<img src={img} style={{ maxHeight:'124px', width:'100%', cursor: 'pointer', border:'1px solid grey'}} />
												</ReactFileReader>
											</div>
										</div>
												
											<div className="row" style={{ marginBottom: 10 }}  >
												<div className="col-md-3" >
													<label>Place <span className="text-danger">*</span></label>
												</div>
												<div className="col-md-9" >
													<input type="text" name="i__place" id="i__place" className="form-control" required placeholder="Place" value={this.getData('place')} onChange={this.setData.bind(this,'place')} />
												</div>
											</div>
											
											<div className="row" style={{ marginBottom: 10 }}  >
												<div className="col-md-3" >
													<label>City <span className="text-danger">*</span></label>
												</div>
												<div className="col-md-9" >
													<input type="text" name="i__city" id="i__city" className="form-control" required placeholder="City" value={this.getData('city')} onChange={this.setData.bind(this,'city')} />
												</div>
											</div>
											
											<div className="row" style={{ marginBottom: 10 }}  >
												<div className="col-md-3" >
													<label>State <span className="text-danger">*</span></label>
												</div>
												<div className="col-md-9" >
													<input type="text" name="i__state" id="i__state" className="form-control" required placeholder="State" value={this.getData('state')} onChange={this.setData.bind(this,'state')} />
												</div>
											</div>
											
											<div className="row" style={{ marginBottom: 10 }}  >
												<div className="col-md-3" >
													<label>Country & Pincode <span className="text-danger">*</span></label>
												</div>
												<div className="col-md-5" >
													<input type="text" name="i__country" id="i__country" className="form-control" required placeholder="Country"  value={this.getData('country')} onChange={this.setData.bind(this,'country')} />
												</div>
												<div className="col-md-4" >
													<input type="number" name="i__pincode" id="i__pincode" className="form-control" required placeholder="Pincode"  value={this.getData('pincode')} onChange={this.setData.bind(this,'pincode')} />
												</div>
											</div>
											
											<div className="row" style={{ marginBottom: 10 }}  >
												<div className="col-md-3" >
													<label>Mobile <span className="text-danger">*</span></label>
												</div>
												<div className="col-md-5" >
													<input type="number" name="i__mobile" id="i__mobile" className="form-control" required placeholder="Mobile (Required)" value={this.getData('mobile')} onChange={this.setData.bind(this,'mobile')} />
												</div>
												<div className="col-md-4" >
													<input type="number" name="i__mobile2" id="i__mobile2" className="form-control"  placeholder="Alternate Contact Number" value={this.getData('mobile2')} onChange={this.setData.bind(this,'mobile2')} />
												</div>
											</div>
											
											<div className="row" style={{ marginBottom: 10 }}  >
												<div className="col-md-3" >
													<label>Phone <span className="text-danger"></span></label>
												</div>
												<div className="col-md-9" >
													<input type="text" name="i__phone" id="i__phone" className="form-control"  placeholder="Phone" value={this.getData('phone')} onChange={this.setData.bind(this,'phone')} />
												</div>
											</div>
											
											<div className="row" style={{ marginBottom: 10 }}  >
												<div className="col-md-3" >
													<label>Email <span className="text-danger"></span></label>
												</div>
												<div className="col-md-9" >
													<input type="email" name="i__email" id="i__email" className="form-control"  placeholder="Email ID"  value={this.getData('email')} onChange={this.setData.bind(this,'email')} />
												</div>
											</div>
											
											<div className="row" style={{ marginBottom: 10 }}  >
												<div className="col-md-3" >
													<label>Whatsapp <span className="text-danger"></span></label>
												</div>
												<div className="col-md-9" >
													<input type="text" name="i__whatsapp" id="i__whatsapp" className="form-control"  placeholder="Whatsapp" value={this.getData('whatsapp')} onChange={this.setData.bind(this,'whatsapp')} />
												</div>
											</div>
											
											<div className="row" style={{ marginBottom: 10 }}  >
												<div className="col-md-3" >
													<label>Facebook Page <span className="text-danger"></span></label>
												</div>
												<div className="col-md-9" >
													<input type="text" name="i__facebook" id="i__facebook" className="form-control"  placeholder="Facebook" value={this.getData('facebook')} onChange={this.setData.bind(this,'facebook')} />
												</div>
											</div>
											
											<div className="row" style={{ marginBottom: 10 }}  >
												<div className="col-md-3" >
													<label>Twitter <span className="text-danger"></span></label>
												</div>
												<div className="col-md-9" >
													<input type="text" name="i__twitter" id="i__twitter" className="form-control"  placeholder="Twitter" value={this.getData('twitter')} onChange={this.setData.bind(this,'twitter')} />
												</div>
											</div>
											
											<div className="row" style={{ marginBottom: 10 }}  >
												<div className="col-md-3" >
													<label>Username <span className="text-danger">*</span></label>
												</div>
												<div className="col-md-9" >
													<input type="text" name="i__uname" id="i__uname" className="form-control" required placeholder="Username" value={this.getData('username')} onChange={this.setData.bind(this,'username')} />
												</div>
											</div>
											
											<div className="row" style={{ marginBottom: 10 }}  >
												<div className="col-md-3" >
													<label>Password <span className="text-danger">*</span></label>
												</div>
												<div className="col-md-9" >
													<input type={this.state.viewpass == true ? "text" : "password"} name="i__pass" id="i__pass" className="form-control"  placeholder="Password" required value={this.getData('password')} onChange={this.setData.bind(this,'password')} />
													 <span className="text-primary"  onClick={this.viewPassword.bind(this)} style={{position : 'absolute',top : '7px',right : '25px'}}><i className="fa fa-eye fa-lg" ></i></span>
												</div>
											</div>
										
											<div className="row" style={{ marginBottom: 10 }}  >
												<div className="col-md-12" >
													<div className="text-right" >
														
														<button  className="btn btn-danger btn-sm"  onClick={() => this.setState({ formode: false})}>
															<i className="fa fa-remove"></i> Cancel
														</button>
														&nbsp;
														<button type="submit" name="i__save" className="btn btn-success btn-sm" disabled={this.state.saveText!='Update Info'} >
															<i className="fa fa-check"></i> {this.state.saveText}
														</button>
														
													</div>
												</div>
											</div>
										
									</div>
								</div>
							</div>
						</div>
					</div>
				  </section>
				
				  </form>
				  </div>
	            : '' }
				</div>
			</div>
		);
	}
	
}
export default Settings;