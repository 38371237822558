import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import $ from 'jquery';
import {appName,copyRight} from '../../utils/Variables';
import * as Reqst from '../../utils/Reqst';
import Sanitize from '../../utils/Sanitize';
import Notifications, {notify} from 'react-notify-toast';
import {Helmet} from 'react-helmet';
import {Row,Col,Card} from 'react-bootstrap';
import swal from 'sweetalert';



class Footer extends Component{
	constructor(props){
		super(props);
		this.state = {
			loginText: 'Login',
			isRedirect: false,
			showLoader: false,
		}
	}
	

	render(){
		
		return(
			 <div className="footer">
				Copyright &copy; Designed & Developed by <a href="http://peacesoft.in" target="_blank">Peace Soft</a>
			 </div>
		);
	}
	
}
export default Footer;