import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import $ from 'jquery';
import { appName, copyRight, imagePath } from '../../utils/Variables';
import * as Reqst from '../../utils/Reqst';
import Sanitize from '../../utils/Sanitize';
import { notify } from 'react-notify-toast';
import { Helmet } from 'react-helmet';
import swal from 'sweetalert';
import Cookies from 'universal-cookie';
import ReactFileReader from 'react-file-reader';
import { Button, Tooltip, Loading, Notification, Select, DatePicker, Pagination, Radio, Tag } from 'element-react';
import NOIMAGE from '../../assets/img/noimage.jpg';
import AppTitle from '../../containers/AppTitle';
import FullPageLoader from '../../containers/FullPageLoader';
import SimpleReactValidator from 'simple-react-validator';
import { Row, Col, Table, Modal } from 'react-bootstrap';


class PriestList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isRedirect: false,
			dataLoading: false,
			showFilterModal: false,
			dataLoading1: true,
			expired: false,
			totalcount: 0,
			todosPerPage: 100,
			searchcolumn: '',
			offset: 0,
			addLink: false,
			states: [],
			dioceseList : [],
			dioceseLoading : false,
			countries: [],
			updateText: 'Update',
			activePage: 1,
			optionValue: '',
			searchcode: '',
			dataList: [],
			editData: [],
			showEditModal: false,
			tags: [],
			savedtags: [],
			diocese: [],
			province: [],
			files: '',
			photoname: '',
			dioceseLoading: false,
			reportsLink: false,
			provinceLoading: false
		}
		this.validator = new SimpleReactValidator();
		this.onPageChange = this.onPageChange.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
		this.setcolumn = this.setcolumn.bind(this);
		this.onPageSizeChange = this.onPageSizeChange.bind(this);
		this.handleInputBlur = this.handleInputBlur.bind(this);
		this.onRadioChange = this.onRadioChange.bind(this);
		this.onSearchChange = this.onSearchChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.onDateChange = this.onDateChange.bind(this);
		this.onSelectChange = this.onSelectChange.bind(this);
		this.handleFiles = this.handleFiles.bind(this);

	}
	
	getDataName(id) {

			var index = this.state.dioceseList.findIndex(function (obj) {
				return (obj.id == id);
			});
			var d = this.state.dioceseList;

			var name = 'Not Assigned';

			if (d[index]) {
				name = d[index].name;

			}

			return name;
		
	}
	
	
	onDioChange(val) {

		var orgdata = [];

		this.setState({ savedtags: [] });
		
		this.setState({ searchcode: val });

		var tags = this.state.tags;

		var column = 'diocesid';
		var customcolumn = 'Diocese';
		var value = val; //this.state.searchcode;
		var customvalue = this.getDataName(val);

		var cond = ' = ';


		var len = tags.length + 1;

		//value = ''{'+value+'}';

		var preisstrinval = "";
		var postisstrinval = "";

		column = 'a.' + column;


		var query = column + '' + cond + '' + preisstrinval + value + postisstrinval;

		this.state.tags.push({ "key": len, "name": customcolumn + '   ' + '"' + customvalue + '"', "column": column, "value": value, "query": query });

		orgdata = this.state.tags;
		this.setState({ tags: orgdata });

		this.setState({ searchcode: '' });
		this.forceUpdate();
		$("#searchcode").val('');

		//this.loadDataFilter();
		this.loadDataFilterCount();

	}

	handleFiles(files) {
		this.setState({ files: files.base64, photoname: files.fileList[0]['name'] });
	}

	
	handleInputBlur(e) {
		if (!this.validator.allValid()) {
			this.validator.showMessages();
			this.forceUpdate();
		}
	}
	
	loadDiocese() {

		this.setState({ dioceseList: [], dioceseLoading: true });
		var token = Sanitize.getStoreageItem('accessToken');
		Reqst.axGet('api/v1/getdioceseslist', token).then(res => {

			let result = res['data'];

			if (result) {
				if (result.status == '1') {
					this.setState({ dioceseList: result.data, dioceseLoading: false });
				}
				else if (result.status == '2') {
					alert(result.message);
					this.setState({ dioceseLoading: false });
					this.setState({ expired: true });
				}
				else {
					Notification({ message: result.message, type: 'error' });
					this.setState({ dioceseLoading: false });
				}
			}
			else {

				Notification({ message: 'Something went wrong Try again...', type: 'error' });
				this.setState({ dioceseLoading: false });
			}
		});
	}

	getData(field) {
		var l = this.state.editData;
		return l[field];
	}

	setData(field, e) {
		var l = this.state.editData;
		l[field] = e.target.value;
		this.setState({ editData: l });
	}

	setDataSelect(field, e) {
		var l = this.state.editData;
		l[field] = e;
		this.setState({ editData: l });

		if (field == 'country') {

			this.loadStates();
		}
	}

	setDataDate(value, field) {
		var l = this.state.editData;
		l[field] = value;
		this.setState({ editData: l });

	}

	

	loadProvince() {

		this.setState({ province: [], provinceLoading: true });
		var token = Sanitize.getStoreageItem('accessToken');
		Reqst.axGet('api/v1/getprovincelist', token).then(res => {

			let result = res['data'];

			if (result) {
				if (result.status == '1') {
					this.setState({ province: result.data, provinceLoading: false });
				}
				else if (result.status == '2') {
					alert(result.message);
					this.setState({ provinceLoading: false });
					this.setState({ expired: true });
				}
				else {
					Notification({ message: result.message, type: 'error' });
					this.setState({ provinceLoading: false });
				}
			}
			else {

				Notification({ message: 'Something went wrong Try again...', type: 'error' });
				this.setState({ provinceLoading: false });
			}
		});
	}

	onSearchChange() {

		var orgdata = [];

		this.setState({ savedtags: [] });

		var tags = this.state.tags;

		var column = this.state.searchcolumn;

		var value = $("#searchcode").val();

		var cond = this.state.optionValue;

		var displayname = this.getcolumnname(column) + ' ' + this.getconditionname(cond) + ' ' + value;

		//this.state.searchcode;

		if (cond == '3') {
			displayname = this.getcolumnname(column) + ' ' + this.getconditionname(cond);
			cond = ' = ';
			value = '';
		}
		else if (cond == '2') {

			cond = ' != ';
		}
		else if (cond == '1') {

			cond = ' LIKE ';
			value = '%' + value + '%';
		}


		var len = tags.length + 1;

		//value = ''{'+value+'}';

		var preisstrinval = "'";
		var postisstrinval = "'";



		if (column == 'dname') {

			column = 'b.name';
		}
		else if (column == 'pname') {

			column = 'c.provincename';
		}
		else {

			column = 'a.' + column;
		}



		var query = column + '' + cond + '' + preisstrinval + value + postisstrinval;

		this.state.tags.push({ "key": len, "name": displayname, "column": column, "value": value, "query": query });

		orgdata = this.state.tags;

		this.setState({ tags: orgdata });

		this.setState({ searchcolumn: '', searchfor: '', searchcode: '', });
		$("#searchcode").val('');

		//this.loadDataFilter();
		this.loadDataFilterCount();

	}

	getconditionname(cond) {

		var res = '';

		if (cond == '1') {

			res = 'Contains';

		}
		else if (cond == '2') {

			res = 'Not Contains';
		}
		else if (cond == '3') {

			res = 'Is Not set';
		}

		return res;

	}

	getcolumnname(cond) {

		var res = '';

		if (cond == 'name') {

			res = 'Name';

		}
		else if (cond == 'mobile') {

			res = 'Mobile';
		}
		else if (cond == 'dname') {

			res = 'Diocese';
		}
		else if (cond == 'pname') {

			res = 'Province';
		}

		return res;


	}

	handleInputChange(e) {
		this.setState({ [e.target.name]: e.target.value });
	}

	handleSubmit(e) {
		try {

			e.preventDefault();
			if (this.validator.allValid()) {
				this.setState({ updateText: 'Updating..' });
				var token = Sanitize.getStoreageItem('accessToken');
				Reqst.axPost('api/v1/update_priest', $("#frmUpdateDio").serialize(), token).then(res => {
					if (res['data'].status == '1') {
						Notification({ message: res['data'].message, type: 'success' });
						//this.loadData();
						this.loadDataCount();
						this.setState({ updateText: 'Update', showEditModal: false });
					}
					else if (res['data'].status == '2') {
						alert(res['data'].message);
						this.setState({ updateText: 'Save' });
						this.setState({ expired: true });
					}
					else {
						Notification({ message: res['data'].message, type: 'error' });
						this.setState({ saveText: 'Save' });
					}
				});
			}
			else {
				this.validator.showMessages();
				// rerender to show messages for the first time
				this.forceUpdate();
			}
		}
		catch (e) {
			console.log(e);
		}
	}

	setcolumn(value) {

		this.state.searchcolumn = value;
		this.setState({ searchcolumn: value });
	}

	onRadioChange(value) {

		this.state.optionValue = value;
		this.setState({ optionValue: value });
	}

	handleClose(tag) {
		const { tags } = this.state;

		tags.splice(tags.map(el => el.key).indexOf(tag.key), 1);

		this.setState({ tag });
		this.setState({ searchcolumn: '', optionValue: '', searchcode: '' });
		$("#searchcode").val('');
		//this.loadDataFilter();
		this.loadDataFilterCount();
	}

	onPageSizeChange(rpp) {

		var pc = parseInt(this.state.activePage);

		var offset = parseInt(rpp) * parseInt(pc);

		this.state.offset = offset;
		this.setState({ offset: offset });
		this.state.todosPerPage = rpp;
		this.setState({ todosPerPage: rpp });
		this.loadData();

	}

	onPageChange(page) {

		var rpp = this.state.todosPerPage;

		var pc = parseInt(page);
		
		pc = pc - 1;

		var offset = parseInt(rpp) * parseInt(pc);

		this.state.activePage = page;
		this.setState({ activePage: page });
		this.state.offset = offset;
		this.setState({ offset: offset });
		this.loadData();

	}

	handleEdit(id, e) {
		var list = this.state.dataList;
		var index = list.findIndex(function (obj) { return obj.id == id });
		var l = list[index];
		this.setState({ editData: l, showEditModal: true });
		this.loadCountries();
	}

	loadCountries() {

		this.setState({ countries: [], countryLoading: true });
		var token = Sanitize.getStoreageItem('accessToken');
		Reqst.axGet('api/v1/getcountries', token).then(res => {

			let result = res['data'];

			if (result) {
				if (result.status == '1') {
					this.setState({ countries: result.data, countryLoading: false });
					this.loadStates();
				}
				else if (result.status == '2') {
					alert(result.message);
					this.setState({ countryLoading: false });
					this.setState({ expired: true });
				}
				else {
					Notification({ message: result.message, type: 'error' });
					this.setState({ countryLoading: false });
				}
			}
			else {

				Notification({ message: 'Something went wrong Try again...', type: 'error' });
				this.setState({ countryLoading: false });
			}
		});
	}

	loadStates() {

		this.setState({ states: [], stateLoading: true });
		var country = this.getData('country');
		var token = Sanitize.getStoreageItem('accessToken');
		Reqst.axGet('api/v1/getstates/' + country, token).then(res => {

			let result = res['data'];

			if (result) {
				if (result.status == '1') {
					this.setState({ states: result.data, stateLoading: false });
				}
				else if (result.status == '2') {
					alert(result.message);
					this.setState({ stateLoading: false });
					this.setState({ expired: true });
				}
				else {
					Notification({ message: result.message, type: 'error' });
					this.setState({ stateLoading: false });
				}
			}
			else {

				Notification({ message: 'Something went wrong Try again...', type: 'error' });
				this.setState({ stateLoading: false });
			}
		});
	}

	componentDidMount() {

		//this.loadData();
		this.loadDataCount();
		this.loadDiocese();
	}

	getdateoforginal(date) {

		if (date != '' && date != null) {
			var date1 = new Date(date);
			var year = date1.getFullYear();
			var month = (date1.getMonth() + 1);
			var day = date1.getDate()
			var hours = date1.getHours();
			var minutes = date1.getMinutes();
			var seconds = date1.getSeconds();
			month = month < 10 ? '0' + month : month;
			day = day < 10 ? '0' + day : day;
			var strTime = year + '-' + month + '-' + day;
			return strTime;
		}
		else {

			return '- - - -';
		}

	}

	onDateChange(type, value) {
		if (type == 'dob') {
			this.setState({ d_dob: value });
		}
		else if (type == 'Dobaptism') {
			this.setState({ d_dobaptism: value });
		}
		else if (type == 'Dodiocan') {
			this.setState({ d_dodiocan: value });
		}
		else if (type == 'Doordination') {
			this.setState({ d_doordinate: value });
		}
	}

	onSelectChange(type, value) {

		if (type == 'Province') {

			this.setState({ d_province: value });
		}
		else if (type == 'Diocese') {

			this.setState({ d_diocese: value });
		}
	}

	checkIfArrayIsUnique() {
		var tags = this.state.tags;

		for (var i = 0; i < tags.length; i++) {
			for (var j = 0; j < tags.length; j++) {
				if (i != j) {
					if (tags[i]['column'] == tags[j]['column']) {
						return true;
						break;// means there are duplicate values
					}
				}
			}
		}
		return false; // means there are no duplicate values.
	}

	getdateoforginal(date) {

		if (date != '' && date != null) {
			var date1 = new Date(date);
			var year = date1.getFullYear();
			var month = (date1.getMonth() + 1);
			var day = date1.getDate()
			var hours = date1.getHours();
			var minutes = date1.getMinutes();
			var seconds = date1.getSeconds();
			month = month < 10 ? '0' + month : month;
			day = day < 10 ? '0' + day : day;
			var strTime = year + '-' + month + '-' + day;
			return strTime;
		}
		else {

			return '- - - -';
		}

	}

	loadDataFilter() {

		this.setState({ dataList: []});
		this.setState({ dataLoading: true });
		var token = Sanitize.getStoreageItem('accessToken');

		var total = this.state.totalcount;

		var limit = this.state.todosPerPage;

		var offset = this.state.offset;


		if (offset != 0) {

			limit = offset - limit;

		}
		else {

			offset = 100;
			limit = 0;

		}

		var tags = this.state.tags;
		var qdata = '';
		var cond = ' AND ';
		if (this.checkIfArrayIsUnique()) {

			cond = ' OR ';
		}
		if (tags.length > 0) {

			for (var i = 0; i < tags.length; i++) {

				if (i == 0) {

					qdata += tags[i]['query']

				}
				else {

					qdata += cond + tags[i]['query'];
				}

			}

		}
		/* var iscombine = '  ';

		if (this.checkIfArrayIsUnique()) {

			iscombine = ' OR ';
		} */

		qdata = qdata.replace(/,*$/, "");
		//qdata = iscombine + qdata;
		if (qdata == '') {
			qdata = 'no'
		}
		else {
			qdata = '(' + qdata + ')';
		}

		var data = { 'limit': limit, 'offset': offset, 'query': qdata };

		Reqst.axPost('api/v1/getpriestsfilterdata', data, token).then(res => {

			let result = res['data'];

			if (result) {
				if (result.status == '1') {
					this.setState({ dataList: result.data});
					this.setState({ dataLoading: false });
				}
				else if (result.status == '2') {
					alert(result.message);
					this.setState({ dataLoading: false });
					this.setState({ expired: true });
				}
				else {
					Notification({ message: result.message, type: 'error' });
					this.setState({ dataLoading: false });
				}
			}
			else {

				Notification({ message: 'Something went wrong Try again...', type: 'error' });
				this.setState({ dataLoading: false });
			}
		});
	}

	loadDataFilterCount() {

		this.setState({ totalcount: 0 });
		this.setState({ dataLoading: true });
		var token = Sanitize.getStoreageItem('accessToken');


		var tags = this.state.tags;
		var qdata = '';
		var cond = ' AND ';
		if (this.checkIfArrayIsUnique()) {

			cond = ' OR ';
		}
		if (tags.length > 0) {

			for (var i = 0; i < tags.length; i++) {

				if (i == 0) {

					qdata += tags[i]['query']

				}
				else {

					qdata += cond + tags[i]['query'];
				}

			}

		}
		/* var iscombine = '  ';

		if (this.checkIfArrayIsUnique()) {

			iscombine = ' OR ';
		} */

		qdata = qdata.replace(/,*$/, "");
		//qdata = iscombine + qdata;
		if (qdata == '') {
			qdata = 'no'
		}
		else {
			qdata = '(' + qdata + ')';
		}

		var data = { 'query': qdata };

		Reqst.axPost('api/v1/getpriestsfilterdatacount', data, token).then(res => {

			let result = res['data'];

			if (result) {
				if (result.status == '1') {
					this.setState({ totalcount: result.count});
					this.setState({ dataLoading: false });
					this.loadDataFilter();
				}
				else if (result.status == '2') {
					alert(result.message);
					this.setState({ dataLoading: false });
					this.setState({ expired: true });
				}
				else {
					Notification({ message: result.message, type: 'error' });
					this.setState({ dataLoading: false });
				}
			}
			else {

				Notification({ message: 'Something went wrong Try again...', type: 'error' });
				this.setState({ dataLoading: false });
			}
		});
	}

	loadData() {

		this.setState({ dataList: []});
        this.setState({ dataLoading: true });
		var token = Sanitize.getStoreageItem('accessToken');

		var total = this.state.totalcount;

		var limit = this.state.todosPerPage;

		var offset = this.state.offset;
		
		
		
	
		if (offset != 0) {

            //limit = offset - limit;
			
			if(total < offset){
		
				limit = total;
				offset = offset - this.state.todosPerPage;
				
			}
			
		   var activePage = this.state.activePage;
			
			if(activePage == 1){
			
				offset = 0;
			
		     }
			

        }
        else {
			
			if(total < 50){
				
				offset = 0;
				limit = total;
				
			}
			else{

				offset = 0;
				//limit = 50;
			}

        }



		var data = { 'limit': limit, 'offset': offset };

		Reqst.axPost('api/v1/getpriests', data, token).then(res => {

			let result = res['data'];

			if (result) {
				if (result.status == '1') {
					this.setState({ dataList: result.data, dataLoading: false });
				}
				else if (result.status == '2') {
					alert(result.message);
					this.setState({ dataLoading: false });
					this.setState({ expired: true });
				}
				else {
					Notification({ message: result.message, type: 'error' });
					this.setState({ dataLoading: false });
				}
			}
			else {

				Notification({ message: 'Something went wrong Try again...', type: 'error' });
				this.setState({ dataLoading: false });
			}
		});
	}

	loadDataCount() {

		this.setState({ totalcount: 0, dataLoading: true });
		var token = Sanitize.getStoreageItem('accessToken');

		Reqst.axGet('api/v1/getpriestcount', token).then(res => {

			let result = res['data'];

			if (result) {
				if (result.status == '1') {
					this.setState({ totalcount: result.count, dataLoading: false });
					this.loadData();
				}
				else if (result.status == '2') {
					alert(result.message);
					this.setState({ dataLoading: false });
					this.setState({ expired: true });
				}
				else {
					Notification({ message: result.message, type: 'error' });
					this.setState({ dataLoading: false });
				}
			}
			else {

				Notification({ message: 'Something went wrong Try again...', type: 'error' });
				this.setState({ dataLoading: false });
			}
		});
	}

	handleRemove(id) {

		swal({
			title: 'Confirm',
			text: 'Do you want to Remove this Priest ?',
			buttons: ['No', 'Yes']
		}).then((willYes) => {
			if (willYes) {

				this.removePriest(id);

			}
		});

	}

	removePriest(id) {

		var token = Sanitize.getStoreageItem('accessToken');
		var uid = Sanitize.getStoreageItem('userId');
		Reqst.axGet('api/v1/remove_priest/' + id + '/' + uid, token).then(res => {

			let result = res['data'];

			if (result) {
				if (result.status == '1') {
					Notification({ message: result.message, type: 'success' });
					//this.loadData();
					this.loadDataCount();
				}
				else if (result.status == '2') {
					alert(result.message);
					this.setState({ expired: true });
				}
				else {
					Notification({ message: result.message, type: 'error' });
				}
			}
			else {

				Notification({ message: 'Something went wrong Try again...', type: 'error' });
			}
		});

	}

	render() {

		if (this.state.addLink) {

			return (<Redirect to={'/configuration/addpriest'} />);

		}

		if (this.state.reportsLink) {

			return (<Redirect to={'/configuration/priests/reports'} />);

		}


		let sno = this.state.offset+1;

		const selcolumns = [
			{
				value: 'name',
				label: 'Name'
			},
			{
				value: 'dname',
				label: 'Diocese'
			},
			{
				value: 'pname',
				label: 'Province'
			},
			{
				value: 'mobile',
				label: 'Mobile'
			}
		];

		const types = [
			{
				value: 'diocese',
				label: 'Diocese'
			},
			{
				value: 'archdiocese',
				label: 'Archdiocese'
			}
		];

		if (this.state.expired) {
			return (<Redirect to="/login" />);
		}



		return (
			<div>

				<Helmet>
					<title>{appName} | Configuration | Priest </title>
				</Helmet>


				<div className="topbar-header">
					<div className="topbar-header__content">

						<AppTitle
							menuLevel1="Configuration"
							menuLevel2="Priest"
							isbreadcumb={true}
						/>


						<div className="topbar_buttons">
							<div className="desktop_pagination">

								<Pagination
									layout="prev,sizes,total,pager, next"
									total={this.state.totalcount}
									pageCount={this.state.totalcount}
									currentPage={this.state.activePage}
									small={true}
									pageSizes={[25, 50, 75, 100]}
									pageSize={this.state.todosPerPage}
									onCurrentChange={this.state.tags.length > 0 ? '' : this.onPageChange}
									onSizeChange={this.state.tags.length > 0 ? '' : this.onPageSizeChange}
								/>



							</div>
							&nbsp;
							<Tooltip className="item" content="Add an Priest" placement="bottom" >
								<Button type="text" onClick={() => this.setState({ addLink: true })}>
									<i className="fa fa-plus-circle" style={{ fontSize: '22px' }} />
								</Button>
							</Tooltip>
							&nbsp;&nbsp;
							<Tooltip className="item" content="Reports" placement="bottom" >
								<Button type="text" onClick={() => this.setState({ reportsLink: true })}>
									<i className="fa fa-file" style={{ fontSize: '22px' }} />
								</Button>
							</Tooltip>
							&nbsp;&nbsp;&nbsp;
								<Tooltip className="item" content="Filters" placement="bottom" >
								<Button type="text" onClick={() => this.setState({ showFilterModal: true })}>
									<i className="fa fa-filter" style={{ fontSize: '22px' }} />
								</Button>
							</Tooltip>
						</div>
					</div>
				</div>

				<div className="main_content">
					<section>
						<div className="card" >
							<h4 className="card-header bg-info text-white">Priest</h4>
							<div className="card-body" >
							
							 <div className="row" style={{ marginBottom: 25 }}  >
											<div className="col-md-3">
												
												<Select value={this.state.searchcode!='' ? this.state.searchcode : false} name="diocese" required style={{ width: '100%' }} onChange={this.onDioChange.bind(this)}  loading={this.state.dioceseLoading}>
													{
														this.state.dioceseList.map(el => {
															return <Select.Option key={el.id} label={el.name} value={el.id} />
														})
													}
												</Select>
											</div>
										 </div>
										 
								{this.state.tags.length > 0 ?
									<div style={{ marginBottom: '10px', marginTop: '0px' }}>
										{
											this.state.tags.map(tag => {
												return (
													<Tag
														key={tag.key}
														closable={true}
														type="primary"
														closeTransition={false}
														onClose={this.handleClose.bind(this, tag)}>{tag.name}</Tag>
												)
											})
										}

									</div>
									: ''}
								<Table responsive bordered striped >
									<thead>
										<tr>
											<th>S.No</th>
											<th>Name</th>
											<th>Diocese</th>
											<th>Parish</th>
											{/*<th>Province</th>*/}
											<th>Mobile</th>
											<th>Email</th>
											{/*<th>Blood Group</th>*/}
											<th>Manage</th>
										</tr>
									</thead>
									<tbody>
										{this.state.dataLoading ?
											<tr height="60"><td colSpan="8"><center>

												<Loading />

											</center>
											</td></tr>
											:
											this.state.dataList.length > 0 ?

												this.state.dataList.map((item, i) => {
													return <tr>
														<td width="80" >{sno++}</td>
														<td>{item.name}</td>
														<td style={{ textTransform: 'capitalize' }}>{item.dname}</td>
														<td style={{ textTransform: 'capitalize' }}>{item.parishname}</td>
														{/*<td style={{ textTransform: 'capitalize' }}>{item.pname}</td>*/}
														<td>{item.mobile}</td>
														<td>{item.email}</td>
														{/*<td>{item.bloodgroup}</td>*/}
														<td style={{textAlign:'center'}}>
															<Link to={"/configuration/editpriest/" + item.id}>
																<i className="fa fa-edit" style={{ fontSize: '16px' }} />
															</Link>
															&nbsp;&nbsp;&nbsp;
                                                            <Link to={"/configuration/addministry/" + item.id}>
																<i className="fa fa-plus" style={{ fontSize: '16px' }} />
															</Link>&nbsp;&nbsp;&nbsp;
															<Link to={"/configuration/addeducation/" + item.id}>
																<i className="fa fa-graduation-cap" style={{ fontSize: '16px' }} />
															</Link>&nbsp;&nbsp;&nbsp;
															<Button type="text" onClick={() => this.handleRemove(item.id)}>
																<i className="fa fa-remove" style={{ fontSize: '16px', color: '#009688' }} />
															</Button>
														</td>

													</tr>;
												})
												:
												<tr><td colSpan="8"><center>No Data Found...</center></td></tr>
										}
									</tbody>
								</Table>
								<div className="mobile_pagination">

										<Pagination
											layout="prev,pager,next"
											pageCount={this.state.totalcount}
											currentPage={this.state.activePage}
											small={true}
											total={this.state.totalcount}
											currentPage={this.state.activePage}
											pageSizes={[25, 50, 75, 100]}
											pageSize={this.state.todosPerPage}
											onCurrentChange={this.state.tags.length > 0 ? '' : this.onPageChange}
										/>

                                     </div>
							</div>

							<Modal size="md" show={this.state.showFilterModal} onHide={() => { this.setState({ showFilterModal: false }) }} className="right" >
								<Modal.Header closeButton >
									<Modal.Title>Filters</Modal.Title>
								</Modal.Header>
								<Modal.Body>
									<label>Select Field<span className="text-danger"></span></label>
									<Select onChange={this.setcolumn} value={this.state.searchcolumn} style={{ width: '100%' }}>
										{
											selcolumns.map(el => {
												return <Select.Option key={el.value} label={el.label} value={el.value} />
											})
										}
									</Select>
									<br /><br />
									<Radio value="1" checked={this.state.optionValue === 1} onChange={this.onRadioChange.bind(this)}>Contains</Radio>
									<Radio value="2" checked={this.state.optionValue === 2} onChange={this.onRadioChange.bind(this)}>Not Contains</Radio>
									<Radio value="3" checked={this.state.optionValue === 3} onChange={this.onRadioChange.bind(this)}>Not Set</Radio>
									<br />
									{this.state.optionValue != 3 && this.state.optionValue != '' ?
										<input type="text" name="searchcode" id="searchcode" className="form-control" required />
										:
										''}

									<br /> <br />

									{this.state.optionValue != '' && this.state.searchcolumn != '' ?

										<div className="text-center">
											<Button block onClick={this.onSearchChange} type="primary">
												<i className="fa fa-search" />&nbsp;Search
															</Button>
										</div>

										: ''}
								</Modal.Body>
							</Modal>

						</div>
					</section>
				</div>
			</div>
		);
	}

}
export default PriestList;