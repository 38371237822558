import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import $ from 'jquery';
import { appName, copyRight, apiUrl } from '../../utils/Variables';
import * as Reqst from '../../utils/Reqst';
import Sanitize from '../../utils/Sanitize';
import { notify } from 'react-notify-toast';
import { Helmet } from 'react-helmet';
import swal from 'sweetalert';
import Cookies from 'universal-cookie';
import ReactFileReader from 'react-file-reader';
import { Button, Tooltip, Loading, Notification, Select, DatePicker } from 'element-react';
import NOIMAGE from '../../assets/img/noimage.jpg';
import AppTitle from '../../containers/AppTitle';
import FullPageLoader from '../../containers/FullPageLoader';
import SimpleReactValidator from 'simple-react-validator';
import { Row, Col, Table, Modal } from 'react-bootstrap';


class EditMinistry extends Component {
    constructor(props) {
        super(props);
        this.state = {
            updateText: 'Update',
            isRedirect: false,
            dataLoading: false,
            diocese: [],
            parishes: [],
            province: [],
            states: [],
            expired: false,
            showEditModal: false,
            dioceseLoading: false,
            parishLoading: false,
            provinceLoading: false,
            designationList: [],
            desLoading: false,
            stateLoading: false,
            ministrydata: [],
            editData: this.props.data,
            d_type: '',
            d_erected: new Date()
        }
        this.validator = new SimpleReactValidator();
        this.handleUpdateSubmit = this.handleUpdateSubmit.bind(this);
    }

    componentDidMount() {
        this.loadDiocese();
        this.loadParish();
		this.getdesignation();
    }

    getdateoforginal(date) {

        if (date != '' && date != null) {
            var date1 = new Date(date);
            var year = date1.getFullYear();
            var month = (date1.getMonth() + 1);
            var day = date1.getDate()
            var hours = date1.getHours();
            var minutes = date1.getMinutes();
            var seconds = date1.getSeconds();
            month = month < 10 ? '0' + month : month;
            day = day < 10 ? '0' + day : day;
            var strTime = year + '-' + month + '-' + day;
            return strTime;
        }
        else {

            return '';
        }

    }

    loadDiocese() {

        this.setState({ diocese: [], dioceseLoading: true });
        var token = Sanitize.getStoreageItem('accessToken');
        Reqst.axGet('api/v1/getdioceseslist', token).then(res => {

            let result = res['data'];

            if (result) {
                if (result.status == '1') {
                    this.setState({ diocese: result.data, dioceseLoading: false });
                }
                else if (result.status == '2') {
                    alert(result.message);
                    this.setState({ dioceseLoading: false });
                    this.setState({ expired: true });
                }
                else {
                    Notification({ message: result.message, type: 'error' });
                    this.setState({ dioceseLoading: false });
                }
            }
            else {

                Notification({ message: 'Something went wrong Try again...', type: 'error' });
                this.setState({ dioceseLoading: false });
            }
        });
    }

    loadParish() {

        this.setState({ parishes: [], parishLoading: true });
        var token = Sanitize.getStoreageItem('accessToken');
        Reqst.axGet('api/v1/getparishlist', token).then(res => {

            let result = res['data'];

            if (result) {
                if (result.status == '1') {
                    this.setState({ parishes: result.data, parishLoading: false });
                }
                else if (result.status == '2') {
                    alert(result.message);
                    this.setState({ parishLoading: false });
                    this.setState({ expired: true });
                }
                else {
                    Notification({ message: result.message, type: 'error' });
                    this.setState({ parishLoading: false });
                }
            }
            else {

                Notification({ message: 'Something went wrong Try again...', type: 'error' });
                this.setState({ parishLoading: false });
            }
        });
    }

    getdesignation() {

        this.setState({ designationList: [], desLoading: true });
        var token = Sanitize.getStoreageItem('accessToken');
        var editdata = this.state.editData;
        var dep = editdata['department'];
        Reqst.axGet('api/v1/getdesignationbytype/' + dep, token).then(res => {

            let result = res['data'];

            if (result) {
                if (result.status == '1') {
                    this.setState({ designationList: result.data, desLoading: false });
                }
                else if (result.status == '2') {
                    alert(result.message);
                    this.setState({ desLoading: false });
                    this.setState({ expired: true });
                }
                else {
                    Notification({ message: result.message, type: 'error' });
                    this.setState({ desLoading: false });
                }
            }
            else {

                Notification({ message: 'Something went wrong Try again...', type: 'error' });
                this.setState({ desLoading: false });
            }
        });
    }

    getdesignationbydep(dep) {

        this.setState({ designationList: [], desLoading: true });
        var token = Sanitize.getStoreageItem('accessToken');
		/* var editdata = this.state.editData;
		var dep = editdata['department']; */
        if (!dep) {

            dep = '1';
        }

        Reqst.axGet('api/v1/getdesignationbytype/' + dep, token).then(res => {

            let result = res['data'];

            if (result) {
                if (result.status == '1') {
                    this.setState({ designationList: result.data, desLoading: false });
                }
                else if (result.status == '2') {
                    alert(result.message);
                    this.setState({ desLoading: false });
                    this.setState({ expired: true });
                }
                else {
                    //Notification({ message: result.message, type: 'error' });
                    this.setState({ desLoading: false });
                }
            }
            else {

                Notification({ message: 'Something went wrong Try again...', type: 'error' });
                this.setState({ desLoading: false });
            }
        });
    }

    setDataSelect(field, value) {
        var l = this.state.editData;
        l[field] = value;
        this.setState({ editData: l });

        if (field == 'department') {

            this.getdesignationbydep(value);
        }

    }

    setDataDate(value, field) {

        var l = this.state.editData;
        l[field] = value;
        this.setState({ editData: l });

    }

    handleUpdateSubmit(e) {
        try {

            e.preventDefault();
            if (this.validator.allValid()) {
                this.setState({ updateText: 'Updating..' });
                var token = Sanitize.getStoreageItem('accessToken');
                Reqst.axPost('api/v1/updateministry', $("#frmUpdateMinistry").serialize(), token).then(res => {
                    if (res['data'].status == '1') {
                        Notification({ message: res['data'].message, type: 'success' });
                        this.setState({ updateText: 'Update', showEditModal: false });
                    }
                    else if (res['data'].status == '2') {
                        alert(res['data'].message);
                        this.setState({ updateText: 'Update' });
                        this.setState({ expired: true });
                    }
                    else {
                        Notification({ message: res['data'].message, type: 'error' });
                        this.setState({ updateText: 'Update' });
                    }
                });
            }
            else {
                this.validator.showMessages();
                // rerender to show messages for the first time
                this.forceUpdate();
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    getData(field) {
        var l = this.state.editData;
        return l[field];
    }

    setData(field, e) {
        var l = this.state.editData;
        l[field] = e.target.value;
        this.setState({ editData: l });
    }

    render() {


        if (this.state.expired) {
            return (<Redirect to="/login" />);
        }

        const deps = [
            {
                value: '1',
                label: 'CDPI'
            },
            {
                value: '2',
                label: 'Diocese'
            },
            {
                value: '3',
                label: 'Parish'
            },
            {
                value: '4',
                label: 'Commission'
            },
            {
                value: '5',
                label: 'Center'
            },
            {
                value: '6',
                label: 'Other'
            }
        ];

        return (
            <div>
                <form action="" method="post" encType="multipart/form-data" id="frmUpdateMinistry" onSubmit={this.handleUpdateSubmit} >

                    <input type="hidden" name="i__id" value={Sanitize.getStoreageItem('userId')} />
                    <input type="hidden" name="tid" value={this.getData('id')} />
                    <input type="hidden" name="m_diocese_form" value={this.getData('diocese')} />
                    <input type="hidden" name="m_design_form" value={this.getData('designation')} />
                    <input type="hidden" name="m_dep_form" value={this.getData('department')} />
                    <input type="hidden" name="m_fromdate" value={this.getdateoforginal(this.getData('fromdate'))} />
                    <input type="hidden" name="m_todate" value={this.getdateoforginal(this.getData('todate'))} />

                    <div className="row" style={{ marginBottom: 10 }}  >
                        <div className="col-md-3" >
                            <label>Diocese<span className="text-danger">*</span></label>
                        </div>
                        <div className="col-md-9" >

                            <Select value={this.getData('diocese')} required onChange={this.setDataSelect.bind(this, 'diocese')} style={{ width: '100%' }} loading={this.state.dioceseLoading}>
                                {
                                    this.state.diocese.map(el => {
                                        return <Select.Option key={el.id} label={el.name} value={el.id} />
                                    })
                                }
                            </Select>

                            {this.validator.message('Diocese', this.getData('diocese'), 'required')}

                        </div>
                    </div>

                    <div className="row" style={{ marginBottom: 10 }}  >
                        <div className="col-md-3" >
                            <label>Department<span className="text-danger">*</span></label>
                        </div>
                        <div className="col-md-9" >

                            <Select value={this.getData('department')} required onChange={this.setDataSelect.bind(this, 'department')} style={{ width: '100%' }}>
                                {
                                    deps.map(el => {
                                        return <Select.Option key={el.value} label={el.label} value={el.value} />
                                    })
                                }
                            </Select>

                            {this.validator.message('Department', this.getData('department'), 'required')}

                        </div>
                    </div>

                    <div className="row" style={{ marginBottom: 10 }}  >
                        <div className="col-md-3" >
                            <label>Designation<span className="text-danger">*</span></label>
                        </div>
                        <div className="col-md-9" >

                            <Select value={this.getData('designation')} required onChange={this.setDataSelect.bind(this, 'designation')} style={{ width: '100%' }} loading={this.state.desLoading}>
                                {
                                    this.state.designationList.map(el => {
                                        return <Select.Option key={el.id} label={el.designation} value={el.id} />
                                    })
                                }
                            </Select>

                            {this.validator.message('Designation', this.getData('designation'), 'required')}

                        </div>
                    </div>

                    <div className="row" style={{ marginBottom: 10 }}  >
                        <div className="col-md-3" >
                            <label>From Date<span className="text-danger">*</span></label>
                        </div>
                        <div className="col-md-9" >
                            <DatePicker
                                value={this.getData('fromdate') != '0000-00-00' ? new Date(this.getData('fromdate')) : new Date()}
                                placeholder="Pick a day"
                                format="yyyy-MM-dd"
                                align="right"
                                onChange={(date) => { this.setDataDate(date, 'fromdate') }}
                                style={{ width: '100%' }}
                            />
                        </div>
                    </div>

                    <div className="row" style={{ marginBottom: 10 }}  >
                        <div className="col-md-3" >
                            <label>To Date<span className="text-danger">*</span></label>
                        </div>
                        <div className="col-md-9" >
                            <DatePicker
                                value={this.getData('todate') != '0000-00-00' ? new Date(this.getData('todate')) : new Date()}
                                placeholder="Pick a day"
                                format="yyyy-MM-dd"
                                align="right"
                                onChange={(date) => { this.setDataDate(date, 'todate') }}
                                style={{ width: '100%' }}
                            />
                        </div>
                    </div>

                    <div className="row" style={{ marginBottom: 10 }}  >
                        <div className="col-md-3" >
                            <label>Remarks<span className="text-danger"></span></label>
                        </div>
                        <div className="col-md-9" >
                            <textarea name="m_remarks" id="m_remarks" className="form-control" onChange={this.setData.bind(this, 'remark')} value={this.getData('remark')} ></textarea>

                        </div>
                    </div>

                    <div className="row" style={{ marginBottom: 10 }}  >
                        <div className="col-md-12" >
                            <div className="text-right" >

                                <button type="submit" name="i__save" className="btn btn-success btn-sm" disabled={this.state.updateText != 'Update'} >
                                    <i className="fa fa-check"></i> {this.state.updateText}
                                </button>

                            </div>
                        </div>
                    </div>

                </form>
            </div>
        );
    }

}
export default EditMinistry;