import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import $ from 'jquery';
import { appName, copyRight, apiUrl } from '../../utils/Variables';
import * as Reqst from '../../utils/Reqst';
import Sanitize from '../../utils/Sanitize';
import { notify } from 'react-notify-toast';
import { Helmet } from 'react-helmet';
import swal from 'sweetalert';
import Cookies from 'universal-cookie';
import ReactFileReader from 'react-file-reader';
import { Button, Tooltip, Loading, Notification, Select, DatePicker } from 'element-react';
import NOIMAGE from '../../assets/img/noimage.jpg';
import AppTitle from '../../containers/AppTitle';
import FullPageLoader from '../../containers/FullPageLoader';
import SimpleReactValidator from 'simple-react-validator';


class AddMinistry extends Component {
	constructor(props) {
		super(props);
		this.state = {
			saveText: 'Save',
			isRedirect: false,
			dataLoading: false,
			diocese: '',
			dioceseList: [],
			parishList: [],
			expired: false,
			dioceseLoading: false,
			provinceLoading: false,
			stateLoading: false,
			designation : '',
			d_type: '',
			priestdata: [],
			designationList: [],
			parish : '',
			files: '',
			photoname: '',
			type : '',
			d_state: '35',
			d_country: '101',
			d_erected: new Date()
		}
		this.validator = new SimpleReactValidator();
		this.handleInputChange = this.handleInputChange.bind(this);
		this.onDeptChange = this.onDeptChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	onDesiChange(val) {
		this.state.designation = val;
		this.setState({ designation: val });
	}

	onParishChange(val) {
		//this.state.parish = e;
		this.state.parish = val;
		this.setState({ parish: val });
	}

	onDioChange(val) {
		//this.state.diocese = e;
		this.state.diocese = val;
		this.setState({ diocese: val });
		this.loadParish();
	}

	onDeptChange(val) {
		//this.state.type = e;
		this.state.type = val;
		this.setState({ type: val });
		this.getdesignation();
	}

	componentDidMount() {
		this.loadDiocese();
	}

	loadParish() {

		this.setState({ parishList: [], dioceseLoading: true });
		var token = Sanitize.getStoreageItem('accessToken');
		Reqst.axGet('api/v1/getparishbydio/' + this.state.diocese, token).then(res => {

			let result = res['data'];

			if (result) {
				if (result.status == '1') {
					this.setState({ parishList: result.data, dioceseLoading: false });
				}
				else if (result.status == '2') {
					alert(result.message);
					this.setState({ dioceseLoading: false });
					this.setState({ expired: true });
				}
				else {
					Notification({ message: result.message, type: 'error' });
					this.setState({ dioceseLoading: false });
				}
			}
			else {

				Notification({ message: 'Something went wrong Try again...', type: 'error' });
				this.setState({ dioceseLoading: false });
			}
		});
	}

	loadDiocese() {

		this.setState({ dioceseList: [], dioceseLoading: true });
		var token = Sanitize.getStoreageItem('accessToken');
		Reqst.axGet('api/v1/getdioceseslist', token).then(res => {

			let result = res['data'];

			if (result) {
				if (result.status == '1') {
					this.setState({ dioceseList: result.data, dioceseLoading: false });
				}
				else if (result.status == '2') {
					alert(result.message);
					this.setState({ dioceseLoading: false });
					this.setState({ expired: true });
				}
				else {
					Notification({ message: result.message, type: 'error' });
					this.setState({ dioceseLoading: false });
				}
			}
			else {

				Notification({ message: 'Something went wrong Try again...', type: 'error' });
				this.setState({ dioceseLoading: false });
			}
		});
	}

	getdesignation() {

		this.setState({ designationList: [], dioceseLoading: true });
		var token = Sanitize.getStoreageItem('accessToken');
		Reqst.axGet('api/v1/getdesignationbytype/' + this.state.type, token).then(res => {

			let result = res['data'];

			if (result) {
				if (result.status == '1') {
					this.setState({ designationList: result.data, dioceseLoading: false });
				}
				else if (result.status == '2') {
					alert(result.message);
					this.setState({ dioceseLoading: false });
					this.setState({ expired: true });
				}
				else {
					Notification({ message: result.message, type: 'error' });
					this.setState({ dioceseLoading: false });
				}
			}
			else {

				Notification({ message: 'Something went wrong Try again...', type: 'error' });
				this.setState({ dioceseLoading: false });
			}
		});
	}

	handleInputChange(e) {
		this.setState({ [e.target.name]: e.target.value });
	}

	onClear() {
		document.getElementById("frmMinistry").reset();
	}

	handleSubmit(e) {
		try {

			e.preventDefault();
			if (this.validator.allValid()) {
				this.setState({ saveText: 'Saving..' });
				var token = Sanitize.getStoreageItem('accessToken');
				Reqst.axPost('api/v1/addministry', $("#frmMinistry").serialize(), token).then(res => {
					if (res['data'].status == '1') {
						Notification({ message: res['data'].message, type: 'success' });
						document.getElementById("frmMinistry").reset();
						this.setState({ saveText: 'Save', parish: '', type: '', designation: '', diocese: '', designationList: [], dioceseList: [] });
					}
					else if (res['data'].status == '2') {
						alert(res['data'].message);
						this.setState({ saveText: 'Save' });
						this.setState({ expired: true });
					}
					else if (res['data'].status == '3') {
						Notification({ message: res['data'].message, type: 'success' });
						this.setState({ saveText: 'Save' });
					}
					else {
						Notification({ message: res['data'].message, type: 'error' });
						this.setState({ saveText: 'Save' });
					}
				});
			}
			else {
				this.validator.showMessages();
				// rerender to show messages for the first time
				this.forceUpdate();
			}
		}
		catch (e) {
			console.log(e);
		}
	}

	render() {


		if (this.state.expired) {
			return (<Redirect to="/login" />);
		}

		const types = [
			{
				value: '1',
				label: 'CDPI'
			},
			{
				value: '2',
				label: 'Diocese'
			},
			{
				value: '3',
				label: 'Parish'
			},
			{
				value: '4',
				label: 'Commission'
			},
			{
				value: '5',
				label: 'Center'
			},
			{
				value: '6',
				label: 'Other'
			}
		];

		return (
			<div>

				<Helmet>
					<title>{appName} | Configuration</title>
				</Helmet>


				<div className="topbar-header">
					<div className="topbar-header__content">

						<AppTitle
							menuLevel1="Configuration"
							menuLevel2="Priest"
							menuLevel3="Add Ministry"
							menuLevel2link="#/configuration/priest"
							isbreadcumb={true}
						/>


						<div className="topbar_buttons">

						</div>
					</div>
				</div>

				<div className="main_content">
					<section>
						<div className="" >

							<div className="card" >
								<h4 className="card-header bg-info text-white">Add Ministry Details</h4>
								<div className="card-body" >
									<form action="" method="post" encType="multipart/form-data" id="frmMinistry" onSubmit={this.handleSubmit} >
										<input type="hidden" name="i__id" value={Sanitize.getStoreageItem('userId')} />
										<input type="hidden" name="priestid" value={this.props.match.params.id} />
										<input type="hidden" name="type" value={this.state.type} />
										<input type="hidden" name="diocese" value={this.state.diocese} />
										<input type="hidden" name="designation" value={this.state.designation} />
										<input type="hidden" name="parish" value={this.state.parish} />

										<div className="row" style={{ marginBottom: 10 }}>

											<div className="col-md-6" >
												<label>From Date<span className="text-danger">*</span></label>
												<input type="date" name="fromdate" className="form-control" required />
											</div>
											<div className="col-md-6" >
												<label>To Date<span className="text-danger">*</span></label>
												<input type="date" name="todate" className="form-control" />
											</div>
										</div>
										<div className="row" style={{ marginBottom: 10 }}>
											<div className="col-md-6" >
												<label>Department<span className="text-danger">*</span></label>
												<Select name="type" required onChange={this.onDeptChange} style={{ width: '100%' }}>
													{
														types.map(el => {
															return <Select.Option key={el.value} label={el.label} value={el.value} />
														})
													}
												</Select>
											</div>
											<div className="col-md-6" >
												<label>Designation<span className="text-danger">*</span></label>
												<Select name="designation" onChange={this.onDesiChange.bind(this)} required style={{ width: '100%' }}>
													{
														this.state.designationList.map(el => {
															return <Select.Option key={el.id} label={el.designation} value={el.id} />
														})
													}
												</Select>
											</div>
										</div>
										<div className="row" style={{ marginBottom: 10 }}  >
											<div className="col-md-6">
												<label>Diocese<span className="text-danger">*</span></label>
												<Select name="diocese" required style={{ width: '100%' }} onChange={this.onDioChange.bind(this)}>
													{
														this.state.dioceseList.map(el => {
															return <Select.Option key={el.id} label={el.name} value={el.id} />
														})
													}
												</Select>
											</div>
											{this.state.type == 3 ?
												<div className="col-md-6">
													<label>Parish<span className="text-danger">*</span></label>
													<Select name="parish" onChange={this.onParishChange.bind(this)} required style={{ width: '100%' }}>
														{
															this.state.parishList.map(el => {
																return <Select.Option key={el.id} label={el.name} value={el.id} />
															})
														}
													</Select>
												</div> : ''}
											<div className="col-md-6">
												<label>Remark</label>
												<textarea name="remark" className="form-control" ></textarea>
											</div>
										</div>
										<div className="row" style={{ marginBottom: 10 }}  >
											<div className="col-md-12" >
												<div className="text-right" >

													<button type="button" className="btn btn-danger btn-sm" >
														<i className="fa fa-remove"></i> Cancel
													</button>
													&nbsp;
													<button type="submit" name="i__save" className="btn btn-success btn-sm" disabled={this.state.saveText != 'Save'} >
														<i className="fa fa-check"></i> {this.state.saveText}
													</button>

												</div>
											</div>
										</div>

									</form>
								</div>
							</div>
						</div>
					</section>
				</div>
			</div >
		);
	}

}
export default AddMinistry;