import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {appName,apiUrl,copyRight,imagePath} from '../../utils/Variables';
import * as Reqst from '../../utils/Reqst';
import Sanitize from '../../utils/Sanitize';
import Notifications, {notify} from 'react-notify-toast';
import {Helmet} from 'react-helmet';
import {Row,Col,Card} from 'react-bootstrap';
import swal from 'sweetalert';
import Cookies from 'universal-cookie';

import LOGO from '../../assets/img/church.png';

class Header extends Component{
	constructor(props){
		super(props);
		this.state = {
			loginText: 'Login',
			isRedirect: false,
			showLoader: false,
		}
		this.handleSideBarToggle = this.handleSideBarToggle.bind(this);	
		this.handleLogOut = this.handleLogOut.bind(this);
	}
	
	componentDidMount(){
		
		if($(window).width() > 768){
			
		  this.handleSideBarToggleBySession();
		  
		}
		else{
		
		   $("body").removeClass('sidenav-toggled');
		   
		}
		
	}
	
	handleSideBarToggleBySession(){
		
		var sidebar = Sanitize.getStoreageItem('sidebar_active') ? Sanitize.getStoreageItem('sidebar_active') : 'no';
		
		if(sidebar == 'yes')
		{
			$("body").removeClass('sidenav-toggled');
			$(".topbar-header__content").addClass('topbar-header__content_toggle');
			$(".project-title").hide();
			//Sanitize.setStorage('sidebar_active', 'yes');
		}
		else
		{
			$("body").addClass('sidenav-toggled');
			$(".topbar-header__content").removeClass('topbar-header__content_toggle');
			$(".project-title").show();
			//Sanitize.setStorage('sidebar_active', 'no');
		}
	}
	
	handleSideBarToggle(){
		
		if($("body").hasClass('sidenav-toggled'))
		{
			$("body").removeClass('sidenav-toggled');
			$(".topbar-header__content").addClass('topbar-header__content_toggle');
			$(".project-title").hide();
			Sanitize.setStorage('sidebar_active', 'yes');
			
		}
		else
		{
			$("body").addClass('sidenav-toggled');
			$(".topbar-header__content").removeClass('topbar-header__content_toggle');
			$(".project-title").show();
			Sanitize.setStorage('sidebar_active', 'no');
		}
	}
	
	setLoginCookie(){
		const cookies = new Cookies();
		if(cookies.get('dy_user_login')!='yes')
		{
			let d = new Date();
			cookies.remove('dy_user_login');
			d.setTime(d.getTime() + (10*60*1000));
			cookies.set('dy_user_login', 'yes', {path: "/", expires: d});
			//cookies.set('j_user_login', 'yes');
		}
	}
	
	handleLogOut(e){
		swal({
		title:'Confirm',
		text: 'Do you want to Logout?',
		buttons:['No','Yes']}).then((willYes)=>{
			if(willYes){
			
				Sanitize.setStorage('accessToken', '');
				Sanitize.setStorage('userData', '');
				Sanitize.setStorage('userId', '');
				Sanitize.setStorage('userRole', '');
				this.setState({isRedirect:true});
			}
		});	
	}
	
	render(){
		
		$('html, body').animate({scrollTop:0}, 'slow');
		
		if(!Sanitize.getStoreageItem('userData') && !Sanitize.getStoreageItem('accessToken'))
		{
			return <Redirect to="/login" />;
		}
		
		var logo = LOGO;
		
		var sitename = 'CDPI';
		
		/* if(Sanitize.getStoreageItem('userData'))
		{
		  var cmpData = JSON.parse(Sanitize.getStoreageItem('userData'));
		 
		  sitename = cmpData.name;
		
		  var logo = cmpData.logo!='' && cmpData.logo!=null ? imagePath.uploadPath+cmpData.logo : LOGO;
		} */
		
		return(
		<div>
			<Notifications />
			
			<header className="app-header">
				<a className="app-header__logo" href="javascript:;">
					<img src={LOGO} style={{ width: '50px', marginTop: '-18px',maxHeight:'50px' }} />
				</a>
				
				<a className="app-sidebar__toggle" href="javascript:;" data-toggle="sidebar" aria-label="Hide Sidebar" onClick={this.handleSideBarToggle} ></a>
				<h3 className="project-title" >{sitename}</h3>
				 <ul className="app-nav">
					
					<li className="dropdown"><a className="app-nav__item" href="javascript:;" data-toggle="dropdown" aria-label="Open Profile Menu"><i className="fa fa-user fa-lg"></i></a>
					  <ul className="dropdown-menu settings-menu dropdown-menu-right">
						<li><a className="dropdown-item" href="#/settings"><i className="fa fa-cog fa-lg"></i> Settings</a></li>
						<li>
							<a href="javascript:;" className="dropdown-item" onClick={this.handleLogOut} >
								<i className="fa fa-sign-out fa-lg"></i> Logout
							</a>
						</li>
					  </ul>
					</li>
					
				</ul>
			</header>
			</div>
		);
	}
	
}
export default Header;