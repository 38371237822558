import React, { Component } from 'react';
import { BrowserRouter, Route, Link, HashRouter, IndexRoute } from 'react-router-dom';
import ProgressBar from 'react-progress-bar-plus';
import 'react-progress-bar-plus/lib/progress-bar.css';

import Header from '../layouts/Web/Header';
import Footer from '../layouts/Web/Footer';
import Sidebar from '../layouts/Web/Sidebar';

import Login from '../views/Login';
import Home from '../views/Home';

import Settings from '../views/Settings';
import AddDiocese from '../views/Diocese';
import Dioceses from '../views/Diocese/DioceseList';
import AddParish from '../views/Parish/AddParish';
import Parishes from '../views/Parish/ParishList';
import AddCommission from '../views/Commission/AddCommission';
import Churches from '../views/Church/ChurchList';
import AddChurch from '../views/Church/AddChurch';
import EditChurch from '../views/Church/EditChurch';
import AddCenter from '../views/Centers/AddCenter';
import Priest from '../views/Priest/PriestList';
import AddPriest from '../views/Priest';
import EditPriest from '../views/Priest/edit';
import PriestReports from '../views/Priest/Reports';
import Designation from '../views/Designation';
import EditMinistry from '../views/Priest/EditMinistry';
import CommissionList from '../views/Commission/CommissionList';
import CentersList from '../views/Centers/CentersList';
import AddUser from '../views/Users/AddUser';
import Users from '../views/Users/UserList';
import EditUser from '../views/Users/EditUser';
import ChurchReports from '../views/Church/Reports';
import Announcements from '../views/Announcements/Announcementlist';
import AddAnnouncement from '../views/Announcements/AddAnnouncement';
import Events from '../views/Events/Eventlist';
import AddEvent from '../views/Events/AddEvent';
import Pages from '../views/Pages/PageList';
import AddPage from '../views/Pages/AddPage';
import AddMinistry from '../views/Priest/AddMinistry';
import AddEducation from '../views/Priest/AddEducation';
import Version from '../views/Version';




const AppRoute = ({ component: Component, layout: Layout, ...rest }) => (
  <Route {...rest} render={props => (
    <Layout>
      <Component {...props} />
    </Layout>
  )} />
)


const DefaultLayout = ({component: Component, ...rest}) => {
  return (
    <Route {...rest} render={matchProps => (
      <div className="DefaultLayout">
	  <ProgressBar percent={100} />
        <Header />
		<Sidebar />
			<main className="app-content">
				<Component {...matchProps} />
			</main>
		<Footer />
      </div>
    )} />
  )
};

export default () => (
	
<HashRouter>
	<div>
	
		
		{/*<Route path="/" exact component={Test} />*/}
			{/*<Route path="/" exact component={Home} />*/}
			<Route exact path="/login" component={Login} />
			<DefaultLayout exact path="/" component={Home} />
			<DefaultLayout path="/settings" component={Settings} />
			<DefaultLayout path="/mobileversion" component={Version} />
			<DefaultLayout path="/configuration/adddiocese" component={AddDiocese} />
			<DefaultLayout path="/configuration/dioceses" component={Dioceses} />
			<DefaultLayout path="/configuration/parishes" component={Parishes} />
			<DefaultLayout path="/configuration/commissions" component={CommissionList} />
			<DefaultLayout path="/configuration/addparish" component={AddParish} />
			<DefaultLayout path="/configuration/addcommission" component={AddCommission} />
			<DefaultLayout path="/configuration/centers" component={CentersList} />
			<DefaultLayout path="/configuration/addcenter" component={AddCenter} />
			<DefaultLayout path="/configuration/churches" component={Churches} />
            <DefaultLayout path="/configuration/addchurch" component={AddChurch} />
            <DefaultLayout path="/configuration/editchurch/:id" component={EditChurch} />
			<DefaultLayout path="/configuration/addpriest" component={AddPriest} />
            <DefaultLayout path="/configuration/priest" component={Priest} />
			<DefaultLayout path="/configuration/priests/reports" component={PriestReports} />
			<DefaultLayout path="/configuration/church/reports" component={ChurchReports} />
            <DefaultLayout path="/configuration/editpriest/:id" component={EditPriest} />
			<DefaultLayout path="/configuration/designation" component={Designation} />
			<DefaultLayout path="/configuration/editministry/:id" component={EditMinistry} />
			<DefaultLayout path="/user/adduser" component={AddUser} />
			<DefaultLayout path="/user/updateuser/:id" component={EditUser} />
			<DefaultLayout path="/users" component={Users} />
			<DefaultLayout path="/announcements" component={Announcements} />
			<DefaultLayout path="/addannouncement" component={AddAnnouncement} />
			<DefaultLayout path="/configuration/addministry/:id" component={AddMinistry} />
			<DefaultLayout path="/configuration/addeducation/:id" component={AddEducation} />
			<DefaultLayout path="/events" component={Events} />
			<DefaultLayout path="/addevent" component={AddEvent} />
			<DefaultLayout path="/pages" component={Pages} />
			<DefaultLayout path="/addpage" component={AddPage} />
		
			</div>
</HashRouter>

);