import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import $ from 'jquery';
import {appName,copyRight,imagePath} from '../../utils/Variables';
import * as Reqst from '../../utils/Reqst';
import Sanitize from '../../utils/Sanitize';
import {notify} from 'react-notify-toast';
import {Helmet} from 'react-helmet';
import swal from 'sweetalert';
import Cookies from 'universal-cookie';
import ReactFileReader from 'react-file-reader';
import {Button,Tooltip,Loading,Notification,Select,DatePicker,Pagination,Radio,Tag } from 'element-react';
import NOIMAGE from '../../assets/img/noimage.jpg';
import AppTitle from '../../containers/AppTitle';
import FullPageLoader from '../../containers/FullPageLoader';
import SimpleReactValidator from 'simple-react-validator';
import {Row,Col,Table,Modal} from 'react-bootstrap';
import {CKEditor} from 'ckeditor4-react';


class PageList extends Component{
	constructor(props){
		super(props);
		this.state = {
			isRedirect: false,
			dataLoading : false,
			showFilterModal : false,
			dataLoading1 : true,
			expired : false,
			totalcount : 0,
			todosPerPage : 50,
			searchcolumn : '',
			offset : 0,
			addLink : false,
			states : [],
			countries : [],
			updateText : 'Update',
			activePage: 1,
			optionValue : '',
			searchcode : '',
			dataList : [],
			editData : [],
			showEditModal : false,
			tags : [],
			savedtags : [],
		}
		this.validator = new SimpleReactValidator();
		this.onPageChange = this.onPageChange.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
		this.setcolumn = this.setcolumn.bind(this);
		this.onPageSizeChange = this.onPageSizeChange.bind(this);
		this.handleInputBlur = this.handleInputBlur.bind(this);
		this.onRadioChange = this.onRadioChange.bind(this);
		this.onSearchChange = this.onSearchChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleEditorChange = this.handleEditorChange.bind(this);
		
	}
	
	handleInputBlur(e){
		if (!this.validator.allValid()) {
			this.validator.showMessages();			
			this.forceUpdate();
		}
	}
	
	getData(field) {
		var l = this.state.editData;
		return l[field];
	}
	
	setData(field, e) {
		var l = this.state.editData;
		l[field] = e.target.value;
		this.setState({ editData: l });
	}
	
	setDataSelect(field, e) {
		var l = this.state.editData;
		l[field] = e;
		this.setState({ editData: l });
		
		if(field=='country'){
		
			this.loadStates();
		}
	}
	
	handleEditorChange(v){
		var newContent = v.editor.getData();
		this.setState({editorContent:newContent});
		
		var l = this.state.editData;
		l['content'] = newContent;
		this.setState({ editData: l });
	}
	
	setDataDate(value,field) {
		var l = this.state.editData;
		l[field] = value;
		this.setState({ editData: l });

	}
	
	getconditionname(cond){
		
		var res = '';
		
		if(cond == '1'){
			
			res = 'Contains';
			
		}
		else if(cond == '2'){
			
			res = 'Not Contains';
		}
		else if(cond == '3'){
			
			res = 'Is Not set';
		}
		
		return res;
		
	}
	
	getcolumnname(cond){
		
		var res = '';
		
		if(cond == 'name'){
			
			res = 'Name';
			
		}
		return res;
		
		
	}
	
	
	onSearchChange() {

		var orgdata = [];

		this.setState({ savedtags: [] });

		var tags = this.state.tags;

		var column = this.state.searchcolumn;
		
		var value = $("#searchcode").val();
		
		var cond = this.state.optionValue;
		
		var displayname = this.getcolumnname(column)+' '+this.getconditionname(cond)+' '+value;


			if (cond == '3') {
				displayname = this.getcolumnname(column)+' '+this.getconditionname(cond);
				cond = ' = ';
				value = '';
			}
			else if (cond == '2') {

				cond = ' != ';
			}
			else if (cond == '1') {

				cond = ' LIKE ';
				value = '%'+value+'%';
			}
			

			var len = tags.length + 1;
			
			//value = ''{'+value+'}';
			
			var preisstrinval = "'";
			var postisstrinval = "'";

		
			var query = column+''+cond+''+preisstrinval+value+postisstrinval;
			
			this.state.tags.push({ "key": len, "name": displayname, "column": column, "value": value, "query": query });

			orgdata = this.state.tags;

			console.log(orgdata);

			this.setState({ tags: orgdata });

			this.setState({ searchcolumn: '', searchfor: '', searchcode: '',});
			$("#searchcode").val('');

			this.loadDataFilter();
			this.loadDataFilterCount();
		
	}
	
	handleInputChange(e){
		this.setState({[e.target.name]: e.target.value});
	}
	
	handleSubmit(e){
		try{
		
			e.preventDefault();
			 if (this.validator.allValid()) {
				this.setState({updateText: 'Updating..'});
				var token = Sanitize.getStoreageItem('accessToken');
				Reqst.axPost('api/v1/update_page',$("#frmUpdateCom").serialize(),token).then(res=>{
					if(res['data'].status=='1')
					{
						Notification({message:res['data'].message,type: 'success'});
						this.loadData();
		                this.loadDataCount();
						this.setState({updateText:'Update',showEditModal:false});
					}
					else if(res['data'].status=='2')
					{
						  alert(res['data'].message);
						  this.setState({updateText:'Save'});
						  this.setState({expired:true});
					}
					else
					{
						Notification({message:res['data'].message,type: 'error'});
						this.setState({saveText:'Save'});
					}
				});
		  }
		   else {
			this.validator.showMessages();
			// rerender to show messages for the first time
			this.forceUpdate();
		 }
		}
		catch(e){
			console.log(e);
		}
	}
	
	setcolumn(value) {
		
		this.state.searchcolumn = value;
		this.setState({ searchcolumn: value });
	}
	
	onRadioChange(value) {
		
		this.state.optionValue = value;
		this.setState({ optionValue: value });
	}
	
	handleClose(tag) {
		const { tags } = this.state;

		tags.splice(tags.map(el => el.key).indexOf(tag.key), 1);

		this.setState({ tag });
		this.setState({ searchcolumn: '', optionValue: '', searchcode: '' });
		$("#searchcode").val('');
		this.loadDataFilter();
		this.loadDataFilterCount();
	}
	
	onPageSizeChange(rpp) {

		var pc = parseInt(this.state.activePage);

		var offset = parseInt(rpp) * parseInt(pc);

		this.state.offset = offset;
		this.setState({ offset: offset });
		this.state.todosPerPage = rpp;
		this.setState({ todosPerPage: rpp });
		this.loadData();

	}
	
	setDataDate(value, field) {
        var l = this.state.editData;
        l[field] = value;
        this.setState({ editData: l });

    }
	
	onPageChange(page) {

		var rpp = this.state.todosPerPage;

		var pc = parseInt(page);

		var offset = parseInt(rpp) * parseInt(pc);

		this.state.activePage = page;
		this.setState({ activePage: page });
		this.state.offset = offset;
		this.setState({ offset: offset });
		this.loadData();

	}
	
	handleEdit(id, e) {
		var list = this.state.dataList;
		var index = list.findIndex(function (obj) { return obj.id == id });
		var l = list[index];
		this.setState({ editData: l, showEditModal: true });
	}
	
	handleRemove(id,name) {
		
		swal({
		title:'Confirm',
		text: 'Do you want to Remove '+name+' Event ?',
		buttons:['No','Yes']}).then((willYes)=>{
			if(willYes){
			   
			   this.removeCommission(id);
		
			}
		});	
		
	}
	
	removeCommission(id){
		
		var token = Sanitize.getStoreageItem('accessToken');
		var uid = Sanitize.getStoreageItem('userId');
		Reqst.axGet('api/v1/remove_event/'+id+'/'+uid,token).then(res=>{
			
				let result = res['data'];
				
				if(result){
						if(result.status=='1')
						{
							Notification({message:result.message,type: 'success'});
							this.loadData();
		                    this.loadDataCount();
						}
						else if(result.status=='2')
						{
							alert(result.message);
							this.setState({expired:true});
						}
						else
						{
							Notification({message:result.message,type: 'error'});
						}
				 }
				 else{
					 
							Notification({message:'Something went wrong Try again...',type: 'error'});
				  }
		});
		
		
	}
	
	loadCountries(){
		
		this.setState({countries : [],countryLoading:true});
		var token = Sanitize.getStoreageItem('accessToken');
		Reqst.axGet('api/v1/getcountries',token).then(res=>{
			
				let result = res['data'];
				
				if(result){
						if(result.status=='1')
						{
							this.setState({countries: result.data, countryLoading:false});
							this.loadStates();
						}
						else if(result.status=='2')
						{
							alert(result.message);
							this.setState({countryLoading:false});
							this.setState({expired:true});
						}
						else
						{
							Notification({message:result.message,type: 'error'});
							this.setState({countryLoading:false});
						}
				 }
				 else{
					 
							Notification({message:'Something went wrong Try again...',type: 'error'});
							this.setState({countryLoading:false});
				  }
			});
	}
	
	loadStates(){
		
		this.setState({states : [],stateLoading:true});
		var country = this.getData('country');
		var token = Sanitize.getStoreageItem('accessToken');
		Reqst.axGet('api/v1/getstates/'+country,token).then(res=>{
			
				let result = res['data'];
				
				if(result){
						if(result.status=='1')
						{
							this.setState({states: result.data,stateLoading:false});
						}
						else if(result.status=='2')
						{
							alert(result.message);
							this.setState({stateLoading:false});
							this.setState({expired:true});
						}
						else
						{
							Notification({message:result.message,type: 'error'});
							this.setState({stateLoading:false});
						}
				 }
				 else{
					 
							Notification({message:'Something went wrong Try again...',type: 'error'});
							this.setState({stateLoading:false});
				  }
			});
	}
	
	componentDidMount(){
		
		this.loadData();
		this.loadDataCount();
	}
	
	getdateoforginalformat(date) {

		if (date != '' && date != null) {
			var date1 = new Date(date);
			var year = date1.getFullYear();
			var month = (date1.getMonth() + 1);
			var day = date1.getDate()
			var hours = date1.getHours();
			var minutes = date1.getMinutes();
			var seconds = date1.getSeconds();
			month = month < 10 ? '0' + month : month;
			day = day < 10 ? '0' + day : day;
			var strTime = day + '-' + month + '-' + year;
			return strTime;
		}
		else {

			return '- - - -';
		}

	}
	
	checkIfArrayIsUnique() {
		var tags = this.state.tags;

		for (var i = 0; i < tags.length; i++) {
			for (var j = 0; j < tags.length; j++) {
				if (i != j) {
					if (tags[i]['column'] == tags[j]['column']) {
						return true;
						break;// means there are duplicate values
					}
				}
			}
		}
		return false; // means there are no duplicate values.
	}
	
	getdateoforginal(date) {

		if (date != '' && date != null) {
			var date1 = new Date(date);
			var year = date1.getFullYear();
			var month = (date1.getMonth() + 1);
			var day = date1.getDate()
			var hours = date1.getHours();
			var minutes = date1.getMinutes();
			var seconds = date1.getSeconds();
			month = month < 10 ? '0' + month : month;
			day = day < 10 ? '0' + day : day;
			var strTime = year + '-' + month + '-' + day;
			return strTime;
		}
		else {

			return '- - - -';
		}

	}
	
	loadDataFilter(){
		
		this.setState({dataList : [],dataLoading:true});
		var token = Sanitize.getStoreageItem('accessToken');
		
		var total = this.state.totalcount;

		var limit = this.state.todosPerPage;
		
		var offset = this.state.offset;
	
		
		if(offset!=0){
		
		  limit = offset - limit;
		
		}
		else{
			
			offset = 50;
			limit = 0;
			
		}
		
		var tags = this.state.tags;
		var qdata = '';
		var cond = ' AND ';
		if (this.checkIfArrayIsUnique()) {

			cond = ' OR ';
		}
		if (tags.length > 0) {

			for (var i = 0; i < tags.length; i++) {
				
				if(i==0){

				   qdata += tags[i]['query']
				 
				}
				else{
					
					qdata += cond + tags[i]['query'] ;
				}

			}

		}
		/* var iscombine = '  ';

		if (this.checkIfArrayIsUnique()) {

			iscombine = ' OR ';
		} */
		
		qdata = qdata.replace(/,*$/, "");
		//qdata = iscombine + qdata;
		if (qdata == '') {
			qdata = 'no'
		}
		else{
		  qdata = '('+qdata+')';
		}
	
		var data = {'limit':limit,'offset':offset,'query':qdata};
		
		Reqst.axPost('api/v1/geteventfilterdata',data,token).then(res=>{
			
				let result = res['data'];
				
				if(result){
						if(result.status=='1')
						{
							this.setState({dataList: result.data, dataLoading:false});
						}
						else if(result.status=='2')
						{
							alert(result.message);
							this.setState({dataLoading:false});
							this.setState({expired:true});
						}
						else
						{
							Notification({message:result.message,type: 'error'});
							this.setState({dataLoading:false});
						}
				 }
				 else{
					 
							Notification({message:'Something went wrong Try again...',type: 'error'});
							this.setState({dataLoading:false});
				  }
			});
	}
	
	loadDataFilterCount(){
		
		this.setState({totalcount : 0,dataLoading:true});
		var token = Sanitize.getStoreageItem('accessToken');
		
		
		var tags = this.state.tags;
		var qdata = '';
		var cond = ' AND ';
		if (this.checkIfArrayIsUnique()) {

			cond = ' OR ';
		}
		if (tags.length > 0) {

			for (var i = 0; i < tags.length; i++) {
				
				if(i==0){

				   qdata += tags[i]['query']
				 
				}
				else{
					
					qdata += cond + tags[i]['query'] ;
				}

			}

		}
		/* var iscombine = '  ';

		if (this.checkIfArrayIsUnique()) {

			iscombine = ' OR ';
		} */
		
		qdata = qdata.replace(/,*$/, "");
		//qdata = iscombine + qdata;
		if (qdata == '') {
			qdata = 'no'
		}
		else{
		  qdata = '('+qdata+')';
		}
	
		var data = {'query':qdata};
		
		Reqst.axPost('api/v1/geteventsfilterdatacount',data,token).then(res=>{
			
				let result = res['data'];
				
				if(result){
						if(result.status=='1')
						{
							this.setState({totalcount: result.count, dataLoading:false});
						}
						else if(result.status=='2')
						{
							alert(result.message);
							this.setState({dataLoading:false});
							this.setState({expired:true});
						}
						else
						{
							Notification({message:result.message,type: 'error'});
							this.setState({dataLoading:false});
						}
				 }
				 else{
					 
							Notification({message:'Something went wrong Try again...',type: 'error'});
							this.setState({dataLoading:false});
				  }
			});
	}
	
	loadData(){
		
		this.setState({dataList : [],dataLoading:true});
		var token = Sanitize.getStoreageItem('accessToken');
		
		var total = this.state.totalcount;

		var limit = this.state.todosPerPage;
		
		var offset = this.state.offset;
	
		
		if(offset!=0){
		
		  limit = offset - limit;
		
		}
		else{
			
			offset = 50;
			limit = 0;
			
		}
	
	
		
		var data = {'limit':limit,'offset':offset};
		
		Reqst.axPost('api/v1/getpages',data,token).then(res=>{
			
				let result = res['data'];
				
				if(result){
						if(result.status=='1')
						{
							this.setState({dataList: result.data, dataLoading:false});
						}
						else if(result.status=='2')
						{
							alert(result.message);
							this.setState({dataLoading:false});
							this.setState({expired:true});
						}
						else
						{
							Notification({message:result.message,type: 'error'});
							this.setState({dataLoading:false});
						}
				 }
				 else{
					 
							Notification({message:'Something went wrong Try again...',type: 'error'});
							this.setState({dataLoading:false});
				  }
			});
	}
	
	loadDataCount(){
		
		this.setState({totalcount : 0,dataLoading:true});
		var token = Sanitize.getStoreageItem('accessToken');
		
		Reqst.axGet('api/v1/getpagescount',token).then(res=>{
			
				let result = res['data'];
				
				if(result){
						if(result.status=='1')
						{
							this.setState({totalcount: result.count, dataLoading:false});
						}
						else if(result.status=='2')
						{
							alert(result.message);
							this.setState({dataLoading:false});
							this.setState({expired:true});
						}
						else
						{
							Notification({message:result.message,type: 'error'});
							this.setState({dataLoading:false});
						}
				 }
				 else{
					 
							Notification({message:'Something went wrong Try again...',type: 'error'});
							this.setState({dataLoading:false});
				  }
			});
	}
	
	
	
	render(){
		
		if (this.state.addLink) {

			return (<Redirect to={'/addpage'} />);

		}
		
		
		let sno = 1;
		
		const selcolumns = [
			{
				value: 'title',
				label: 'Title'
			}
		];
		
		const types = [
			{
				value: 'diocese',
				label: 'Diocese'
			},
			{
				value: 'archdiocese',
				label: 'Archdiocese'
			}
		];
		
		if(this.state.expired)
		{
			return (<Redirect to="/login" />);
		}
		
		
		
		return(
			<div>
				
				<Helmet>
					<title>{appName} | Pages </title>
				</Helmet>
				
				
				<div className="topbar-header">
						<div className="topbar-header__content">
						
						   <AppTitle
								menuLevel1="Pages"
								isbreadcumb={true}
							/>
						
				       
						<div className="topbar_buttons">
								<div className="desktop_pagination">
								
									<Pagination
										layout="prev,sizes,total,pager, next"
										total={this.state.totalcount}
										pageCount={3}
										currentPage={this.state.activePage}
										small={true}
										pageSizes={[25, 50, 75, 100]}
										pageSize={this.state.todosPerPage}
										onCurrentChange={this.state.tags.length > 0 ? '' : this.onPageChange}
										onSizeChange={this.state.tags.length > 0 ? '' : this.onPageSizeChange}
									 />
										
									

								</div>
								&nbsp;
								<Tooltip className="item" content="Add Page" placement="bottom" >
								   <Button type="text" onClick={() => this.setState({ addLink: true })}>
										<i className="fa fa-plus-circle" style={{fontSize : '22px'}} />
									</Button>
								</Tooltip>
								
						</div>
					 </div>
				</div>
				
				<div className="main_content">
				    <section>
					      <div className="card" >
							<h4 className="card-header bg-info text-white">Pages</h4>
							  <div className="card-body" >
							  {this.state.tags.length > 0 ?
									<div style={{ marginBottom: '10px', marginTop: '0px' }}>
										{
											this.state.tags.map(tag => {
												return (
													<Tag
														key={tag.key}
														closable={true}
														type="primary"
														closeTransition={false}
														onClose={this.handleClose.bind(this, tag)}>{tag.name}</Tag>
												)
											})
										}

									</div>
								: ''}
							     <Table responsive bordered striped >
										<thead>
											<tr>
												<th width="30">S.No</th>
												<th width="220">Title</th>
												
												<th width="60" align="center" style={{textAlign:'center'}}>Manage</th>
											</tr>
										</thead>
										<tbody>
										 {this.state.dataLoading ?
										  <tr height="60"><td colSpan="3"><center>
										  
											 <Loading />
										  
										     </center>
										   </td></tr>
						                :
										 this.state.dataList.length>0 ?
										
											 this.state.dataList.map((item,i)=>{
												return <tr>
													<td width="30" >{sno++}</td>
													<td>{item.title}</td>
													
													<td align="center">
													 <Button type="text" onClick={this.handleEdit.bind(this, item.id)}>
										                  <i className="fa fa-edit" style={{fontSize : '16px'}} />
									                  </Button>
													 
													</td>
													
												</tr>;
											})
										 :
										   <tr><td colSpan="3"><center>No Data Found...</center></td></tr>
										}
										</tbody>
									</Table>
									<div className="mobile_pagination">

										<Pagination
											layout="prev,pager,next"
											pageCount={this.state.totalcount}
											currentPage={this.state.activePage}
											small={true}
											total={this.state.totalcount}
											currentPage={this.state.activePage}
											pageSizes={[25, 50, 75, 100]}
											pageSize={this.state.todosPerPage}
											onCurrentChange={this.state.tags.length > 0 ? '' : this.onPageChange}
										/>

                                     </div>
								  </div>
								  
								  <Modal size="md" show={this.state.showFilterModal} onHide={() => { this.setState({ showFilterModal: false }) }} className="right" >
									<Modal.Header closeButton >
										<Modal.Title>Filters</Modal.Title>
									</Modal.Header>
									<Modal.Body>
									         <label>Select Field<span className="text-danger"></span></label>
										      <Select onChange={this.setcolumn} value={this.state.searchcolumn} style={{width:'100%'}}>
													{
														selcolumns.map(el => {
															return <Select.Option key={el.value} label={el.label} value={el.value} />
														})
													}
												</Select>
												<br/><br/>
												<Radio value="1" checked={this.state.optionValue === 1} onChange={this.onRadioChange.bind(this)}>Contains</Radio>
                                                <Radio value="2" checked={this.state.optionValue === 2} onChange={this.onRadioChange.bind(this)}>Not Contains</Radio>
												 <Radio value="3" checked={this.state.optionValue === 3} onChange={this.onRadioChange.bind(this)}>Not Set</Radio>
												 <br/>
												 {this.state.optionValue!=3 && this.state.optionValue!='' ?
												    <input type="text" name="searchcode" id="searchcode" className="form-control" required />
												 : 
												 ''}
												 
												 <br/> <br/>
												 
												 {this.state.optionValue != '' && this.state.searchcolumn != '' ?
			
													<div className="text-center">
														<Button block onClick={this.onSearchChange} type="primary">
															<i className="fa fa-search" />&nbsp;Search
															</Button>
													</div>
									
												: ''}
									</Modal.Body>
								</Modal>
								
								<Modal size="md" show={this.state.showEditModal} onHide={() => { this.setState({ showEditModal: false }) }} className="right" >
									<Modal.Header closeButton >
										<Modal.Title>Update Page</Modal.Title>
									</Modal.Header>
									<Modal.Body>
											<form action="" method="post" encType="multipart/form-data" id="frmUpdateCom" onSubmit={this.handleSubmit} >
											
											            <input type="hidden" name="i__id" value={Sanitize.getStoreageItem('userId')} />
														<input type="hidden" name="tid" value={this.getData('id')} />
														<input type="hidden" name="i__content" value={this.getData('content')} />
														
														
												<div className="row" style={{ marginBottom: 10 }}  >
													<div className="col-md-3" >
														<label>Title<span className="text-danger">*</span></label>
													</div>
													<div className="col-md-9" >
														<input type="text" name="c_name" id="c_name" className="form-control" required onChange={this.setData.bind(this, 'title')} value={this.getData('title')}  />
														{this.validator.message('Title', this.getData('title'), 'required')}
													</div>
												</div>
												
												
												<div className="row" style={{ marginBottom: 10 }}  >
													<div className="col-md-3" >
														<label>Content<span className="text-danger">*</span></label>
													</div>
													<div className="col-md-9" >
														               <CKEditor 
																		  data={this.getData('content')} 
																		  config={{extraPlugins :'font,colorbutton,colordialog,iframe'}}
																		  onChange = {this.handleEditorChange}
																		  
																		 />
													</div>
												</div>
												
											
												
												<div className="row" style={{ marginBottom: 10 }}  >
																<div className="col-md-12" >
																	<div className="text-right" >
																		
																		<button type="submit" name="i__save" className="btn btn-success btn-sm" disabled={this.state.updateText!='Update'} >
																			<i className="fa fa-check"></i> {this.state.updateText}
																		</button>
																		
																	</div>
																</div>
												</div>
												
											</form>
									</Modal.Body>
								</Modal>
			                 </div>
						 </section>
				</div>
			</div>
		);
	}
	
}
export default PageList;