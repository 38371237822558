import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {appName,copyRight} from '../../utils/Variables';
import * as Reqst from '../../utils/Reqst';
import Sanitize from '../../utils/Sanitize';
import Notifications, {notify} from 'react-notify-toast';
import {Helmet} from 'react-helmet';
import {Row,Col,Card} from 'react-bootstrap';
import swal from 'sweetalert';
import Cookies from 'universal-cookie';
import {Loading, Notification} from 'element-react';
import SortableTree from 'react-sortable-tree';
import 'react-sortable-tree/style.css'; 
import Chart from 'react-apexcharts';
		
import {AppTitle, Button} from '../../containers';


const styles = {
	firstDiv: {
		 backgroundImage: 'linear-gradient(#117a92, #095369)',
		 padding: 15,
		 margin:0, 
		 minHeight: 300		
	},
	loginTitle: {
	    borderBottom: '1px solid #e0dcdc',
		paddingBottom: '7px',
		width: '95%',
	}
}

class Home extends Component{
	constructor(props){
		super(props);
		this.state = {
			loginText: 'Login',
			isRedirect: false,
			showLoader: false,
			expired : false,
			parishLoading : false,
			pristLoading : false,
			userData: [],
			pristList : [],
			priestnames : [],
			priestcount : [],
			parishcount : [],
			parishnames : [],
			parishList : [],
			postcount : 0,
			pagecount : 0,
			catcount : 0,
			treeData: [{ title: 'Chicken', children: [{ title: 'Egg' }, {title:'Rupees'}] }],
		}
		this.handleTreeChange = this.handleTreeChange.bind(this);
		
	}
	
	componentDidMount(){
		
		this.loadPristsData();
		this.loadParishData();
	
	}
	
	
	
	componentWillMount(){
		
		this.setState({showLoader:false});
	}
	
	
	
	loadParishData(){
		
		this.setState({parishList : [],parishnames:[],parishcount:[],parishLoading:true});
		var token = Sanitize.getStoreageItem('accessToken');
		Reqst.axGet('api/v1/getparishcountdistrictwise',token).then(res=>{
			
				let result = res['data'];
				
				if(result){
						if(result.status=='1')
						{
							this.setState({parishList: result.data, parishLoading:false});
							this.getParishCount();
						}
						else if(result.status=='2')
						{
							alert(result.message);
							this.setState({parishLoading:false});
							this.setState({expired:true});
						}
						else
						{
							Notification({message:result.message,type: 'error'});
							this.setState({parishLoading:false});
						}
				 }
				 else{
					 
							Notification({message:'Something went wrong Try again...',type: 'error'});
							this.setState({parishLoading:false});
				  }
			});
	}
	
	getParishCount() {

		var data = this.state.parishList;

		var count = []; var name = [];

		for (var i = 0; i < data.length; i++) {

			if (data[i].parcount != 0) {

				count.push(parseInt(data[i].parcount));
				name.push(data[i].name);
			}

		}

		this.setState({ parishnames: name, parishcount: count });
	}
	
	loadPristsData(){
		
		this.setState({pristList : [],priestnames:[],priestcount:[],pristLoading:true});
		var token = Sanitize.getStoreageItem('accessToken');

		if(Sanitize.getStoreageItem('userData')){
			
			var d = JSON.parse(Sanitize.getStoreageItem('userData'));
		
			var diocese = d.diocese;
		}
		
		var diocese = '';
	
		
		var data = {diocese:diocese};
		
		Reqst.axPost('api/v1/getpriestcountdiocesewise',data,token).then(res=>{
			
				let result = res['data'];
				
				if(result){
						if(result.status=='1')
						{
							this.setState({pristList: result.data, pristLoading:false});
							this.getPriestCount();
						}
						else if(result.status=='2')
						{
							alert(result.message);
							this.setState({pristLoading:false});
							this.setState({expired:true});
						}
						else
						{
							Notification({message:result.message,type: 'error'});
							this.setState({pristLoading:false});
						}
				 }
				 else{
					 
							Notification({message:'Something went wrong Try again...',type: 'error'});
							this.setState({pristLoading:false});
				  }
			});
	}
	
	getPriestCount() {

		var data = this.state.pristList;

		var count = []; var name = [];

		for (var i = 0; i < data.length; i++) {

			if (data[i].prcount != 0) {

				count.push(parseInt(data[i].prcount));
				name.push(data[i].name);
			}

		}

		this.setState({ priestnames: name, priestcount: count });
	}
	
	

	handleTreeChange(treedata){
		console.log(treedata);
		this.setState({treeData:treedata});
	}
	
	handleAddMenuClick(){
		
		var m = $("#i__menu").val();
		var d = this.state.treeData;
		d.push({title:m});
		this.setState({treeData:d});
		console.log(this.state.treeData);
		$("#i__menu").val('');
		this.forceUpdate();
	}
	
	render(){
		
		
		if(this.state.expired)
		{
			return (<Redirect to="/login" />);
		}
		
		const piechartseries = this.state.priestcount;

		const dougnoutoptins = {
			chart: {
				id: 'apexchart-example-3',
				toolbar: {
					show: true,
					tools: {
						download: true,
						selection: true,
						zoom: true,
						zoomin: true,
						zoomout: true,
						pan: true,
						customIcons: []
					},
					autoSelected: 'zoom'
				},
			},
			dataLabels: {
				enabled: true
			},
			legend: {
				position: 'left'
			},

			labels: this.state.priestnames

		};
		
		
		const piechartseries1 = this.state.parishcount;

		const dougnoutoptins1 = {
			chart: {
				id: 'apexchart-example-3',
				toolbar: {
					show: true,
					tools: {
						download: true,
						selection: true,
						zoom: true,
						zoomin: true,
						zoomout: true,
						pan: true,
						customIcons: []
					},
					autoSelected: 'zoom'
				},
			},
			dataLabels: {
				enabled: true
			},
			legend: {
				position: 'left'
			},

			labels: this.state.parishnames

		};
		
		
		
		return(
		<>
		{/*<div className="app-title">
			<div>
			  <h1><i className="fa fa-dashboard"></i> Dashboard</h1>
			  <p>A free and open source Bootstrap 4 admin template</p>
			</div>
			<ul className="app-breadcrumb breadcrumb">
			  <li className="breadcrumb-item"><i className="fa fa-home fa-lg"></i></li>
			  <li className="breadcrumb-item"><a href="#">Dashboard</a></li>
			</ul>
		</div>*/}
		
			<div className="topbar-header">
						<div className="topbar-header__content">
						
						   <AppTitle
								menuLevel1="Dashboard"
								isbreadcumb={true}
							/>
						
				       
						<div className="topbar_buttons">
								
						 </div>
					 </div>
			</div>
				
		<div className="main_content">
		  
		    <div className="row">
			   <div className="col-md-6">
				    <div className="card" style={{height:'100%'}}>
						<h4 className="card-header bg-info text-white">Diocese wise Priests</h4>
						<div className="card-body">
						{this.state.pristLoading ?
					   
							   <div className="loader_div">
								 <Loading />
							   </div>
						 :
								    <div className="pie" >
										 <Chart options={dougnoutoptins} series={piechartseries} type="pie" id="apexchart-example-3" width="100%" />

									</div>
						}
						</div>
				  </div>
			  </div>
			  <div className="col-md-6">
				    <div className="card" style={{height:'100%'}}>
						<h4 className="card-header bg-info text-white">Districts wise Parishes </h4>
						<div className="card-body">
						{this.state.parishLoading ?
					   
							   <div className="loader_div">
								 <Loading />
							   </div>
						 :
								    <div className="pie" >
										 <Chart options={dougnoutoptins1} series={piechartseries1} type="pie" id="apexchart-example-3" width="100%" />

									</div>
						}
						</div>
				  </div>
			  </div>
	    	</div>
			
		</div>
				
		  
		 </>		  
		);
	}
	
}
export default Home;