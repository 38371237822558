import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {appName,copyRight} from '../../utils/Variables';
import * as Reqst from '../../utils/Reqst';
import Sanitize from '../../utils/Sanitize';
import Notifications, {notify} from 'react-notify-toast';
import {Helmet} from 'react-helmet';
import {Row,Col,Card} from 'react-bootstrap';
import swal from 'sweetalert';
import {Menus,DioMenus} from '../../utils/Menus';


class Sidebar extends Component{
	constructor(props){
		super(props);
		this.state = {
			loginText: 'Login',
			isRedirect: false,
			showLoader: false,
		}
		
		this.handleMenuExpand = this.handleMenuExpand.bind(this);
	}
	
	handleMenuExpand(e){
		var el = e.target;
		if($(el).closest('li').hasClass('is-expanded')){
			$(el).closest('li').removeClass('is-expanded');
		}
		else{
			$('ul.app-menu li').removeClass('is-expanded');
			$(el).closest('li').addClass('is-expanded');
		}
	}
	
	getActiveMenu(){
		let activeMenu = window.location.hash.split( '/' )[1];
		let _activeMenu = '/'+window.location.hash.split( '/' );
		_activeMenu = _activeMenu.replace(/,/g, '/').slice(2);
		return _activeMenu;	
	}

	getMenu(item){
		
		let activePath = this.getActiveMenu();
		let activeMenuItem = activePath;
		
		if(item.child && item.child.length>0)
		{
			activeMenuItem = activeMenuItem.split('/');
			activeMenuItem = '/'+activeMenuItem[1];
			
			return <li className={activeMenuItem==item.path ? "treeview is-expanded" : "treeview"} onClick={this.handleMenuExpand} >
						<a className={activeMenuItem==item.path ? "app-menu__item active is-expanded" : "app-menu__item"} href="javascript:;" data-toggle="treeview">
							<i className={"app-menu__icon "+item.icon} ></i>
							<span className="app-menu__label">{item.name}</span><i className="treeview-indicator fa fa-angle-right"></i>
						</a>
					  <ul className="treeview-menu">
					  {item.child.map((val,k)=>{
						return <li>
							<Link className={activePath==val.path ? "treeview-item active"  : "treeview-item "} to={val.path} >
								<i className="icon fa fa-circle-o"></i> {val.name}
							</Link>
						</li>						
						})
					  }
					  </ul>
					</li>;
		}
		else
		{
			return  <li>
						<Link className={activePath==item.path ? 'app-menu__item  active' : 'app-menu__item '} to={item.path} >
							<i className={"app-menu__icon "+item.icon} ></i>
							<span className="app-menu__label">{item.name}</span>
						</Link>
					</li> ;
		}
		
	}
	
	render(){
		
		
		return(
			<>
			  <div className="app-sidebar__overlay" data-toggle="sidebar"></div>
				<aside className="app-sidebar">
				{/*<div className="app-sidebar__user"><img className="app-sidebar__user-avatar" src="https://s3.amazonaws.com/uifaces/faces/twitter/jsa/48.jpg" alt="User Image" />
					<div>
					  <p className="app-sidebar__user-name">John Doe</p>
					  <p className="app-sidebar__user-designation">Frontend Developer</p>
					</div>
				</div>*/}
				  <ul className="app-menu">
				  {Sanitize.getStoreageItem('userRole') == 'admin' ?
				  
					  Menus.map((item,i)=>{
						  return this.getMenu(item);
						})
				 :
				 
				     DioMenus.map((item,i)=>{
						  return this.getMenu(item);
						})
						
				  }
				  {/*<li><Link className="app-menu__item " to="/"><i className="app-menu__icon fa fa-dashboard"></i><span className="app-menu__label">Dashboard</span></Link></li>
						 
					<li className="treeview" onClick={this.handleMenuExpand} >
						<a className="app-menu__item" href="javascript:;" data-toggle="treeview"><i className="app-menu__icon fa fa-book"></i>
							<span className="app-menu__label">Post</span><i className="treeview-indicator fa fa-angle-right"></i>
						</a>
					  <ul className="treeview-menu">
						<li>
							<Link className="treeview-item" to="/page-2"><i className="icon fa fa-circle-o"></i> Post List</Link>
						</li>						
						<li>
							<Link className="treeview-item" to="/page-2"><i className="icon fa fa-circle-o"></i> Post Category</Link>
						</li>
						<li>
							<Link className="treeview-item" to="/page-2"><i className="icon fa fa-circle-o"></i> Page List</Link>
						</li>
					  </ul>
					</li>
					
					
					<li className="treeview" onClick={this.handleMenuExpand} >
						<a className="app-menu__item" href="javascript:;" data-toggle="treeview"><i className="app-menu__icon fa fa-users"></i>
							<span className="app-menu__label">Authors & Awards</span><i className="treeview-indicator fa fa-angle-right"></i>
						</a>
					  <ul className="treeview-menu">
						<li>
							<Link className="treeview-item" to="/page-2"><i className="icon fa fa-circle-o"></i> Authors</Link>
						</li>						
						<li>
							<Link className="treeview-item" to="/page-2"><i className="icon fa fa-circle-o"></i> Awards</Link>
						</li>
						<li>
							<Link className="treeview-item" to="/page-2"><i className="icon fa fa-circle-o"></i> Certificate</Link>
						</li>
					  </ul>
					</li>
					
					<li><Link className="app-menu__item " to="/settings"><i className="app-menu__icon fa fa-gear"></i><span className="app-menu__label">Settings</span></Link></li>
				  */}
				  </ul>
			</aside>
			
			</>
		);
	}
	
}
export default Sidebar;