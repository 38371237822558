import React, { Component } from 'react';

export const Menus = [
	{
		name: 'Dashboard',
		path: '/',
		icon: 'fa fa-dashboard'
	},
	{
		name: 'Dioceses',
		path: '/configuration/dioceses',
		icon: 'fa fa-industry'
	},
	{
		name: 'Parishes',
		path: '/configuration/parishes',
		icon: 'fa fa-building-o'
	},
	{
		name: 'Commission',
		path: '/configuration/commissions',
		icon: 'fa fa-money'
	},
	{
		name: 'Center',
		path: '/configuration/centers',
		icon: 'fa fa-university'
	},
	{
         name: 'Churches',
         path: '/configuration/churches',
         icon: 'fa fa-home'
    },
	{
         name: 'Priest',
         path: '/configuration/priest',
         icon: 'fa fa-users'
    },
	{
         name: 'Designation',
         path: '/configuration/designation',
         icon: 'fa fa-briefcase'
    },
	{
         name: 'Announcements',
         path: '/announcements',
         icon: 'fa fa-bullhorn'
    },
	{
         name: 'Events',
         path: '/events',
         icon: 'fa fa-calendar-check-o'
    },
	{
         name: 'Pages',
         path: '/pages',
         icon: 'fa fa-file'
    },
	{
         name: 'Users',
         path: '/users',
         icon: 'fa fa-user'
    },
	{
		name: 'Settings',
		path: '/settings',
		icon: 'fa fa-gear'
	},
	{
		name: 'Mobile Version',
		path: '/mobileversion',
		icon: 'fa fa-mobile'
	}
];

export const DioMenus = [
	{
		name: 'Dashboard',
		path: '/',
		icon: 'fa fa-dashboard'
	},
	{
		name: 'Dioceses',
		path: '/configuration/dioceses',
		icon: 'fa fa-industry'
	},
	{
		name: 'Settings',
		path: '/settings',
		icon: 'fa fa-gear'
	}
];
	
	