import React from 'react';
import ReactDOM from 'react-dom';
import 'element-theme-default';
import './index.css';
import { i18n } from 'element-react';
import locale from 'element-react/src/locale/lang/en';
import 'font-awesome/css/font-awesome.min.css';
import App from './layouts/App';

import './serviceWorker';

i18n.use(locale);

ReactDOM.render(<App />, document.getElementById('root'));

