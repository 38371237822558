import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import $ from 'jquery';
import { appName, copyRight, apiUrl } from '../../utils/Variables';
import * as Reqst from '../../utils/Reqst';
import Sanitize from '../../utils/Sanitize';
import { notify } from 'react-notify-toast';
import { Helmet } from 'react-helmet';
import swal from 'sweetalert';
import Cookies from 'universal-cookie';
import ReactFileReader from 'react-file-reader';
import { Button, Tooltip, Loading, Notification, Select, DatePicker } from 'element-react';
import NOIMAGE from '../../assets/img/noimage.jpg';
import AppTitle from '../../containers/AppTitle';
import FullPageLoader from '../../containers/FullPageLoader';
import SimpleReactValidator from 'simple-react-validator';
import { Row, Col, Table, Modal } from 'react-bootstrap';
import EditMinistry from './EditMinistry';
import UpdateEducation from './UpdateEducation';


class Priest extends Component {
	constructor(props) {
		super(props);
		this.state = {
			updateText: 'Update',
			isRedirect: false,
			dataLoading: false,
			diocese: [],
			parishes: [],
			province: [],
			states: [],
			expired: false,
			showEditModal: false,
			dioceseLoading: false,
			parishLoading: false,
			provinceLoading: false,
			showEditModal1 : false,
			designationList: [],
			desLoading: false,
			stateLoading: false,
			ministrydata: [],
			educationdata : [],
			editData: [],
			d_type: '',
			priestdata: [],
			files: '',
			photoname: '',
			d_state: '35',
			d_country: '101',
			d_erected: new Date()
		}
		this.validator = new SimpleReactValidator();
		this.handleInputChange = this.handleInputChange.bind(this);
		this.onSelectChange = this.onSelectChange.bind(this);
		this.onDateChange = this.onDateChange.bind(this);
		this.handleInputBlur = this.handleInputBlur.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleFiles = this.handleFiles.bind(this);
		this.editPriest = this.editPriest.bind(this);
	}

	componentDidMount() {
		this.editPriest();
		this.loadDiocese();
		this.loadParish();
		this.loadProvince();
	}

	handleFiles(files) {
		this.setState({ files: files.base64, photoname: files.fileList[0]['name'] });
	}

	getdateoforginal(date) {

		if (date != '' && date != null) {
			var date1 = new Date(date);
			var year = date1.getFullYear();
			var month = (date1.getMonth() + 1);
			var day = date1.getDate()
			var hours = date1.getHours();
			var minutes = date1.getMinutes();
			var seconds = date1.getSeconds();
			month = month < 10 ? '0' + month : month;
			day = day < 10 ? '0' + day : day;
			var strTime = year + '-' + month + '-' + day;
			return strTime;
		}
		else {

			return '';
		}

	}


	loadDiocese() {

		this.setState({ diocese: [], dioceseLoading: true });
		var token = Sanitize.getStoreageItem('accessToken');
		Reqst.axGet('api/v1/getdioceseslist', token).then(res => {

			let result = res['data'];

			if (result) {
				if (result.status == '1') {
					this.setState({ diocese: result.data, dioceseLoading: false });
				}
				else if (result.status == '2') {
					alert(result.message);
					this.setState({ dioceseLoading: false });
					this.setState({ expired: true });
				}
				else {
					Notification({ message: result.message, type: 'error' });
					this.setState({ dioceseLoading: false });
				}
			}
			else {

				Notification({ message: 'Something went wrong Try again...', type: 'error' });
				this.setState({ dioceseLoading: false });
			}
		});
	}

	loadParish() {

		this.setState({ parishes: [], parishLoading: true });
		var token = Sanitize.getStoreageItem('accessToken');
		Reqst.axGet('api/v1/getparishlist', token).then(res => {

			let result = res['data'];

			if (result) {
				if (result.status == '1') {
					this.setState({ parishes: result.data, parishLoading: false });
				}
				else if (result.status == '2') {
					alert(result.message);
					this.setState({ parishLoading: false });
					this.setState({ expired: true });
				}
				else {
					Notification({ message: result.message, type: 'error' });
					this.setState({ parishLoading: false });
				}
			}
			else {

				Notification({ message: 'Something went wrong Try again...', type: 'error' });
				this.setState({ parishLoading: false });
			}
		});
	}

	loadProvince() {

		this.setState({ province: [], provinceLoading: true });
		var token = Sanitize.getStoreageItem('accessToken');
		Reqst.axGet('api/v1/getprovincelist', token).then(res => {

			let result = res['data'];

			if (result) {
				if (result.status == '1') {
					this.setState({ province: result.data, provinceLoading: false });
				}
				else if (result.status == '2') {
					alert(result.message);
					this.setState({ provinceLoading: false });
					this.setState({ expired: true });
				}
				else {
					Notification({ message: result.message, type: 'error' });
					this.setState({ provinceLoading: false });
				}
			}
			else {

				Notification({ message: 'Something went wrong Try again...', type: 'error' });
				this.setState({ provinceLoading: false });
			}
		});
	}

	handleEdit(id, e) {
		var list = this.state.ministrydata;
		var index = list.findIndex(function (obj) { return obj.id == id });
		var l = list[index];
		this.setState({ editData: l, showEditModal: true });
	}
	
	handleEdit1(id, e) {
		var list = this.state.educationdata;
		var index = list.findIndex(function (obj) { return obj.id == id });
		var l = list[index];
		this.setState({ editData: l, showEditModal1: true });
	}

	getdesignation() {

		this.setState({ designationList: [], desLoading: true });
		var token = Sanitize.getStoreageItem('accessToken');
		var editdata = this.state.editData;
		var dep = editdata['department'];
		Reqst.axGet('api/v1/getdesignationbytype/' + dep, token).then(res => {

			let result = res['data'];

			if (result) {
				if (result.status == '1') {
					this.setState({ designationList: result.data, desLoading: false });
				}
				else if (result.status == '2') {
					alert(result.message);
					this.setState({ desLoading: false });
					this.setState({ expired: true });
				}
				else {
					Notification({ message: result.message, type: 'error' });
					this.setState({ desLoading: false });
				}
			}
			else {

				Notification({ message: 'Something went wrong Try again...', type: 'error' });
				this.setState({ desLoading: false });
			}
		});
	}

	handleRemove(id) {

		swal({
			title: 'Confirm',
			text: 'Do you want to Remove this Minsistry ?',
			buttons: ['No', 'Yes']
		}).then((willYes) => {
			if (willYes) {

				this.removeMinistry(id);

			}
		});

	}
	
	handleRemove1(id) {

		swal({
			title: 'Confirm',
			text: 'Do you want to Remove this Education ?',
			buttons: ['No', 'Yes']
		}).then((willYes) => {
			if (willYes) {

				this.removeEducation(id);

			}
		});

	}

	removeMinistry(id) {

		var token = Sanitize.getStoreageItem('accessToken');
		var uid = Sanitize.getStoreageItem('userId');
		Reqst.axGet('api/v1/remove_ministry/' + id + '/' + uid, token).then(res => {

			let result = res['data'];

			if (result) {
				if (result.status == '1') {
					Notification({ message: result.message, type: 'success' });
					this.editPriest();
				}
				else if (result.status == '2') {
					alert(result.message);
					this.setState({ expired: true });
				}
				else {
					Notification({ message: result.message, type: 'error' });
				}
			}
			else {

				Notification({ message: 'Something went wrong Try again...', type: 'error' });
			}
		});


	}
	
	removeEducation(id) {

		var token = Sanitize.getStoreageItem('accessToken');
		var uid = Sanitize.getStoreageItem('userId');
		Reqst.axGet('api/v1/remove_education/' + id + '/' + uid, token).then(res => {

			let result = res['data'];

			if (result) {
				if (result.status == '1') {
					Notification({ message: result.message, type: 'success' });
					this.editPriest();
				}
				else if (result.status == '2') {
					alert(result.message);
					this.setState({ expired: true });
				}
				else {
					Notification({ message: result.message, type: 'error' });
				}
			}
			else {

				Notification({ message: 'Something went wrong Try again...', type: 'error' });
			}
		});


	}

	editPriest() {

		this.setState({ priestdata: [], ministrydata: [], provinceLoading: true, dataLoading: true,educationdata : [] });
		var token = Sanitize.getStoreageItem('accessToken');
		Reqst.axGet('api/v1/getpriestsedit/' + this.props.match.params.id, token).then(res => {

			let result = res['data'];

			if (result) {
				if (result.status == '1') {
					var res = result.data;
					this.setState({
						ministrydata: result.ministry,
						educationdata: result.education,
						dataLoading: false
					});
					this.setState({
						priestdata: result.data,
						t_id: res['id'],
						d_name: res['name'],
						d_mobile: res['mobile'],
						d_province: res['provinceid'],
						d_diocese: res['diocesid'],
						d_parish: res['parishid'],
						d_dob: res['dob'],
						d_pob: res['placeofbirth'],
						d_parishob: res['parishofbirth'],
						d_dobaptism: res['dobaptism'],
						d_placeofbap: res['placeofbaptism'],
						d_parishobap: res['parishofbaptism'],
						d_dodiocan: res['dodiocan'],
						d_placeofdiocan: res['placeofdiocan'],
						d_bishopofdiocan: res['bishopofdiocan'],
						d_doordinate: res['doordination'],
						d_placeofordinate: res['placeofordination'],
						d_bishopofordinate: res['bishopofordination'],
						d_bloodgroup: res['bloodgroup'],
						d_email: res['emailid'],
						d_whatsapp: res['whatsappno'],
						d_others: res['others'],
						d_emcare: res['emergencycare'],
						d_emno: res['emergencyno'],
						d_emcp: res['emergencycontactperson'],
						d_pubtitle: res['publication_title'],
						d_pubtype: res['publication_type'],
						d_pubyear: res['publication_year'],
						d_publink: res['publication_link'],
						d_pubtitle_two: res['publication_title_2'],
						d_pubtype_two: res['publication_type_2'],
						d_pubyear_two: res['publication_year_2'],
						d_publink_two: res['publication_link_2'],
						photo: res['userimage'],
						provinceLoading: false
					});
				}
				else if (result.status == '2') {
					alert(result.message);
					this.setState({ provinceLoading: false });
					this.setState({ expired: true });
				}
				else {
					Notification({ message: result.message, type: 'error' });
					this.setState({ provinceLoading: false });
				}
			}
			else {

				Notification({ message: 'Something went wrong Try again...', type: 'error' });
				this.setState({ provinceLoading: false });
			}
		});
	}

	handleInputChange(e) {
		this.setState({ [e.target.name]: e.target.value });
	}

	onDateChange(type, value) {
		if (type == 'dob') {
			this.setState({ d_dob: value });
		}
		else if (type == 'Dobaptism') {
			this.setState({ d_dobaptism: value });
		}
		else if (type == 'Dodiocan') {
			this.setState({ d_dodiocan: value });
		}
		else if (type == 'Doordination') {
			this.setState({ d_doordinate: value });
		}
	}

	onSelectChange(type, value) {

		if (type == 'Province') {

			this.setState({ d_province: value });
		}
		else if (type == 'Diocese') {

			this.setState({ d_diocese: value });
		}
		else if (type == 'Parish') {

			this.setState({ d_parish: value });
		}
	}

	getDepName(given) {

		var res = '';

		if (given == '1') {

			res = 'CDPI';
		}
		else if (given == '2') {

			res = 'Diocese';

		}
		else if (given == '3') {

			res = 'Parish';

		}
		else if (given == '4') {

			res = 'Commission';

		}
		else if (given == '5') {

			res = 'Center';

		}
		else if (given == '6') {

			res = 'Other';

		}

		return res;

	}

	handleInputBlur(e) {
		if (!this.validator.allValid()) {
			this.validator.showMessages();
			this.forceUpdate();
		}
	}

	onClear() {
		document.getElementById("frmDiocese").reset();
	}

	handleSubmit(e) {
		try {

			e.preventDefault();
			if (this.validator.allValid()) {
				this.setState({ updateText: 'Updating..' });
				var token = Sanitize.getStoreageItem('accessToken');
				Reqst.axPost('api/v1/update_priest', $("#frmDiocese").serialize(), token).then(res => {
					if (res['data'].status == '1') {
						Notification({ message: res['data'].message, type: 'success' });
						document.getElementById("frmDiocese").reset();
						this.setState({ updateText: 'Update' });
					}
					else if (res['data'].status == '2') {
						alert(res['data'].message);
						this.setState({ updateText: 'Update' });
						this.setState({ expired: true });
					}
					else if (res['data'].status == '3') {
						Notification({ message: res['data'].message, type: 'success' });
						this.setState({ updateText: 'Update' });
					}
					else {
						Notification({ message: res['data'].message, type: 'error' });
						this.setState({ updateText: 'Update' });
					}
				});
			}
			else {
				this.validator.showMessages();
				// rerender to show messages for the first time
				this.forceUpdate();
			}
		}
		catch (e) {
			console.log(e);
		}
	}

	render() {


		if (this.state.expired) {
			return (<Redirect to="/login" />);
		}

		let sno = 1;

		const deps = [
			{
				value: '1',
				label: 'CDPI'
			},
			{
				value: '2',
				label: 'Diocese'
			},
			{
				value: '3',
				label: 'Parish'
			},
			{
				value: '4',
				label: 'Commission'
			},
			{
				value: '5',
				label: 'Center'
			},
			{
				value: '6',
				label: 'Other'
			}
		];

		return (
			<div>

				<Helmet>
					<title>{appName} | Configuration</title>
				</Helmet>


				<div className="topbar-header">
					<div className="topbar-header__content">

						<AppTitle
							menuLevel1="Configuration"
							menuLevel2="Priest"
							menuLevel3="Edit Priest"
							menuLevel2link="#/configuration/priest"
							isbreadcumb={true}
						/>


						<div className="topbar_buttons">

						</div>
					</div>
				</div>

				<div className="main_content">
					<section>
						<div className="" >

							<div className="card" >
								<h4 className="card-header bg-info text-white">Edit Priest</h4>
								<div className="card-body" >
									<form action="" method="post" encType="multipart/form-data" id="frmDiocese" onSubmit={this.handleSubmit} >
										<input type="hidden" name="i__id" value={Sanitize.getStoreageItem('userId')} />
										<input type="hidden" name="d_province_form" value={this.state.d_province} />
										<input type="hidden" name="files" value={this.state.files} />
										<input type="hidden" name="photoname" value={this.state.photoname} />
										<input type="hidden" name="d_diocese_form" value={this.state.d_diocese} />
										<input type="hidden" name="d_parish_form" value={this.state.d_parish} />
										<input type="hidden" name="d_state_form" value={this.state.d_state} />
										<input type="hidden" name="d_dobaptism_form" value={this.getdateoforginal(this.state.d_dobaptism)} />
										<input type="hidden" name="d_dodiocan_form" value={this.getdateoforginal(this.state.d_dodiocan)} />
										<input type="hidden" name="d_doordinate_form" value={this.getdateoforginal(this.state.d_doordinate)} />
										<input type="hidden" name="d_dob_form" value={this.getdateoforginal(this.state.d_dob)} />
										<input type="hidden" name="tid" value={this.state.t_id} />
										<input type="hidden" name="photoimage" value={this.state.photo} />

										<div className="row">
											<div className="col-md-6">

												<div className="row" style={{ marginBottom: 10 }}  >
													<div className="col-md-3" >
														<label>Name<span className="text-danger">*</span></label>
													</div>
													<div className="col-md-9" >
														<input type="text" name="d_name" id="d_name" className="form-control" value={this.state.d_name} onChange={this.handleInputChange} onBlur={this.handleInputBlur} required />
														{this.validator.message('Name', this.state.d_name, 'required')}
													</div>
												</div>

												<div className="row" style={{ marginBottom: 10 }}  >
													<div className="col-md-3" >
														<label>Mobile<span className="text-danger">*</span></label>
													</div>
													<div className="col-md-9" >
														<input type="text" name="d_mobile" id="d_mobile" className="form-control" value={this.state.d_mobile} onChange={this.handleInputChange} onBlur={this.handleInputBlur} required />
														{this.validator.message('Mobile', this.state.d_mobile, 'required|integer|min:10|max:10')}
													</div>
												</div>

												<div className="row" style={{ marginBottom: 10 }}  >
													<div className="col-md-3" >
														<label>Province<span className="text-danger"></span></label>
													</div>
													<div className="col-md-9" >
														<Select value={this.state.d_province} onChange={this.onSelectChange.bind(this, 'Province')} style={{ width: '100%' }}>
															{
																this.state.province.map(el => {
																	return <Select.Option key={el.id} label={el.provincename} value={el.id} />
																})
															}
														</Select>
													</div>
												</div>

												<div className="row" style={{ marginBottom: 10 }}  >
													<div className="col-md-3" >
														<label>Diocese</label>
													</div>
													<div className="col-md-9" >
														<Select value={this.state.d_diocese} onChange={this.onSelectChange.bind(this, 'Diocese')} style={{ width: '100%' }}>
															{
																this.state.diocese.map(el => {
																	return <Select.Option key={el.id} label={el.name} value={el.id} />
																})
															}
														</Select>

														{/*this.validator.message('Diocese', this.state.d_diocese, '')*/}
													</div>
												</div>

												<div className="row" style={{ marginBottom: 10 }}  >
													<div className="col-md-3" >
														<label>Date of Birth<span className="text-danger"></span></label>
													</div>
													<div className="col-md-9" >
														<DatePicker
															value={this.state.d_dob != null && this.state.d_dob != '0000-00-00' ? new Date(this.state.d_dob) : ''}
															placeholder="Pick a day"
															format="yyyy-MM-dd"
															align="right"
															onChange={(date) => { this.onDateChange('dob', date) }}
															style={{ width: '100%' }}
														/>
														{/*this.validator.message('Dob', this.state.d_dob, '')*/}

													</div>
												</div>

												<div className="row" style={{ marginBottom: 10 }}  >
													<div className="col-md-3" >
														<label>Place of Birth<span className="text-danger"></span></label>
													</div>
													<div className="col-md-9" >
														<input type="text" name="d_pob" id="d_pob" className="form-control" value={this.state.d_pob} onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
													</div>
												</div>

												<div className="row" style={{ marginBottom: 10 }}  >
													<div className="col-md-3" >
														<label>Parish of birth<span className="text-danger"></span></label>
													</div>
													<div className="col-md-9" >
														<input type="text" name="d_parishob" id="d_parishob" className="form-control" value={this.state.d_parishob} onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
													</div>
												</div>

												<div className="row" style={{ marginBottom: 10 }}  >
													<div className="col-md-3" >
														<label>Date of Baptism<span className="text-danger"></span></label>
													</div>
													<div className="col-md-9" >
														<DatePicker
															value={this.state.d_dobaptism != null && this.state.d_dobaptism != '0000-00-00' ? new Date(this.state.d_dobaptism) : ''}
															placeholder="Pick a day"
															format="yyyy-MM-dd"
															align="right"
															onChange={(date) => { this.onDateChange('Dobaptism', date) }}
															style={{ width: '100%' }}
														/>
														{/*this.validator.message('Dobaptism', this.state.d_dobaptism, '')*/}
													</div>
												</div>


												<div className="row" style={{ marginBottom: 10 }}  >
													<div className="col-md-3" >
														<label>Place of Baptism<span className="text-danger"></span></label>
													</div>
													<div className="col-md-9" >
														<input type="text" name="d_placeofbap" id="d_placeofbap" className="form-control" value={this.state.d_placeofbap} onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
													</div>
												</div>

												<div className="row" style={{ marginBottom: 10 }}  >
													<div className="col-md-3" >
														<label>Parish of Baptism<span className="text-danger"></span></label>
													</div>
													<div className="col-md-9" >
														<input type="text" name="d_parishobap" id="d_parishobap" className="form-control" value={this.state.d_parishobap} onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
													</div>
												</div>

												<div className="row" style={{ marginBottom: 10 }}  >
													<div className="col-md-3" >
														<label>Date of Diocan<span className="text-danger"></span></label>
													</div>
													<div className="col-md-9" >
														<DatePicker
															value={this.state.d_dodiocan != null && this.state.d_dodiocan != '0000-00-00' ? new Date(this.state.d_dodiocan) : ''}
															placeholder="Pick a day"
															format="yyyy-MM-dd"
															align="right"
															onChange={(date) => { this.onDateChange('Dodiocan', date) }}
															style={{ width: '100%' }}
														/>
														{/*this.validator.message('Dodiocan', this.state.d_dodiocan, '')*/}
													</div>
												</div>

												<div className="row" style={{ marginBottom: 10 }}  >
													<div className="col-md-3" >
														<label>Place of Diocan<span className="text-danger"></span></label>
													</div>
													<div className="col-md-9" >
														<input type="text" name="d_placeofdiocan" id="d_placeofdiocan" value={this.state.d_placeofdiocan} className="form-control" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
													</div>
												</div>

												<div className="row" style={{ marginBottom: 10 }}  >
													<div className="col-md-3" >
														<label>Bishop of Diocan<span className="text-danger"></span></label>
													</div>
													<div className="col-md-9" >
														<input type="text" name="d_bishopofdiocan" id="d_bishopofdiocan" value={this.state.d_bishopofdiocan} className="form-control" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
													</div>
												</div>

												<div className="row" style={{ marginBottom: 10 }}  >
													<div className="col-md-3" >
														<label>Date of Ordination<span className="text-danger"></span></label>
													</div>
													<div className="col-md-9" >
														<DatePicker
															value={this.state.d_doordinate != null && this.state.d_doordinate != '0000-00-00' ? new Date(this.state.d_doordinate) : ''}
															placeholder="Pick a day"
															format="yyyy-MM-dd"
															align="right"
															onChange={(date) => { this.onDateChange('Doordination', date) }}
															style={{ width: '100%' }}
														/>
														{/*this.validator.message('Doordination', this.state.d_doordinate, '')*/}
													</div>
												</div>

												<div className="row" style={{ marginBottom: 10 }}  >
													<div className="col-md-3" >
														<label>Place of Ordination<span className="text-danger"></span></label>
													</div>
													<div className="col-md-9" >
														<input type="text" name="d_placeofordinate" id="d_placeofordinate" value={this.state.d_placeofordinate} className="form-control" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
													</div>
												</div>

												<div className="row" style={{ marginBottom: 10 }}  >
													<div className="col-md-3" >
														<label>Bishop of Ordination<span className="text-danger"></span></label>
													</div>
													<div className="col-md-9" >
														<input type="text" name="d_bishopofordinate" id="d_bishopofordinate" value={this.state.d_bishopofordinate} className="form-control" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
													</div>
												</div>


												<div className="row" style={{ marginBottom: 10 }}  >
													<div className="col-md-3" >
														<label>Blood Group<span className="text-danger"></span></label>
													</div>
													<div className="col-md-9" >
														<input type="text" name="d_bloodgroup" id="d_bloodgroup" className="form-control" value={this.state.d_bloodgroup} onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
													</div>
												</div>


												<div className="row" style={{ marginBottom: 10 }}  >
													<div className="col-md-3" >
														<label>Email<span className="text-danger"></span></label>
													</div>
													<div className="col-md-9" >
														<input type="text" name="d_email" id="d_email" className="form-control" value={this.state.d_email} onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
														{/*this.validator.message('Email', this.state.d_email, 'email')*/}
													</div>
												</div>

												<div className="row" style={{ marginBottom: 10 }}  >
													<div className="col-md-3" >
														<label>Whatsapp<span className="text-danger"></span></label>
													</div>
													<div className="col-md-9" >
														<input type="text" value={this.state.d_whatsapp} name="d_whatsapp" id="d_whatsapp" className="form-control" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
														{/*this.validator.message('Whatsappno', this.state.d_whatsapp, 'integer')*/}
													</div>
												</div>

												<div className="row" style={{ marginBottom: 10 }}  >
													<div className="col-md-3" >
														<label>Others<span className="text-danger"></span></label>
													</div>
													<div className="col-md-9" >
														<input type="text" value={this.state.d_others} name="d_others" id="d_others" className="form-control" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
													</div>
												</div>
											</div>
											<div className="col-md-6">

												<div className="row" style={{ marginBottom: 10 }}  >
													<div className="col-md-3" >
														<label>Emergency Care<span className="text-danger"></span></label>
													</div>
													<div className="col-md-9" >
														<input type="text" value={this.state.d_emcare} name="d_emcare" id="d_emcare" className="form-control" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
													</div>
												</div>

												<div className="row" style={{ marginBottom: 10 }}  >
													<div className="col-md-3" >
														<label>Emergency No<span className="text-danger"></span></label>
													</div>
													<div className="col-md-9" >
														<input type="text" value={this.state.d_emno} name="d_emno" id="d_emno" className="form-control" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
														{/*this.validator.message('Emergencyno', this.state.d_emno, 'integer|min:10|max:10')*/}
													</div>
												</div>

												<div className="row" style={{ marginBottom: 10 }}  >
													<div className="col-md-3" >
														<label>Emergency Contact Person<span className="text-danger"></span></label>
													</div>
													<div className="col-md-9" >
														<input type="text" value={this.state.d_emcp} name="d_emcp" id="d_emcp" className="form-control" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
													</div>
												</div>

												<div className="row" style={{ marginBottom: 10 }}  >
													<div className="col-md-3" >
														<label>Parish</label>
													</div>
													<div className="col-md-9" >
														<Select value={this.state.d_parish} onChange={this.onSelectChange.bind(this, 'Parish')} style={{ width: '100%' }}>
															{
																this.state.parishes.map(el => {
																	return <Select.Option key={el.id} label={el.name} value={el.id} />
																})
															}
														</Select>
														{/*this.validator.message('Parish', this.state.d_parish, '')*/}
													</div>
												</div>

												<div className="row" style={{ marginBottom: 10 }}  >
													<div className="col-md-3" >
														<label>Publication Title 1<span className="text-danger"></span></label>
													</div>
													<div className="col-md-9" >
														<input type="text" value={this.state.d_pubtitle} name="d_pubtitle" id="d_pubtitle" className="form-control" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
													</div>
												</div>


												<div className="row" style={{ marginBottom: 10 }}  >
													<div className="col-md-3" >
														<label>Publication Type 1<span className="text-danger"></span></label>
													</div>
													<div className="col-md-9" >
														<input type="text" value={this.state.d_pubtype} name="d_pubtype" id="d_pubtype" className="form-control" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
													</div>
												</div>

												<div className="row" style={{ marginBottom: 10 }}  >
													<div className="col-md-3" >
														<label>Publication Year 1<span className="text-danger"></span></label>
													</div>
													<div className="col-md-9" >
														<input type="text" value={this.state.d_pubyear} name="d_pubyear" id="d_pubyear" className="form-control" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
													</div>
												</div>

												<div className="row" style={{ marginBottom: 10 }}  >
													<div className="col-md-3" >
														<label>Publication Link 1<span className="text-danger"></span></label>
													</div>
													<div className="col-md-9" >
														<input type="text" value={this.state.d_publink} name="d_publink" id="d_publink" className="form-control" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
													</div>
												</div>

												<div className="row" style={{ marginBottom: 10 }}  >
													<div className="col-md-3" >
														<label>Publication Title 2<span className="text-danger"></span></label>
													</div>
													<div className="col-md-9" >
														<input type="text" value={this.state.d_pubtitle_two} name="d_pubtitle_two" id="d_pubtitle_two" className="form-control" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
													</div>
												</div>

												<div className="row" style={{ marginBottom: 10 }}  >
													<div className="col-md-3" >
														<label>Publication Type 2<span className="text-danger"></span></label>
													</div>
													<div className="col-md-9" >
														<input type="text" value={this.state.d_pubtype_two} name="d_pubtype_two" id="d_pubtype_two" className="form-control" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
													</div>
												</div>

												<div className="row" style={{ marginBottom: 10 }}  >
													<div className="col-md-3" >
														<label>Publication Year 2<span className="text-danger"></span></label>
													</div>
													<div className="col-md-9" >
														<input type="text" value={this.state.d_pubyear_two} name="d_pubyear_two" id="d_pubyear_two" className="form-control" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
													</div>
												</div>

												<div className="row" style={{ marginBottom: 10 }}  >
													<div className="col-md-3" >
														<label>Publication Link 2<span className="text-danger"></span></label>
													</div>
													<div className="col-md-9" >
														<input type="text" value={this.state.d_publink_two} name="d_publink_two" id="d_publink_two" className="form-control" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
													</div>
												</div>

												<div className="row" style={{ marginBottom: 10 }}  >
													<div className="col-md-3" >
														<label>Image<span className="text-danger"></span></label>
													</div>
													<div className="col-md-4" >
														<div className="form-group" >
															<ReactFileReader handleFiles={this.handleFiles} fileTypes='image/*' base64={true}>
																<img src={this.state.files != '' ? this.state.files : this.state.photo != '' ? apiUrl + this.state.photo : NOIMAGE} style={{ border: '1px solid #cccccc', width: '100%', cursor: 'pointer' }} />
															</ReactFileReader>
														</div>
													</div>
												</div>

											</div>
										</div>

										<div className="row" style={{ marginBottom: 10 }}  >
											<div className="col-md-12" >
												<div className="text-right" >
													<button type="submit" name="i__save" className="btn btn-success btn-sm" disabled={this.state.updateText != 'Update'} >
														<i className="fa fa-check"></i> {this.state.updateText}
													</button>
												</div>
											</div>
										</div>

									</form>
									<br />
									<div>
										<h4>Ministry Details</h4>
										<Table responsive bordered striped >
											<thead>
												<tr>
													<th>S.No</th>
													<th>Diocese</th>
													<th>Department</th>
													<th>Designation</th>
													<th>From</th>
													<th>To</th>
													<th>Remarks</th>
													<th>Manage</th>
												</tr>
											</thead>
											<tbody>
												{this.state.dataLoading ?
													<tr height="60"><td colSpan="8"><center>

														<Loading />

													</center>
													</td></tr>
													:
													this.state.ministrydata.length > 0 ?

														this.state.ministrydata.map((item, i) => {
															return <tr>
																<td width="80" >{sno++}</td>
																<td>{item.diocesename}</td>
																<td style={{ textTransform: 'capitalize' }}>{this.getDepName(item.department)}</td>
																<td style={{ textTransform: 'capitalize' }}>{item.designationname}</td>
																<td>{this.getdateoforginal(item.fromdate)}</td>
																<td>{this.getdateoforginal(item.todate)}</td>
																<td>{item.remark}</td>
																<td align="center">
																	<Button type="text" onClick={this.handleEdit.bind(this, item.id)}>
																		<i className="fa fa-edit" style={{ fontSize: '16px' }} />
																	</Button>
																	&nbsp;
													    			<Button type="text" onClick={() => this.handleRemove(item.id)}>
																		<i className="fa fa-remove" style={{ fontSize: '16px' }} />
																	</Button>
																</td>

															</tr>;
														})
														:
														<tr><td colSpan="8"><center>No Data Found...</center></td></tr>
												}
											</tbody>
										</Table>
									</div>
									<br />
									<div>
										<h4>Education Details</h4>
										<Table responsive bordered striped >
											<thead>
												<tr>
													<th>S.No</th>
													<th>Course</th>
													<th>School / University</th>
													<th>Joined Date</th>
													<th>Completed Date</th>
													<th>Remarks</th>
													<th>Manage</th>
												</tr>
											</thead>
											<tbody>
												{this.state.dataLoading ?
													<tr height="60"><td colSpan="7"><center>

														<Loading />

													</center>
													</td></tr>
													:
													this.state.educationdata.length > 0 ?

														this.state.educationdata.map((item, i) => {
															return <tr>
																<td width="80" >{sno++}</td>
																<td style={{ textTransform: 'capitalize' }}>{item.course}</td>
																<td style={{ textTransform: 'capitalize' }}>{item.institution}</td>
																<td>{item.joindate}</td>
																<td>{item.completedate}</td>
																<td>{item.remarks}</td>
																<td align="center">
																	<Button type="text" onClick={this.handleEdit1.bind(this, item.id)}>
																		<i className="fa fa-edit" style={{ fontSize: '16px' }} />
																	</Button>
																	&nbsp;
													    			<Button type="text" onClick={() => this.handleRemove1(item.id)}>
																		<i className="fa fa-remove" style={{ fontSize: '16px' }} />
																	</Button>
																</td>

															</tr>;
														})
														:
														<tr><td colSpan="7"><center>No Data Found...</center></td></tr>
												}
											</tbody>
										</Table>
									</div>
								</div>
							</div>
						</div>
						<Modal size="md" show={this.state.showEditModal} onHide={() => { this.setState({ showEditModal: false }) }} className="right" >
							<Modal.Header closeButton >
								<Modal.Title>Update Ministry</Modal.Title>
							</Modal.Header>
							<Modal.Body>
								<EditMinistry data={this.state.editData} afterFinish={this.editPriest} />
							</Modal.Body>
						</Modal>
						<Modal size="md" show={this.state.showEditModal1} onHide={() => { this.setState({ showEditModal1: false }) }} className="right" >
							<Modal.Header closeButton >
								<Modal.Title>Update Education</Modal.Title>
							</Modal.Header>
							<Modal.Body>
								<UpdateEducation data={this.state.editData} afterFinish={this.editPriest} />
							</Modal.Body>
						</Modal>
					</section>
				</div>
			</div>
		);
	}

}
export default Priest;