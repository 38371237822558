import React, { Component } from 'react';
import {Link, Redirect} from 'react-router-dom';
import $ from 'jquery';
import {Breadcrumb } from 'element-react';

const goBackStyle = {
    fontSize: '21px',
    float: 'left',
    color: '#5f5d5d',
    marginTop: '0px',
    marginLeft: '0px',
	cursor: 'pointer'
}

const styles = {
	loaderContainer: {
	    position: 'absolute',
		width: '100%',
		marginTop: '-20px',
		right: '-610px',
	}
}

class AppTitle extends Component {
	
	constructor(props){
		super(props);
		
	
	}
	
	componentDidMount(){
		
		/* if($("#dy_apptitle").hasClass('app-title-fixed'))
		{
			var nextElement = $("#dy_apptitle").next();
			$(nextElement).addClass('pad-top-35');
		}
		else
		{
			var nextElement = $("#dy_apptitle").next();
			$(nextElement).removeClass('pad-top-35');
		} */
	}
	
  render() {
	  
	   let {
		   
			menuLevel1,
			menuLevel2,
			menuLevel3,
			menuLevel1link,
			menuLevel2link,
			menuLevel3link,
			menuLevel1click,
			isbreadcumb
			
        } = this.props;

		return (
		 <div className="topbar_breadcrumb" >
				<div className="page-title-wrapper" >
					<div className="page-title-actions" >
						<Breadcrumb>
						   <Breadcrumb.Item><a href="#/">Home</a></Breadcrumb.Item>
						   <Breadcrumb.Item>
						     {menuLevel1link && menuLevel1link!='' ? <a href={menuLevel1link} onClick={menuLevel1click}>{menuLevel1}</a> : menuLevel1 }
						   </Breadcrumb.Item>
							{menuLevel2 ? <Breadcrumb.Item active><a href={menuLevel2link}>{menuLevel2}</a></Breadcrumb.Item> : '' }
							{menuLevel3 ? <Breadcrumb.Item active><a href={menuLevel3link}>{menuLevel3}</a></Breadcrumb.Item> : '' }
					    </Breadcrumb>
					</div>
			   </div>
		 </div>
		);
	}
}

export default AppTitle;


