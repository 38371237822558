import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import $ from 'jquery';
import { appName, copyRight, apiUrl } from '../../utils/Variables';
import * as Reqst from '../../utils/Reqst';
import Sanitize from '../../utils/Sanitize';
import { notify } from 'react-notify-toast';
import { Helmet } from 'react-helmet';
import swal from 'sweetalert';
import Cookies from 'universal-cookie';
import ReactFileReader from 'react-file-reader';
import { Button, Tooltip, Loading, Notification, Select, DatePicker } from 'element-react';
import NOIMAGE from '../../assets/img/noimage.jpg';
import AppTitle from '../../containers/AppTitle';
import FullPageLoader from '../../containers/FullPageLoader';
import SimpleReactValidator from 'simple-react-validator';


class UpdateEducation extends Component {
	constructor(props) {
		super(props);
		this.state = {
			saveText: 'Update',
			isRedirect: false,
			dataLoading: false,
			diocese: '',
			dioceseList: [],
			parishList: [],
			expired: false,
			dioceseLoading: false,
			provinceLoading: false,
			stateLoading: false,
			showEditModal1 : false,
			designation : '',
			d_type: '',
			priestdata: [],
			designationList: [],
			editData: this.props.data,
			parish : '',
			files: '',
			photoname: '',
			type : '',
			d_state: '35',
			d_country: '101',
			d_erected: new Date()
		}
		this.validator = new SimpleReactValidator();
		this.handleInputChange = this.handleInputChange.bind(this);
		this.onDeptChange = this.onDeptChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	onDesiChange(val) {
		this.state.designation = val;
		this.setState({ designation: val });
	}

	onParishChange(val) {
		//this.state.parish = e;
		this.state.parish = val;
		this.setState({ parish: val });
	}

	onDioChange(val) {
		//this.state.diocese = e;
		this.state.diocese = val;
		this.setState({ diocese: val });
		this.loadParish();
	}

	onDeptChange(val) {
		//this.state.type = e;
		this.state.type = val;
		this.setState({ type: val });
		this.getdesignation();
	}
	
	 getdateofyear(date) {

        if (date != '' && date != null) {
            var date1 = new Date(date);
            var year = date1.getFullYear();
            var month = (date1.getMonth() + 1);
            var day = date1.getDate()
            var hours = date1.getHours();
            var minutes = date1.getMinutes();
            var seconds = date1.getSeconds();
            month = month < 10 ? '0' + month : month;
            day = day < 10 ? '0' + day : day;
            var strTime = year + '-' + month + '-' + day;
            return year;
        }
        else {

            return '';
        }

    }
	
	getdateofyear1(date) {

        if (date != '' && date != null) {
			
            var date1 = new Date(date);
          /*   var year = date1.getFullYear();
            var month = (date1.getMonth() + 1);
            var day = date1.getDate()
            var hours = date1.getHours();
            var minutes = date1.getMinutes();
            var seconds = date1.getSeconds();
            month = month < 10 ? '0' + month : month;
            day = day < 10 ? '0' + day : day;
            var strTime = year + '-' + month + '-' + day+' '+hours+':'+minutes;
			var ndate = new Date(strTime);
			console.log(date);
			console.log(ndate); */
            return date1;
			
        }
        else {

            return '';
        }

    }

	componentDidMount() {
		//this.loadDiocese();
	}

	loadParish() {

		this.setState({ parishList: [], dioceseLoading: true });
		var token = Sanitize.getStoreageItem('accessToken');
		Reqst.axGet('api/v1/getparishbydio/' + this.state.diocese, token).then(res => {

			let result = res['data'];

			if (result) {
				if (result.status == '1') {
					this.setState({ parishList: result.data, dioceseLoading: false });
				}
				else if (result.status == '2') {
					alert(result.message);
					this.setState({ dioceseLoading: false });
					this.setState({ expired: true });
				}
				else {
					Notification({ message: result.message, type: 'error' });
					this.setState({ dioceseLoading: false });
				}
			}
			else {

				Notification({ message: 'Something went wrong Try again...', type: 'error' });
				this.setState({ dioceseLoading: false });
			}
		});
	}

	loadDiocese() {

		this.setState({ dioceseList: [], dioceseLoading: true });
		var token = Sanitize.getStoreageItem('accessToken');
		Reqst.axGet('api/v1/getdioceseslist', token).then(res => {

			let result = res['data'];

			if (result) {
				if (result.status == '1') {
					this.setState({ dioceseList: result.data, dioceseLoading: false });
				}
				else if (result.status == '2') {
					alert(result.message);
					this.setState({ dioceseLoading: false });
					this.setState({ expired: true });
				}
				else {
					Notification({ message: result.message, type: 'error' });
					this.setState({ dioceseLoading: false });
				}
			}
			else {

				Notification({ message: 'Something went wrong Try again...', type: 'error' });
				this.setState({ dioceseLoading: false });
			}
		});
	}

	getdesignation() {

		this.setState({ designationList: [], dioceseLoading: true });
		var token = Sanitize.getStoreageItem('accessToken');
		Reqst.axGet('api/v1/getdesignationbytype/' + this.state.type, token).then(res => {

			let result = res['data'];

			if (result) {
				if (result.status == '1') {
					this.setState({ designationList: result.data, dioceseLoading: false });
				}
				else if (result.status == '2') {
					alert(result.message);
					this.setState({ dioceseLoading: false });
					this.setState({ expired: true });
				}
				else {
					Notification({ message: result.message, type: 'error' });
					this.setState({ dioceseLoading: false });
				}
			}
			else {

				Notification({ message: 'Something went wrong Try again...', type: 'error' });
				this.setState({ dioceseLoading: false });
			}
		});
	}

	handleInputChange(e) {
		this.setState({ [e.target.name]: e.target.value });
	}

	onClear() {
		document.getElementById("frmMinistry").reset();
	}
	
	getData(field) {
        var l = this.state.editData;
        return l[field];
    }
	
	 setDataDate(value, field) {
        var l = this.state.editData;
        l[field] = value;
        this.setState({ editData: l });

    }

    setData(field, e) {
        var l = this.state.editData;
        l[field] = e.target.value;
        this.setState({ editData: l });
    }

	handleSubmit(e) {
		try {

			e.preventDefault();
			if (this.validator.allValid()) {
				this.setState({ saveText: 'Updating..' });
				var token = Sanitize.getStoreageItem('accessToken');
				Reqst.axPost('api/v1/updateeducation', $("#frmMinistry").serialize(), token).then(res => {
					if (res['data'].status == '1') {
						Notification({ message: res['data'].message, type: 'success' });
						this.setState({ saveText: 'Update',showEditModal1: false});
					}
					else if (res['data'].status == '2') {
						alert(res['data'].message);
						this.setState({ saveText: 'Update' });
						this.setState({ expired: true });
					}
					else if (res['data'].status == '3') {
						Notification({ message: res['data'].message, type: 'success' });
						this.setState({ saveText: 'Update' });
					}
					else {
						Notification({ message: res['data'].message, type: 'error' });
						this.setState({ saveText: 'Update' });
					}
				});
			}
			else {
				this.validator.showMessages();
				// rerender to show messages for the first time
				this.forceUpdate();
			}
		}
		catch (e) {
			console.log(e);
		}
	}

	render() {


		if (this.state.expired) {
			return (<Redirect to="/login" />);
		}

		const types = [
			{
				value: '1',
				label: 'CDPI'
			},
			{
				value: '2',
				label: 'Diocese'
			},
			{
				value: '3',
				label: 'Parish'
			},
			{
				value: '4',
				label: 'Commission'
			},
			{
				value: '5',
				label: 'Center'
			},
			{
				value: '6',
				label: 'Other'
			}
		];

		return (
			                    <div>
									<form action="" method="post" encType="multipart/form-data" id="frmMinistry" onSubmit={this.handleSubmit} >
									   <input type="hidden" name="i__id" value={Sanitize.getStoreageItem('userId')} />
										<input type="hidden" name="tid" value={this.getData('id')} />
										<input type="hidden" name="fromdate" value={this.getdateofyear(this.getData('joindate'))} />
										<input type="hidden" name="todate" value={this.getdateofyear(this.getData('completedate'))} />
										
										
										<div className="row" style={{ marginBottom: 10 }}>

											<div className="col-md-6" >
												<label>Course<span className="text-danger">*</span></label>
												<input type="text" name="pr_course" className="form-control" onChange={this.setData.bind(this, 'course')} value={this.getData('course')} required />
											</div>
											<div className="col-md-6" >
												<label>School / University<span className="text-danger">*</span></label>
												<input type="text" name="pr_institute" className="form-control" onChange={this.setData.bind(this, 'institution')} value={this.getData('institution')} required />
											</div>
										</div>
							
										<div className="row" style={{ marginBottom: 10 }}>

											<div className="col-md-6" >
												<label>Joined Date<span className="text-danger"></span></label>&nbsp;
												         <DatePicker
                                                            value={this.getdateofyear1(this.getData('joindate'))}
                                                            placeholder="Pick a Year"
                                                            selectionMode="year"
                                                            align="right"
                                                            onChange={(date) => { this.setDataDate(date,'joindate') }}
                                                            style={{ width: '100%' }}
                                                        />
											</div>
											<div className="col-md-6" >
												<label>Completed Date<span className="text-danger">*</span></label>&nbsp;
												<DatePicker
                                                            value={this.getdateofyear1(this.getData('completedate'))}
                                                            placeholder="Pick a Year"
                                                            selectionMode="year"
                                                            align="right"
                                                            onChange={(date) => { this.setDataDate(date,'completedate') }}
                                                            style={{ width: '100%' }}
                                                        />
												
											</div>
										</div>
										
										<div className="row" style={{ marginBottom: 10 }}  >
										
											<div className="col-md-12">
												<label>Remark</label>
												<textarea name="remark" className="form-control" onChange={this.setData.bind(this, 'remarks')} value={this.getData('remarks')} ></textarea>
											</div>
										</div>
										<div className="row" style={{ marginBottom: 10 }}  >
											<div className="col-md-12" >
												<div className="text-right" >

													<button type="button" className="btn btn-danger btn-sm" >
														<i className="fa fa-remove"></i> Cancel
													</button>
													&nbsp;
													<button type="submit" name="i__save" className="btn btn-success btn-sm" disabled={this.state.saveText != 'Update'} >
														<i className="fa fa-check"></i> {this.state.saveText}
													</button>

												</div>
											</div>
										</div>

									</form>
							
			                      </div >
		);
	}

}
export default UpdateEducation;