import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import $ from 'jquery';
import {appName,copyRight,imagePath} from '../../utils/Variables';
import * as Reqst from '../../utils/Reqst';
import Sanitize from '../../utils/Sanitize';
import {notify} from 'react-notify-toast';
import {Helmet} from 'react-helmet';
import swal from 'sweetalert';
import Cookies from 'universal-cookie';
import ReactFileReader from 'react-file-reader';
import {Button,Tooltip,Loading,Notification } from 'element-react';
import NOIMAGE from '../../assets/img/noimage.jpg';
import AppTitle from '../../containers/AppTitle';
import FullPageLoader from '../../containers/FullPageLoader';

class Version extends Component{
	constructor(props){
		super(props);
		this.state = {
			saveText: 'Update',
			isRedirect: false,
			showLoader: false,
			cmpData: [],
			formode : false,
			dataLoading : false,
			dataView: [],
			logo: '',
			viewpass : false,
			expired : false,
			updatedLogo: '',
		}
		this.handleSubmit = this.handleSubmit.bind(this);
	}
	
	componentDidMount(){
		
		this.loadData();
	}
	
	
	
	loadData(){
		
		this.setState({dataLoading:true});
		let d = JSON.parse(Sanitize.getStoreageItem('userData'));
		var token = Sanitize.getStoreageItem('accessToken');
		Reqst.axGet('api/v1/getversion',token).then(res=>{
			
				let result = res['data'];
				
				if(result){
						if(result.status=='1')
						{
							this.setState({dataView: result.data, dataLoading:false});
						}
						else if(result.status=='2')
						{
							alert(result.message);
							this.setState({dataLoading:false});
							this.setState({expired:true});
						}
						else
						{
							Notification({message:result.message,type: 'error'});
							this.setState({dataLoading:false});
						}
				 }
				 else{
					 
							Notification({message:'Something went wrong Try again...',type: 'error'});
							this.setState({dataLoading:false});
				  }
			});
	}
	
	getnovalues(str) {

		if (str != '' && str != null) {
			return str;
		}
		else {

			return 'Not Assigned';
		}

	}

	getData(field){
		let d = this.state.dataView;
		return d[field];
	}
	
	setData(field,e){
		var v = e.target.value;
		let d = this.state.dataView;
		d[field] = v;
		this.setState({dataView:d});
	}
	
	
	
	handleSubmit(e){
		try{
		
			e.preventDefault();
			this.setState({showLoader:true, saveText: 'Updating..'});
			var token = Sanitize.getStoreageItem('accessToken');
			Reqst.axPost('api/v1/updateversion',$("#frmSettings").serialize(),token).then(res=>{
				if(res['data'].status=='1')
				{
					Notification({message:res['data'].message,type: 'success'});
					this.setState({showLoader:false, saveText:'Update',formode:false});
					this.loadData();
					$('html, body').animate({scrollTop:0}, 'slow');
				}
				else if(res['data'].status=='2')
				{
					  alert(res['data'].message);
					  this.setState({saveText:'Update'});
					  this.setState({expired:true});
				}
				else
				{
					Notification({message:res['data'].message,type: 'error'});
					this.setState({showLoader:false, saveText:'Update'});
					$('html, body').animate({scrollTop:0}, 'slow');
				}
			});
		}
		catch(e){
			console.log(e);
		}
	}
	
	render(){
		
		
		
		if(this.state.expired)
		{
			return (<Redirect to="/login" />);
		}
		
		
		
		return(
			<div>
				
				<Helmet>
					<title>{appName} | Mobile Version</title>
				</Helmet>
				
				
				<div className="topbar-header">
						<div className="topbar-header__content">
						
						   <AppTitle
								menuLevel1="Mobile Version"
								isbreadcumb={true}
							/>
						
				       
						<div className="topbar_buttons">
								
						</div>
					 </div>
				</div>
				
				<div className="main_content">
				{!this.state.formode ? 
				 
				 <div className="smartphone">
                     <div className="content">
						
						
					   {this.state.dataLoading ?
					   
						   <div className="text-center">
						     <Loading />
						   </div>
						:
						
						<div>
						        <div className="pull-right">
									<Tooltip className="item" content="Update" placement="bottom" >
											<Button type="text" size="large" onClick={() => this.setState({ formode: true})}>
												<i className="fa fa-edit" />
											</Button>
										</Tooltip>
								</div>
						
								 <div style={{textAlign:'center',padding:'50px'}}>
								
									
										<h4>{this.getData('projectname')}</h4><br/>
										<h4>{this.getData('domainname')}</h4><br/>
										<h4>{this.getData('versionname')}</h4><br/>
										<h4>{this.getData('versioncode')}</h4><br/>
									
								</div>
						</div>
								
						
					    }
					  
						 </div>
								
					    </div>
				: '' }
				
				{this.state.formode ? 
				<div className="smartphone">
                     <div className="content">
				            <form action="" method="post" encType="multipart/form-data" id="frmSettings" onSubmit={this.handleSubmit} >
							<input type="hidden" name="i__id" value={this.getData('id')} />
								<div style={{textAlign:'center',padding:'50px'}}>
								
							                   <div className="row" style={{ marginBottom: 10 }}  >
													
													<div className="col-md-12" >
														<input type="text" name="projectname" id="projectname" className="form-control" required placeholder="Project Name" value={this.getData('projectname')} onChange={this.setData.bind(this,'projectname')} />
													</div>
												</div>
												
												<div className="row" style={{ marginBottom: 10 }}  >
													
													<div className="col-md-12" >
														<input type="text" name="domainname" id="domainname" className="form-control" required placeholder="Domain Name" value={this.getData('domainname')} onChange={this.setData.bind(this,'domainname')} />
													</div>
												</div>
												
												<div className="row" style={{ marginBottom: 10 }}  >
													
													<div className="col-md-12" >
														<input type="text" name="versionname" id="versionname" className="form-control" required placeholder="Version Name" value={this.getData('versionname')} onChange={this.setData.bind(this,'versionname')} />
													</div>
												</div>
												
												<div className="row" style={{ marginBottom: 10 }}  >
													
													<div className="col-md-12" >
														<input type="text" name="versioncode" id="versioncode" className="form-control" required placeholder="Version Code" value={this.getData('versioncode')} onChange={this.setData.bind(this,'versioncode')} />
													</div>
												</div>
												
												<div className="row" style={{ marginBottom: 10 }}  >
												<div className="col-md-12" >
													<div className="text-right" >
														
														<button  className="btn btn-danger btn-sm"  onClick={() => this.setState({ formode: false})}>
															<i className="fa fa-remove"></i> Cancel
														</button>
														&nbsp;
														<button type="submit" name="i__save" className="btn btn-success btn-sm" disabled={this.state.saveText!='Update'} >
															<i className="fa fa-check"></i> {this.state.saveText}
														</button>
														
													</div>
												</div>
											</div>
												
								</div>
				
				          </form>
				    </div>
				  </div>
	            : '' }
				</div>
			</div>
		);
	}
	
}
export default Version;