import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import $ from 'jquery';
import { appName, copyRight, imagePath } from '../../utils/Variables';
import * as Reqst from '../../utils/Reqst';
import Sanitize from '../../utils/Sanitize';
import { notify } from 'react-notify-toast';
import { Helmet } from 'react-helmet';
import swal from 'sweetalert';
import Cookies from 'universal-cookie';
import ReactFileReader from 'react-file-reader';
import { Button, Tooltip, Loading, Notification, Select, DatePicker } from 'element-react';
import NOIMAGE from '../../assets/img/noimage.jpg';
import AppTitle from '../../containers/AppTitle';
import FullPageLoader from '../../containers/FullPageLoader';
import SimpleReactValidator from 'simple-react-validator';
import {CKEditor} from 'ckeditor4-react';

class Diocese extends Component {
    constructor(props) {
        super(props);
        this.state = {
            saveText: 'Save',
            isRedirect: false,
            dataLoading: false,
            countries: [],
            states: [],
            expired: false,
            countryLoading: false,
            stateLoading: false,
            d_type: '',
            d_state: '35',
            d_country: '101',
            d_erected: new Date(),
            files: '',
            photoname: '',
        }
        this.validator = new SimpleReactValidator();
        this.handleFiles = this.handleFiles.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.onSelectChange = this.onSelectChange.bind(this);
        this.onDateChange = this.onDateChange.bind(this);
        this.handleInputBlur = this.handleInputBlur.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleContentChange = this.handleContentChange.bind(this);
    }
	
	handleContentChange(e){
		
		this.setState({content : e.editor.getData()});
	}

    handleFiles(files) {
        this.setState({ files: files.base64, photoname: files.fileList[0]['name'] });
    }

    componentDidMount() {

        this.loadCountries();
    }

    getdateoforginal(date) {

        if (date != '' && date != null) {
            var date1 = new Date(date);
            var year = date1.getFullYear();
            var month = (date1.getMonth() + 1);
            var day = date1.getDate()
            var hours = date1.getHours();
            var minutes = date1.getMinutes();
            var seconds = date1.getSeconds();
            month = month < 10 ? '0' + month : month;
            day = day < 10 ? '0' + day : day;
            var strTime = year + '-' + month + '-' + day;
            return strTime;
        }
        else {

            return '- - - -';
        }

    }

    loadCountries() {

        this.setState({ countries: [], countryLoading: true });
        var token = Sanitize.getStoreageItem('accessToken');
        Reqst.axGet('api/v1/getcountries', token).then(res => {

            let result = res['data'];

            if (result) {
                if (result.status == '1') {
                    this.setState({ countries: result.data, countryLoading: false });
                    this.loadStates();
                }
                else if (result.status == '2') {
                    alert(result.message);
                    this.setState({ countryLoading: false });
                    this.setState({ expired: true });
                }
                else {
                    Notification({ message: result.message, type: 'error' });
                    this.setState({ countryLoading: false });
                }
            }
            else {

                Notification({ message: 'Something went wrong Try again...', type: 'error' });
                this.setState({ countryLoading: false });
            }
        });
    }

    loadStates() {

        this.setState({ states: [], d_state: '', stateLoading: true });
        var country = this.state.d_country;
        var token = Sanitize.getStoreageItem('accessToken');
        Reqst.axGet('api/v1/getstates/' + country, token).then(res => {

            let result = res['data'];

            if (result) {
                if (result.status == '1') {
                    this.setState({ states: result.data, d_state: '35', stateLoading: false });
                }
                else if (result.status == '2') {
                    alert(result.message);
                    this.setState({ stateLoading: false });
                    this.setState({ expired: true });
                }
                else {
                    Notification({ message: result.message, type: 'error' });
                    this.setState({ stateLoading: false });
                }
            }
            else {

                Notification({ message: 'Something went wrong Try again...', type: 'error' });
                this.setState({ stateLoading: false });
            }
        });
    }


    handleInputChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    onDateChange(value) {

        this.setState({ d_erected: value });
    }

    onSelectChange(type, value) {

        if (type == 'Type') {

            this.setState({ d_type: value });
        }
        else if (type == 'Country') {

            this.setState({ d_country: value });
            this.state.d_country = value;
            this.loadStates();
        }
        else if (type == 'State') {

            this.setState({ d_state: value });
        }
    }

    handleInputBlur(e) {
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    onClear() {


        document.getElementById("frmDiocese").reset();
    }

    handleSubmit(e) {
        try {

            e.preventDefault();
            if (this.validator.allValid()) {
                this.setState({ saveText: 'Saving..' });
                var token = Sanitize.getStoreageItem('accessToken');
                Reqst.axPost('api/v1/add_diocese', $("#frmDiocese").serialize(), token).then(res => {
                    if (res['data'].status == '1') {
                        Notification({ message: res['data'].message, type: 'success' });
                        document.getElementById("frmDiocese").reset();
                        this.setState({ saveText: 'Save', files: '', photoname: '' });
                    }
                    else if (res['data'].status == '2') {
                        alert(res['data'].message);
                        this.setState({ saveText: 'Save' });
                        this.setState({ expired: true });
                    }
                    else if (res['data'].status == '3') {
                        Notification({ message: res['data'].message, type: 'success' });
                        this.setState({ saveText: 'Save' });
                    }
                    else {
                        Notification({ message: res['data'].message, type: 'error' });
                        this.setState({ saveText: 'Save' });
                    }
                });
            }
            else {
                this.validator.showMessages();
                // rerender to show messages for the first time
                this.forceUpdate();
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    render() {

        const types = [
            {
                value: 'diocese',
                label: 'Diocese'
            },
            {
                value: 'archdiocese',
                label: 'Archdiocese'
            }
        ];

        if (this.state.expired) {
            return (<Redirect to="/login" />);
        }



        return (
            <div>

                <Helmet>
                    <title>{appName} | Configuration | Add Diocese</title>
                </Helmet>


                <div className="topbar-header">
                    <div className="topbar-header__content">

                        <AppTitle
                            menuLevel1="Configuration"
                            menuLevel2="Dioceses"
                            menuLevel3="Add Diocese"
                            menuLevel2link="#/configuration/dioceses"
                            isbreadcumb={true}
                        />


                        <div className="topbar_buttons">

                        </div>
                    </div>
                </div>

                <div className="main_content">
                    <section>
                        <div className="container" >

                            <div className="card" >
                                <h4 className="card-header bg-info text-white">Add Diocese</h4>
                                <div className="card-body" >
                                    <form action="" method="post" encType="multipart/form-data" id="frmDiocese" onSubmit={this.handleSubmit} >
                                        <input type="hidden" name="i__id" value={Sanitize.getStoreageItem('userId')} />
                                        <input type="hidden" name="d_country_form" value={this.state.d_country} />
                                        <input type="hidden" name="d_type_form" value={this.state.d_type} />
                                        <input type="hidden" name="d_state_form" value={this.state.d_state} />
                                        <input type="hidden" name="d_erected_form" value={this.getdateoforginal(this.state.d_erected)} />
                                        <input type="hidden" name="photoname" value={this.state.photoname} />
                                        <input type="hidden" name="files" value={this.state.files} />
                                        <input type="hidden" name="i__content" value={this.state.content} />

                                        <div className="row">
                                            <div className="col-md-6">

                                                <div className="row" style={{ marginBottom: 10 }} >
                                                    <div className="col-md-3" >
                                                        <label>Name<span className="text-danger">*</span></label>
                                                    </div>
                                                    <div className="col-md-9" >
                                                        <input type="text" name="d_name" id="d_name" className="form-control" onChange={this.handleInputChange} onBlur={this.handleInputBlur} required />
                                                        {this.validator.message('Diocese Name', this.state.d_name, 'required')}
                                                    </div>
                                                </div>

                                                <div className="row" style={{ marginBottom: 10 }} >
                                                    <div className="col-md-3" >
                                                        <label>Type<span className="text-danger">*</span></label>
                                                    </div>
                                                    <div className="col-md-9" >
                                                        <Select required onChange={this.onSelectChange.bind(this, 'Type')} style={{ width: '100%' }}>
                                                            {
                                                                types.map(el => {
                                                                    return <Select.Option key={el.value} label={el.label} value={el.value} />
                                                                })
                                                            }
                                                        </Select>

                                                        {this.validator.message('Type', this.state.d_type, 'required')}

                                                    </div>
                                                </div>

                                                <div className="row" style={{ marginBottom: 10 }} >
                                                    <div className="col-md-3" >
                                                        <label>Country<span className="text-danger">*</span></label>
                                                    </div>
                                                    <div className="col-md-9" >
                                                        <Select required onChange={this.onSelectChange.bind(this, 'Country')} value={this.state.d_country} loading={this.state.countryLoading} filterable={true} clearable={true} style={{ width: '100%' }}>
                                                            {
                                                                this.state.countries.map(el => {
                                                                    return <Select.Option key={el.id} label={el.name} value={el.id} />
                                                                })
                                                            }
                                                        </Select>

                                                        {this.validator.message('Country', this.state.d_country, 'required')}

                                                    </div>
                                                </div>

                                                <div className="row" style={{ marginBottom: 10 }} >
                                                    <div className="col-md-3" >
                                                        <label>Address<span className="text-danger"></span></label>
                                                    </div>
                                                    <div className="col-md-9" >
                                                        <textarea name="d_addr" id="d_addr" className="form-control" onChange={this.handleInputChange} onBlur={this.handleInputBlur} ></textarea>
                                                        {this.validator.message('Address', this.state.d_addr, '')}

                                                    </div>
                                                </div>

                                                <div className="row" style={{ marginBottom: 10 }} >
                                                    <div className="col-md-3" >
                                                        <label>Email<span className="text-danger"></span></label>
                                                    </div>
                                                    <div className="col-md-9" >
                                                        <input type="text" name="d_email" id="d_email" className="form-control" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
                                                        {this.validator.message('Email', this.state.d_email, 'email')}
                                                    </div>
                                                </div>
                                                <div className="row" style={{ marginBottom: 10 }} >
                                                    <div className="col-md-3" >
                                                        <label>Bishop Name<span className="text-danger">*</span></label>
                                                    </div>
                                                    <div className="col-md-9" >
                                                        <input type="text" name="b_name" id="b_name" className="form-control" onChange={this.handleInputChange} onBlur={this.handleInputBlur} required />
                                                        {this.validator.message('Bishop Name', this.state.b_name, 'required')}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="row" style={{ marginBottom: 10 }} >
                                                    <div className="col-md-3" >
                                                        <label>Erected<span className="text-danger">*</span></label>
                                                    </div>
                                                    <div className="col-md-9" >
                                                        <DatePicker
                                                            value={this.state.d_erected}
                                                            placeholder="Pick a day"
                                                            format="yyyy-MM-dd"
                                                            align="right"
                                                            onChange={(date) => { this.onDateChange(date) }}
                                                            style={{ width: '100%' }}
                                                        />
                                                        {this.validator.message('Erected', this.state.d_erected, 'required')}

                                                    </div>
                                                </div>

                                                <div className="row" style={{ marginBottom: 10 }} >
                                                    <div className="col-md-3" >
                                                        <label>Mobile<span className="text-danger">*</span></label>
                                                    </div>
                                                    <div className="col-md-9" >
                                                        <input type="text" name="d_mobile" id="d_mobile" className="form-control" onChange={this.handleInputChange} onBlur={this.handleInputBlur} required />
                                                        {this.validator.message('Mobile', this.state.d_mobile, 'required|integer|min:10|max:10')}
                                                    </div>
                                                </div>

                                                <div className="row" style={{ marginBottom: 10 }} >
                                                    <div className="col-md-3" >
                                                        <label>State<span className="text-danger">*</span></label>
                                                    </div>
                                                    <div className="col-md-9" >
                                                        <Select required onChange={this.onSelectChange.bind(this, 'State')} value={this.state.d_state} loading={this.state.stateLoading} style={{ width: '100%' }}>
                                                            {
                                                                this.state.states.map(el => {
                                                                    return <Select.Option key={el.id} label={el.name} value={el.id} />
                                                                })
                                                            }
                                                        </Select>

                                                        {this.validator.message('State', this.state.d_state, 'required')}

                                                    </div>
                                                </div>

                                                <div className="row" style={{ marginBottom: 10 }} >
                                                    <div className="col-md-3" >
                                                        <label>Website<span className="text-danger"></span></label>
                                                    </div>
                                                    <div className="col-md-9" >
                                                        <input type="text" name="d_web" id="d_web" className="form-control" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
                                                        {this.validator.message('Website', this.state.d_web, 'url')}
                                                    </div>
                                                </div>

                                                <div className="row" style={{ marginBottom: 10 }} >
                                                    <div className="col-md-3" >
                                                        <label>Phone<span className="text-danger"></span></label>
                                                    </div>
                                                    <div className="col-md-9" >
                                                        <input type="text" name="d_phone" id="d_phone" className="form-control" onChange={this.handleInputChange} onBlur={this.handleInputBlur} />
                                                        {this.validator.message('Phone', this.state.d_phone, 'integer')}
                                                    </div>
                                                </div>
                                                <div className="row" style={{ marginBottom: 10 }} >
                                                    <div className="col-md-3" >
                                                        <label>Bishop Photo</label>
                                                    </div>
                                                    <div className="col-md-3" >
                                                        <ReactFileReader handleFiles={this.handleFiles} fileTypes='image/*' base64={true}>
                                                            <img src={this.state.files != '' ? this.state.files : NOIMAGE} style={{ border: '1px solid #cccccc', width: '100%', cursor: 'pointer' }} />
                                                        </ReactFileReader>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
										<div className="row" style={{ marginBottom: 10 }}>
											<div className="col-md-12">
												<label>History</label>
												<CKEditor onChange={this.handleContentChange} />
											</div>
										</div>
                                        <div className="row" style={{ marginBottom: 10 }} >
                                            <div className="col-md-12" >
                                                <div className="text-right" >


                                                    <button type="submit" name="i__save" className="btn btn-success btn-sm" disabled={this.state.saveText != 'Save'} >
                                                        <i className="fa fa-check"></i> {this.state.saveText}
                                                    </button>

                                                </div>
                                            </div>
                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        );
    }

}
export default Diocese;