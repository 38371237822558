import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import $ from 'jquery';
import {appName,copyRight,imagePath} from '../../utils/Variables';
import * as Reqst from '../../utils/Reqst';
import Sanitize from '../../utils/Sanitize';
import {notify} from 'react-notify-toast';
import {Helmet} from 'react-helmet';
import swal from 'sweetalert';
import Cookies from 'universal-cookie';
import ReactFileReader from 'react-file-reader';
import {Button,Tooltip,Loading,Notification,Select,DatePicker } from 'element-react';
import NOIMAGE from '../../assets/img/noimage.jpg';
import AppTitle from '../../containers/AppTitle';
import FullPageLoader from '../../containers/FullPageLoader';
import SimpleReactValidator from 'simple-react-validator';

class AddParish extends Component{
	constructor(props){
		super(props);
		this.state = {
			saveText: 'Save',
			isRedirect: false,
			dataLoading : false,
			dioceses : [],
			expired : false,
			dioLoading : false,
			distLoading : false,
			districts : [],
			d_erected : new Date()
		}
		this.validator = new SimpleReactValidator();
		this.handleInputChange = this.handleInputChange.bind(this);
		this.onSelectChange = this.onSelectChange.bind(this);
		this.onDateChange = this.onDateChange.bind(this);
		this.handleInputBlur = this.handleInputBlur.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}
	
	componentDidMount(){
		
		this.loadDioceses();
		this.loadDistricts();
	}
	
	getdateoforginal(date) {

		if (date != '' && date != null) {
			var date1 = new Date(date);
			var year = date1.getFullYear();
			var month = (date1.getMonth() + 1);
			var day = date1.getDate()
			var hours = date1.getHours();
			var minutes = date1.getMinutes();
			var seconds = date1.getSeconds();
			month = month < 10 ? '0' + month : month;
			day = day < 10 ? '0' + day : day;
			var strTime = year + '-' + month + '-' + day;
			return strTime;
		}
		else {

			return '- - - -';
		}

	}
	
	loadDioceses(){
		
		this.setState({dioceses : [],dioLoading:true});
		var token = Sanitize.getStoreageItem('accessToken');
		Reqst.axGet('api/v1/getdioceseslist',token).then(res=>{
			
				let result = res['data'];
				
				if(result){
						if(result.status=='1')
						{
							this.setState({dioceses: result.data, dioLoading:false});
						}
						else if(result.status=='2')
						{
							alert(result.message);
							this.setState({dioLoading:false});
							this.setState({expired:true});
						}
						else
						{
							Notification({message:result.message,type: 'error'});
							this.setState({dioLoading:false});
						}
				 }
				 else{
					 
							Notification({message:'Something went wrong Try again...',type: 'error'});
							this.setState({parishLoading:false});
				  }
			});
	}
	
	loadDistricts(){
		
		this.setState({districts : [],distLoading:true});
		var token = Sanitize.getStoreageItem('accessToken');
		Reqst.axGet('api/v1/getdistrictslist',token).then(res=>{
			
				let result = res['data'];
				
				if(result){
						if(result.status=='1')
						{
							this.setState({districts: result.data, distLoading:false});
						}
						else if(result.status=='2')
						{
							alert(result.message);
							this.setState({distLoading:false});
							this.setState({expired:true});
						}
						else
						{
							Notification({message:result.message,type: 'error'});
							this.setState({distLoading:false});
						}
				 }
				 else{
					 
							Notification({message:'Something went wrong Try again...',type: 'error'});
							this.setState({parishLoading:false});
				  }
			});
	}
	
	
	handleInputChange(e){
		this.setState({[e.target.name]: e.target.value});
	}
	
	onDateChange(value){
		
		 this.setState({d_erected: value});
	}
	
	onSelectChange(type,value){
		
		if(type=='Diocese'){
			
		  this.setState({p_diocese: value});
		}
		else if(type=='District'){
			
		  this.setState({p_district: value});
		}
		
	}
	
	handleInputBlur(e){
		if (!this.validator.allValid()) {
			this.validator.showMessages();			
			this.forceUpdate();
		}
	}
	
	onClear(){
		
		
		document.getElementById("frmParish").reset();
	}
	
	handleSubmit(e){
		try{
		
			e.preventDefault();
			 if (this.validator.allValid()) {
				this.setState({saveText: 'Saving..'});
				var token = Sanitize.getStoreageItem('accessToken');
				Reqst.axPost('api/v1/add_parish',$("#frmParish").serialize(),token).then(res=>{
					if(res['data'].status=='1')
					{
						Notification({message:res['data'].message,type: 'success'});
						document.getElementById("frmParish").reset();
						this.setState({saveText:'Save'});
					}
					else if(res['data'].status=='2')
					{
						  alert(res['data'].message);
						  this.setState({saveText:'Save'});
						  this.setState({expired:true});
					}
					else if(res['data'].status=='3')
					{
						  Notification({message:res['data'].message,type: 'success'});
						  this.setState({saveText:'Save'});
					}
					else
					{
						Notification({message:res['data'].message,type: 'error'});
						this.setState({saveText:'Save'});
					}
				});
		  }
		   else {
			this.validator.showMessages();
			// rerender to show messages for the first time
			this.forceUpdate();
		 }
		}
		catch(e){
			console.log(e);
		}
	}
	
	render(){
		
		const types = [
			{
				value: 'diocese',
				label: 'Diocese'
			},
			{
				value: 'archdiocese',
				label: 'Archdiocese'
			}
		];
		
		if(this.state.expired)
		{
			return (<Redirect to="/login" />);
		}
		
		
		
		return(
			<div>
				
				<Helmet>
					<title>{appName} | Configuration | Add Parish</title>
				</Helmet>
				
				
				<div className="topbar-header">
						<div className="topbar-header__content">
						
						   <AppTitle
								menuLevel1="Configuration"
								menuLevel2="Parishes"
								menuLevel3="Add Parish"
								menuLevel2link="#/configuration/parishes"
								isbreadcumb={true}
							/>
						
				       
						<div className="topbar_buttons">
								
						</div>
					 </div>
				</div>
				
				<div className="main_content">
				       <section>
							<div className="container" >
								
										<div className="card" >
											<h4 className="card-header bg-info text-white">Add Parish</h4>
											<div className="card-body" >
													<form action="" method="post" encType="multipart/form-data" id="frmParish" onSubmit={this.handleSubmit} >
														<input type="hidden" name="i__id" value={Sanitize.getStoreageItem('userId')} />
														<input type="hidden" name="p_diocese_form" value={this.state.p_diocese} />
														<input type="hidden" name="p_district_form" value={this.state.p_district} />
													
														<div className="row">
														  <div className="col-md-6">
														  
														     <div className="row" style={{ marginBottom: 10 }}  >
																	<div className="col-md-3" >
																		<label>Diocese<span className="text-danger">*</span></label>
																	</div>
																	<div className="col-md-9" >
																	<Select required onChange={this.onSelectChange.bind(this,'Diocese')} loading={this.state.dioLoading} filterable={true} clearable={true} style={{width:'100%'}}>
																		{
																			this.state.dioceses.map(el => {
																				return <Select.Option key={el.id} label={el.name} value={el.id} />
																			})
																		}
																	</Select>
																	
																		{this.validator.message('Diocese', this.state.p_diocese, 'required')}
																		
																	</div>
																</div>
																
																<div className="row" style={{ marginBottom: 10 }}  >
																	<div className="col-md-3" >
																		<label>Mobile<span className="text-danger"></span></label>
																	</div>
																	<div className="col-md-9" >
																		<input type="text" name="p_mobile" id="p_mobile" className="form-control"  onChange={this.handleInputChange} onBlur={this.handleInputBlur}  />
																		{this.validator.message('Mobile', this.state.p_mobile, 'integer|min:10|max:10')}
																	</div>
																</div>
																
																<div className="row" style={{ marginBottom: 10 }}  >
																	<div className="col-md-3" >
																		<label>Contact Person<span className="text-danger"></span></label>
																	</div>
																	<div className="col-md-9" >
																		<input type="text" name="p_contperson" id="p_contperson" className="form-control"  onChange={this.handleInputChange} onBlur={this.handleInputBlur}  />
																		{this.validator.message('Contact Person', this.state.p_contperson, 'alpha_space')}
																	</div>
																</div>
																
																 <div className="row" style={{ marginBottom: 10 }}  >
																	<div className="col-md-3" >
																		<label>District<span className="text-danger">*</span></label>
																	</div>
																	<div className="col-md-9" >
																	<Select required onChange={this.onSelectChange.bind(this,'District')} loading={this.state.distLoading} filterable={true} clearable={true} style={{width:'100%'}}>
																		{
																			this.state.districts.map(el => {
																				return <Select.Option key={el.id} label={el.name} value={el.id} />
																			})
																		}
																	</Select>
																	
																		{this.validator.message('District', this.state.p_district, 'required')}
																		
																	</div>
																</div>
														  
																
															</div>
															<div className="col-md-6">
																
																<div className="row" style={{ marginBottom: 10 }}  >
																	<div className="col-md-3" >
																		<label>Name<span className="text-danger">*</span></label>
																	</div>
																	<div className="col-md-9" >
																		<input type="text" name="p_name" id="p_name" className="form-control"  onChange={this.handleInputChange} onBlur={this.handleInputBlur} required />
																		{this.validator.message('Parish Name', this.state.p_name, 'required')}
																	</div>
																</div>
																
																<div className="row" style={{ marginBottom: 10 }}  >
																	<div className="col-md-3" >
																		<label>Email<span className="text-danger"></span></label>
																	</div>
																	<div className="col-md-9" >
																		<input type="text" name="p_mail" id="p_mail" className="form-control"  onChange={this.handleInputChange} onBlur={this.handleInputBlur}  />
																		{this.validator.message('Email', this.state.p_mail, 'email')}
																	</div>
																</div>
																
																<div className="row" style={{ marginBottom: 10 }}  >
																	<div className="col-md-3" >
																		<label>Phone<span className="text-danger"></span></label>
																	</div>
																	<div className="col-md-9" >
																		<input type="text" name="p_phone" id="p_phone" className="form-control"  onChange={this.handleInputChange} onBlur={this.handleInputBlur}  />
																		{this.validator.message('Phone', this.state.p_phone, 'integer')}
																	</div>
																</div>
																
																<div className="row" style={{ marginBottom: 10 }}  >
																	<div className="col-md-3" >
																		<label>Address<span className="text-danger"></span></label>
																	</div>
																	<div className="col-md-9" >
																	     <textarea name="p_addr" id="p_addr" className="form-control" onChange={this.handleInputChange} onBlur={this.handleInputBlur} ></textarea>
																		{this.validator.message('Address', this.state.p_addr, '')}
																		
																	</div>
																</div>
														
													    </div>
													 </div>	
															
													
															<div className="row" style={{ marginBottom: 10 }}  >
																<div className="col-md-12" >
																	<div className="text-right" >
																		
																		
																		<button type="submit" name="i__save" className="btn btn-success btn-sm" disabled={this.state.saveText!='Save'} >
																			<i className="fa fa-check"></i> {this.state.saveText}
																		</button>
																		
																	</div>
																</div>
															</div>
												 
													 </form>
											</div>
										</div>
							    </div>
						 </section>
				</div>
			</div>
		);
	}
	
}
export default AddParish;