import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import $ from 'jquery';
import {appName,copyRight} from '../../utils/Variables';
import * as Reqst from '../../utils/Reqst';
import Sanitize from '../../utils/Sanitize';
import Notifications, {notify} from 'react-notify-toast';
import { Message,Notification } from 'element-react';
import {Helmet} from 'react-helmet';
import {Row,Col,Card} from 'react-bootstrap';
import swal from 'sweetalert';

import PageTitle from '../../containers/PageTitle';
import LOGO from '../../assets/img/logo-bebiblical.png';


const styles = {
	firstDiv: {
		 backgroundImage: 'linear-gradient(#117a92, #095369)',
		 padding: 15,
		 margin:0, 
		 minHeight: 300		
	},
	loginTitle: {
	    borderBottom: '1px solid #e0dcdc',
		paddingBottom: '7px',
		width: '95%',
	}
}

class Login extends Component{
	constructor(props){
		super(props);
		this.state = {
			loginText: 'SIGN IN',
			isRedirect: false,
			showLoader: false,
			loginFlipped: false,
		}
		
		this.handleLogin = this.handleLogin.bind(this);
	}
	
	
	handleLogin(e){
		try{
			
			e.preventDefault();
			
			//let d = new Date();
			//d.setTime(d.getTime() + (minutes*60*1000));
			//d.setTime(d.getTime() + (10*60*1000));
			//const cookies = new Cookies();
			//cookies.set('j_user_login', 'yes', {path: "/", expires: d});
			//cookies.set('j_user_login', 'yes', {path: "/"});
			//this.setState({ isRedirect: true});
			
			this.setState({showLoader:true, loginText: 'Validating..'});
			// var data = Sanitize.objectifyForm($("#frmLogin").serializeObject());
			Reqst.axPost('api/v1/login',$("#frmLogin").serialize()).then(res=>{
			//Reqst.axPost('login/check/',{'data':data}).then(res=>{
				if(res['data'].status=='1')
				{
					
					Sanitize.setStorage('accessToken', res['data'].token);
				    Sanitize.setStorage('userData', JSON.stringify(res['data'].user));
                    Sanitize.setStorage('userId', res['data'].userid);
					Sanitize.setStorage('userRole',res['data'].userrole);
					this.setState({loginText: 'SIGN IN', isRedirect: true, showLoader: false});
					Notification({
						message: res['data'].message,
						type: 'success'
					});
					
				}
				else
				{
					Message({
						message: res['data'].message,
						type: 'error'
					});
					this.setState({loginText: 'SIGN IN', showLoader: false});
					$("#i__password").val('');
				}
			});
		}
		catch(error){
			console.log(error);
		}
	}
	
	showAlert(){
		swal({
			title:'Message',
		text: 'Welcome..!',
		buttons:['No','Yes']}).then((willYes)=>{
			if(willYes){
				//alert('Ok');
			}
			else
			{
				//alert('No');
			}
				
		});
	}
	
	render(){
		
		if(Sanitize.getStoreageItem('accessToken') && this.state.isRedirect )
		{
			return (<Redirect to="/" />);
		}
	
		
		return(
			<div>
				
				<Helmet>
					<title>{appName} | User Login</title>
				</Helmet>
				
				<Notifications />
				
				 <section className="material-half-bg">
				  <div className="cover"></div>
				</section>
				
				<section className="login-content">
				  <div className="logo">
				  {/*<img src={LOGO} style={{ width: '200px' }} />*/}
				  </div>
				  <div className={this.state.loginFlipped ? 'login-box flipped' : 'login-box'} >
					<form id="frmLogin" className="login-form" action="" method="post" encType="multipart/form-data" onSubmit={this.handleLogin} >
					  <h3 className="login-head"><i className="fa fa-lg fa-fw fa-user"></i>SIGN IN</h3>
					  <div className="form-group">
						<label className="control-label">USERNAME</label>
						<input className="form-control" type="text" name="i__username" id="i__username" placeholder="Username" autofocus required />
					  </div>
					  <div className="form-group">
						<label className="control-label">PASSWORD</label>
						<input className="form-control" type="password" name="i__password" id="i__password" placeholder="Password" required />
					  </div>
				
					  <div className="form-group">
						<div className="utility">
						  
						  <p className="semibold-text mb-2"><a href="javascript:;" data-toggle="flip" onClick={()=>{this.setState({loginFlipped: true})}} >Forgot Password ?</a></p>
						</div>
					  </div>
					  <div className="form-group btn-container">
						<button type="submit" className="btn btn-primary btn-block" disabled={this.state.loginText!='SIGN IN'} >
							<i className="fa fa-sign-in fa-lg fa-fw"></i> {this.state.loginText}
						</button>
					  </div>
					</form>
					<form className="forget-form" action="index.html">
					  <h3 className="login-head"><i className="fa fa-lg fa-fw fa-lock"></i>Forgot Password ?</h3>
					  <div className="form-group">
						<label className="control-label">EMAIL</label>
						<input className="form-control" type="text" placeholder="Email" />
					  </div>
					  <div className="form-group btn-container">
						<button className="btn btn-primary btn-block"><i className="fa fa-unlock fa-lg fa-fw"></i>RESET</button>
					  </div>
					  <div className="form-group mt-3">
						<p className="semibold-text mb-0"><a href="javascript:;" data-toggle="flip" onClick={()=>{this.setState({loginFlipped: false})}}  ><i className="fa fa-angle-left fa-fw"></i> Back to Login</a></p>
					  </div>
					</form>
				  </div>
				</section>
				
			</div>
		);
	}
	
}
export default Login;