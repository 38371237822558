import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import $ from 'jquery';
import {appName,copyRight,imagePath} from '../../utils/Variables';
import * as Reqst from '../../utils/Reqst';
import Sanitize from '../../utils/Sanitize';
import {notify} from 'react-notify-toast';
import {Helmet} from 'react-helmet';
import swal from 'sweetalert';
import Cookies from 'universal-cookie';
import ReactFileReader from 'react-file-reader';
import {Button,Tooltip,Loading,Notification,Select,DatePicker } from 'element-react';
import NOIMAGE from '../../assets/img/noimage.jpg';
import AppTitle from '../../containers/AppTitle';
import FullPageLoader from '../../containers/FullPageLoader';
import SimpleReactValidator from 'simple-react-validator';

class AddCommission extends Component{
	constructor(props){
		super(props);
		this.state = {
			saveText: 'Save',
			isRedirect: false,
			expired : false,
		}
		this.validator = new SimpleReactValidator();
		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleInputBlur = this.handleInputBlur.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}
	
	componentDidMount(){
		
		
	}
	
	
	handleInputChange(e){
		this.setState({[e.target.name]: e.target.value});
	}
	

	handleInputBlur(e){
		if (!this.validator.allValid()) {
			this.validator.showMessages();			
			this.forceUpdate();
		}
	}
	
	onClear(){
		
		
		document.getElementById("frmCommission").reset();
	}
	
	handleSubmit(e){
		try{
		
			e.preventDefault();
			 if (this.validator.allValid()) {
				this.setState({saveText: 'Saving..'});
				var token = Sanitize.getStoreageItem('accessToken');
				Reqst.axPost('api/v1/add_commission',$("#frmCommission").serialize(),token).then(res=>{
					if(res['data'].status=='1')
					{
						Notification({message:res['data'].message,type: 'success'});
						document.getElementById("frmCommission").reset();
						this.setState({saveText:'Save'});
					}
					else if(res['data'].status=='2')
					{
						  alert(res['data'].message);
						  this.setState({saveText:'Save'});
						  this.setState({expired:true});
					}
					else if(res['data'].status=='3')
					{
						  Notification({message:res['data'].message,type: 'error'});
						  this.setState({saveText:'Save'});
					}
					else
					{
						Notification({message:res['data'].message,type: 'error'});
						this.setState({saveText:'Save'});
					}
				});
		  }
		   else {
			this.validator.showMessages();
			// rerender to show messages for the first time
			this.forceUpdate();
		 }
		}
		catch(e){
			console.log(e);
		}
	}
	
	render(){
		
		
		if(this.state.expired)
		{
			return (<Redirect to="/login" />);
		}
		
		
		
		return(
			<div>
				
				<Helmet>
					<title>{appName} | Configuration | Add Commission</title>
				</Helmet>
				
				
				<div className="topbar-header">
						<div className="topbar-header__content">
						
						   <AppTitle
								menuLevel1="Configuration"
								menuLevel2="Commissions"
								menuLevel3="Add Commission"
								menuLevel2link="#/configuration/commissions"
								isbreadcumb={true}
							/>
						
				       
						<div className="topbar_buttons">
								
						</div>
					 </div>
				</div>
				
				<div className="main_content">
				       <section>
							<div className="container" >
								
										<div className="card" >
											<h4 className="card-header bg-info text-white">Add Commission</h4>
											<div className="card-body" >
													<form action="" method="post" encType="multipart/form-data" id="frmCommission" onSubmit={this.handleSubmit} >
														<input type="hidden" name="i__id" value={Sanitize.getStoreageItem('userId')} />
													
													
														<div className="row">
														  <div className="col-md-12">
														  
														    
																
																<div className="row" style={{ marginBottom: 10 }}  >
																	<div className="col-md-3" >
																		<label>Name<span className="text-danger">*</span></label>
																	</div>
																	<div className="col-md-9" >
																		<input type="text" name="c_name" id="c_name" className="form-control"  onChange={this.handleInputChange} onBlur={this.handleInputBlur} required />
																		{this.validator.message('Commission Name', this.state.c_name, 'required')}
																	</div>
																</div>
																
																
														
													    </div>
													 </div>	
															
													
															<div className="row" style={{ marginBottom: 10 }}  >
																<div className="col-md-12" >
																	<div className="text-right" >
																		
																
																		<button type="submit" name="i__save" className="btn btn-success btn-sm" disabled={this.state.saveText!='Save'} >
																			<i className="fa fa-check"></i> {this.state.saveText}
																		</button>
																		
																	</div>
																</div>
															</div>
												 
													 </form>
											</div>
										</div>
							    </div>
						 </section>
				</div>
			</div>
		);
	}
	
}
export default AddCommission;