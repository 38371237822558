import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import $ from 'jquery';
import { appName, apiUrl, imagePath } from '../../utils/Variables';
import * as Reqst from '../../utils/Reqst';
import Sanitize from '../../utils/Sanitize';
import { notify } from 'react-notify-toast';
import { Helmet } from 'react-helmet';
import swal from 'sweetalert';
import Cookies from 'universal-cookie';
import ReactFileReader from 'react-file-reader';
import { Button, Tooltip, Loading, Notification, Select, DatePicker, Pagination, Radio, Tag } from 'element-react';
import NOIMAGE from '../../assets/img/noimage.jpg';
import AppTitle from '../../containers/AppTitle';
import FullPageLoader from '../../containers/FullPageLoader';
import SimpleReactValidator from 'simple-react-validator';
import { Row, Col, Table, Modal } from 'react-bootstrap';
import {CKEditor} from 'ckeditor4-react';

class DioceseList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isRedirect: false,
            dataLoading: false,
            showFilterModal: false,
            dataLoading1: true,
            expired: false,
            totalcount: 0,
            todosPerPage: 50,
            searchcolumn: '',
            offset: 0,
            addLink: false,
            states: [],
            countries: [],
            updateText: 'Update',
            activePage: 1,
            optionValue: '',
            searchcode: '',
            dataList: [],
            editData: [],
            showEditModal: false,
            tags: [],
            savedtags: [],
            files: '',
            photoname: '',
        }
        this.validator = new SimpleReactValidator();
        this.onPageChange = this.onPageChange.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.setcolumn = this.setcolumn.bind(this);
        this.onPageSizeChange = this.onPageSizeChange.bind(this);
        this.handleInputBlur = this.handleInputBlur.bind(this);
        this.onRadioChange = this.onRadioChange.bind(this);
        this.onSearchChange = this.onSearchChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleFiles = this.handleFiles.bind(this);
        this.handleContentChange = this.handleContentChange.bind(this);
    }

	handleContentChange(e){
		
		this.setState({content : e.editor.getData()});
		
		var l = this.state.editData;
        l['history'] = e.editor.getData();
        this.setState({ editData: l });
	}
	
    handleFiles(files) {
        this.setState({ files: files.base64, photoname: files.fileList[0]['name'] });
    }

    handleInputBlur(e) {
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    getData(field) {
        var l = this.state.editData;
        return l[field];
    }

    setData(field, e) {
        var l = this.state.editData;
        l[field] = e.target.value;
        this.setState({ editData: l });
    }

    setDataSelect(field, e) {
        var l = this.state.editData;
        l[field] = e;
        this.setState({ editData: l });

        if (field == 'country') {

            this.loadStates();
        }
    }

    setDataDate(value, field) {
        var l = this.state.editData;
        l[field] = value;
        this.setState({ editData: l });

    }

    getconditionname(cond) {

        var res = '';

        if (cond == '1') {

            res = 'Contains';

        }
        else if (cond == '2') {

            res = 'Not Contains';
        }
        else if (cond == '3') {

            res = 'Is Not set';
        }

        return res;

    }

    getcolumnname(cond) {

        var res = '';

        if (cond == 'name') {

            res = 'Diocese';

        }
        else if (cond == 'mobile') {

            res = 'Mobile';
        }
        else if (cond == 'email') {

            res = 'Email';
        }
        else if (cond == 'type') {

            res = 'Type';
        }

        return res;


    }


    onSearchChange() {

        var orgdata = [];

        this.setState({ savedtags: [] });

        var tags = this.state.tags;

        var column = this.state.searchcolumn;

        var value = $("#searchcode").val();

        var cond = this.state.optionValue;

        var displayname = this.getcolumnname(column) + ' ' + this.getconditionname(cond) + ' ' + value;


        if (cond == '3') {
            displayname = this.getcolumnname(column) + ' ' + this.getconditionname(cond);
            cond = ' = ';
            value = '';
        }
        else if (cond == '2') {

            cond = ' != ';
        }
        else if (cond == '1') {

            cond = ' LIKE ';
            value = '%' + value + '%';
        }


        var len = tags.length + 1;

        //value = ''{'+value+'}';

        var preisstrinval = "'";
        var postisstrinval = "'";


        var query = column + '' + cond + '' + preisstrinval + value + postisstrinval;

        this.state.tags.push({ "key": len, "name": displayname, "column": column, "value": value, "query": query });

        orgdata = this.state.tags;

        this.setState({ tags: orgdata });

        this.setState({ searchcolumn: '', searchfor: '', searchcode: '', });
        $("#searchcode").val('');

        this.loadDataFilter();
        this.loadDataFilterCount();

    }

    handleInputChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleSubmit(e) {
        try {

            e.preventDefault();
            if (this.validator.allValid()) {
                this.setState({ updateText: 'Updating..' });
                var token = Sanitize.getStoreageItem('accessToken');
                Reqst.axPost('api/v1/update_diocese', $("#frmUpdateDio").serialize(), token).then(res => {
                    if (res['data'].status == '1') {
                        Notification({ message: res['data'].message, type: 'success' });
                        //this.loadData();
                        this.loadDataCount();
                        this.setState({ updateText: 'Update', showEditModal: false, files: '', photoname: '' });
                    }
                    else if (res['data'].status == '2') {
                        alert(res['data'].message);
                        this.setState({ updateText: 'Save' });
                        this.setState({ expired: true });
                    }
                    else {
                        Notification({ message: res['data'].message, type: 'error' });
                        this.setState({ saveText: 'Save' });
                    }
                });
            }
            else {
                this.validator.showMessages();
                // rerender to show messages for the first time
                this.forceUpdate();
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    setcolumn(value) {

        this.state.searchcolumn = value;
        this.setState({ searchcolumn: value });
    }

    onRadioChange(value) {

        this.state.optionValue = value;
        this.setState({ optionValue: value });
    }

    handleClose(tag) {
        const { tags } = this.state;

        tags.splice(tags.map(el => el.key).indexOf(tag.key), 1);

        this.setState({ tag });
        this.setState({ searchcolumn: '', optionValue: '', searchcode: '' });
        $("#searchcode").val('');
        this.loadDataFilter();
        this.loadDataFilterCount();
    }

    onPageSizeChange(rpp) {

        var pc = parseInt(this.state.activePage);

		var offset = 0;//parseInt(rpp) * parseInt(pc);

		this.state.offset = offset;
		this.setState({ offset: offset });
		this.state.activePage = 1;
		this.setState({ activePage: 1 });
		this.state.todosPerPage = rpp;
		this.setState({ todosPerPage: rpp });
		this.loadData();

    }

    onPageChange(page) {

        var rpp = this.state.todosPerPage;

        var pc = parseInt(page);
		
		pc = pc - 1;

        var offset = parseInt(rpp) * parseInt(pc);

        this.state.activePage = page;
        this.setState({ activePage: page });
        this.state.offset = offset;
        this.setState({ offset: offset });
        this.loadData();

    }

    handleEdit(id, e) {
        var list = this.state.dataList;
        var index = list.findIndex(function (obj) { return obj.id == id });
        var l = list[index];
        this.setState({ editData: l, showEditModal: true });
        this.loadCountries();
    }

    handleRemove(id, name) {

        swal({
            title: 'Confirm',
            text: 'Do you want to Remove ' + name + ' Diocese ?',
            buttons: ['No', 'Yes']
        }).then((willYes) => {
            if (willYes) {

                this.removeDiocese(id);

            }
        });

    }

    removeDiocese(id) {

        var token = Sanitize.getStoreageItem('accessToken');
        var uid = Sanitize.getStoreageItem('userId');
        Reqst.axGet('api/v1/remove_diocese/' + id + '/' + uid, token).then(res => {

            let result = res['data'];

            if (result) {
                if (result.status == '1') {
                    Notification({ message: result.message, type: 'success' });
                    //this.loadData();
                    this.loadDataCount();
                }
                else if (result.status == '2') {
                    alert(result.message);
                    this.setState({ expired: true });
                }
                else {
                    Notification({ message: result.message, type: 'error' });
                }
            }
            else {

                Notification({ message: 'Something went wrong Try again...', type: 'error' });
            }
        });


    }

    loadCountries() {

        this.setState({ countries: [], countryLoading: true });
        var token = Sanitize.getStoreageItem('accessToken');
        Reqst.axGet('api/v1/getcountries', token).then(res => {

            let result = res['data'];

            if (result) {
                if (result.status == '1') {
                    this.setState({ countries: result.data, countryLoading: false });
                    this.loadStates();
                }
                else if (result.status == '2') {
                    alert(result.message);
                    this.setState({ countryLoading: false });
                    this.setState({ expired: true });
                }
                else {
                    Notification({ message: result.message, type: 'error' });
                    this.setState({ countryLoading: false });
                }
            }
            else {

                Notification({ message: 'Something went wrong Try again...', type: 'error' });
                this.setState({ countryLoading: false });
            }
        });
    }

    loadStates() {

        this.setState({ states: [], stateLoading: true });
        var country = this.getData('country');
        var token = Sanitize.getStoreageItem('accessToken');
        Reqst.axGet('api/v1/getstates/' + country, token).then(res => {

            let result = res['data'];

            if (result) {
                if (result.status == '1') {
                    this.setState({ states: result.data, stateLoading: false });
                }
                else if (result.status == '2') {
                    alert(result.message);
                    this.setState({ stateLoading: false });
                    this.setState({ expired: true });
                }
                else {
                    Notification({ message: result.message, type: 'error' });
                    this.setState({ stateLoading: false });
                }
            }
            else {

                Notification({ message: 'Something went wrong Try again...', type: 'error' });
                this.setState({ stateLoading: false });
            }
        });
    }

    componentDidMount() {

        //this.loadData();
        this.loadDataCount();
    }

    getdateoforginal(date) {

        if (date != '' && date != null) {
            var date1 = new Date(date);
            var year = date1.getFullYear();
            var month = (date1.getMonth() + 1);
            var day = date1.getDate()
            var hours = date1.getHours();
            var minutes = date1.getMinutes();
            var seconds = date1.getSeconds();
            month = month < 10 ? '0' + month : month;
            day = day < 10 ? '0' + day : day;
            var strTime = year + '-' + month + '-' + day;
            return strTime;
        }
        else {

            return '- - - -';
        }

    }

    checkIfArrayIsUnique() {
        var tags = this.state.tags;

        for (var i = 0; i < tags.length; i++) {
            for (var j = 0; j < tags.length; j++) {
                if (i != j) {
                    if (tags[i]['column'] == tags[j]['column']) {
                        return true;
                        break;// means there are duplicate values
                    }
                }
            }
        }
        return false; // means there are no duplicate values.
    }

    getdateoforginal(date) {

        if (date != '' && date != null) {
            var date1 = new Date(date);
            var year = date1.getFullYear();
            var month = (date1.getMonth() + 1);
            var day = date1.getDate()
            var hours = date1.getHours();
            var minutes = date1.getMinutes();
            var seconds = date1.getSeconds();
            month = month < 10 ? '0' + month : month;
            day = day < 10 ? '0' + day : day;
            var strTime = year + '-' + month + '-' + day;
            return strTime;
        }
        else {

            return '- - - -';
        }

    }

    loadDataFilter() {

        this.setState({ dataList: [], dataLoading: true });
        var token = Sanitize.getStoreageItem('accessToken');

        var total = this.state.totalcount;

        var limit = this.state.todosPerPage;

        var offset = this.state.offset;


        if (offset != 0) {

            limit = offset - limit;

        }
        else {

            offset = 50;
            limit = 0;

        }

        var tags = this.state.tags;
        var qdata = '';
        var cond = ' AND ';
        if (this.checkIfArrayIsUnique()) {

            cond = ' OR ';
        }
        if (tags.length > 0) {

            for (var i = 0; i < tags.length; i++) {

                if (i == 0) {

                    qdata += tags[i]['query']

                }
                else {

                    qdata += cond + tags[i]['query'];
                }

            }

        }
        /* var iscombine = ' ';

        if (this.checkIfArrayIsUnique()) {

            iscombine = ' OR ';
        } */

        qdata = qdata.replace(/,*$/, "");
        //qdata = iscombine + qdata;
        if (qdata == '') {
            qdata = 'no'
        }
        else {
            qdata = '(' + qdata + ')';
        }

        var data = { 'limit': limit, 'offset': offset, 'query': qdata };

        Reqst.axPost('api/v1/getdiocesesfilterdata', data, token).then(res => {

            let result = res['data'];

            if (result) {
                if (result.status == '1') {
                    this.setState({ dataList: result.data, dataLoading: false });
                }
                else if (result.status == '2') {
                    alert(result.message);
                    this.setState({ dataLoading: false });
                    this.setState({ expired: true });
                }
                else {
                    Notification({ message: result.message, type: 'error' });
                    this.setState({ dataLoading: false });
                }
            }
            else {

                Notification({ message: 'Something went wrong Try again...', type: 'error' });
                this.setState({ dataLoading: false });
            }
        });
    }

    loadDataFilterCount() {

        this.setState({ totalcount: 0, dataLoading: true });
        var token = Sanitize.getStoreageItem('accessToken');


        var tags = this.state.tags;
        var qdata = '';
        var cond = ' AND ';
        if (this.checkIfArrayIsUnique()) {

            cond = ' OR ';
        }
        if (tags.length > 0) {

            for (var i = 0; i < tags.length; i++) {

                if (i == 0) {

                    qdata += tags[i]['query']

                }
                else {

                    qdata += cond + tags[i]['query'];
                }

            }

        }
        /* var iscombine = ' ';

        if (this.checkIfArrayIsUnique()) {

            iscombine = ' OR ';
        } */

        qdata = qdata.replace(/,*$/, "");
        //qdata = iscombine + qdata;
        if (qdata == '') {
            qdata = 'no'
        }
        else {
            qdata = '(' + qdata + ')';
        }

        var data = { 'query': qdata };

        Reqst.axPost('api/v1/getdiocesesfilterdatacount', data, token).then(res => {

            let result = res['data'];

            if (result) {
                if (result.status == '1') {
                    this.setState({ totalcount: result.count, dataLoading: false });
                }
                else if (result.status == '2') {
                    alert(result.message);
                    this.setState({ dataLoading: false });
                    this.setState({ expired: true });
                }
                else {
                    Notification({ message: result.message, type: 'error' });
                    this.setState({ dataLoading: false });
                }
            }
            else {

                Notification({ message: 'Something went wrong Try again...', type: 'error' });
                this.setState({ dataLoading: false });
            }
        });
    }

    loadData() {

        this.setState({ dataList: []});
		this.setState({ dataLoading: true });
        var token = Sanitize.getStoreageItem('accessToken');

        var total = this.state.totalcount;

        var limit = this.state.todosPerPage;

        var offset = this.state.offset;


        if (offset != 0) {

            //limit = offset - limit;
			
			if(total < offset){
		
				limit = total;
				offset = offset - this.state.todosPerPage;
				
			}
			
			var activePage = this.state.activePage;
			
			if(activePage == 1){
			
				offset = 0;
			
		     }

        }
        else {
			
			if(total < 50){
				
				offset = 0;
				limit = total;
				
			}
			else{

				offset = 0;
				//limit = 50;
			}

        }

        var d = JSON.parse(Sanitize.getStoreageItem('userData'));

        var diocese = d.diocese;


        var data = { 'limit': limit, 'offset': offset, diocese: diocese };

        Reqst.axPost('api/v1/getdioceses', data, token).then(res => {

            let result = res['data'];

            if (result) {
                if (result.status == '1') {
                    this.setState({ dataList: result.data, dataLoading: false });
                }
                else if (result.status == '2') {
                    alert(result.message);
                    this.setState({ dataLoading: false });
                    this.setState({ expired: true });
                }
                else {
                    Notification({ message: result.message, type: 'error' });
                    this.setState({ dataLoading: false });
                }
            }
            else {

                Notification({ message: 'Something went wrong Try again...', type: 'error' });
                this.setState({ dataLoading: false });
            }
        });
    }

    loadDataCount() {

        this.setState({ totalcount: 0, dataLoading: true });
        var token = Sanitize.getStoreageItem('accessToken');

        var d = JSON.parse(Sanitize.getStoreageItem('userData'));

        var diocese = d.diocese;


        var data = { diocese: diocese };

        Reqst.axPost('api/v1/getdiocesecount', data, token).then(res => {

            let result = res['data'];

            if (result) {
                if (result.status == '1') {
                    this.setState({ totalcount: result.count, dataLoading: false });
					this.loadData();
                }
                else if (result.status == '2') {
                    alert(result.message);
                    this.setState({ dataLoading: false });
                    this.setState({ expired: true });
                }
                else {
                    Notification({ message: result.message, type: 'error' });
                    this.setState({ dataLoading: false });
                }
            }
            else {

                Notification({ message: 'Something went wrong Try again...', type: 'error' });
                this.setState({ dataLoading: false });
            }
        });
    }



    render() {

        if (this.state.addLink) {

            return (<Redirect to={'/configuration/adddiocese'} />);

        }


        let sno = this.state.offset+1;

        const selcolumns = [
            {
                value: 'name',
                label: 'Diocese'
            },
            {
                value: 'mobile',
                label: 'Mobile'
            },
            {
                value: 'email',
                label: 'Email'
            },
            {
                value: 'type',
                label: 'Type'
            }
        ];

        const types = [
            {
                value: 'diocese',
                label: 'Diocese'
            },
            {
                value: 'archdiocese',
                label: 'Archdiocese'
            }
        ];

        if (this.state.expired) {
            return (<Redirect to="/login" />);
        }



        return (
            <div>

                <Helmet>
                    <title>{appName} | Configuration | Dioceses </title>
                </Helmet>


                <div className="topbar-header">
                    <div className="topbar-header__content">

                        <AppTitle
                            menuLevel1="Configuration"
                            menuLevel2="Dioceses"
                            isbreadcumb={true}
                        />


                        <div className="topbar_buttons">
                            <div className="desktop_pagination">

                                <Pagination
                                    layout="prev,sizes,total,pager, next"
                                    total={this.state.totalcount}
                                    pageCount={3}
                                    currentPage={this.state.activePage}
                                    small={true}
                                    pageSizes={[25, 50, 75, 100]}
                                    pageSize={this.state.todosPerPage}
                                    onCurrentChange={this.state.tags.length > 0 ? '' : this.onPageChange}
                                    onSizeChange={this.state.tags.length > 0 ? '' : this.onPageSizeChange}
                                />



                            </div>
                            &nbsp;
                                {Sanitize.getStoreageItem('userRole') == 'admin' ?
                                <Tooltip className="item" content="Add an Diocese" placement="bottom" >
                                    <Button type="text" onClick={() => this.setState({ addLink: true })}>
                                        <i className="fa fa-plus-circle" style={{ fontSize: '22px' }} />
                                    </Button>
                                </Tooltip>
                                : ''}
                            &nbsp;&nbsp;&nbsp;
                             {Sanitize.getStoreageItem('userRole') == 'admin' ?
                                <Tooltip className="item" content="Filters" placement="bottom" >
                                    <Button type="text" onClick={() => this.setState({ showFilterModal: true })}>
                                        <i className="fa fa-filter" style={{ fontSize: '22px' }} />
                                    </Button>
                                </Tooltip>
                                : ''}
                        </div>
                    </div>
                </div>

                <div className="main_content">
                    <section>
                        <div className="card" >
                            <h4 className="card-header bg-info text-white">Dioceses</h4>
                            <div className="card-body" >
                                {this.state.tags.length > 0 ?
                                    <div style={{ marginBottom: '10px', marginTop: '0px' }}>
                                        {
                                            this.state.tags.map(tag => {
                                                return (
                                                    <Tag
                                                        key={tag.key}
                                                        closable={true}
                                                        type="primary"
                                                        closeTransition={false}
                                                        onClose={this.handleClose.bind(this, tag)}>{tag.name}</Tag>
                                                )
                                            })
                                        }

                                    </div>
                                    : ''}
                                <Table responsive bordered striped >
                                    <thead>
                                        <tr>
                                            <th>S.No</th>
                                            <th>Name</th>
                                            <th>Type</th>
                                            <th>Mobile</th>
                                            <th>Email</th>
                                            <th>Address</th>
                                            <th align="center">Manage</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.dataLoading ?
                                            <tr height="60"><td colSpan="7"><center>

                                                <Loading />

                                            </center>
                                            </td></tr>
                                            :
                                            this.state.dataList.length > 0 ?

                                                this.state.dataList.map((item, i) => {
                                                    return <tr>
                                                        <td width="80" >{sno++}</td>
                                                        <td>{item.name}</td>
                                                        <td style={{ textTransform: 'capitalize' }}>{item.type}</td>
                                                        <td>{item.mobile}</td>
                                                        <td>{item.email}</td>
                                                        <td>{item.address}</td>
                                                        <td align="center">
                                                            <Button type="text" onClick={this.handleEdit.bind(this, item.id)}>
                                                                <i className="fa fa-edit" style={{ fontSize: '16px' }} />
                                                            </Button>
                                                            &nbsp;
                                                     {Sanitize.getStoreageItem('userRole') == 'admin' ?
                                                                <Button type="text" onClick={() => this.handleRemove(item.id, item.name)}>
                                                                    <i className="fa fa-remove" style={{ fontSize: '16px' }} />
                                                                </Button>
                                                                : ''}
                                                        </td>

                                                    </tr>;
                                                })
                                                :
                                                <tr><td colSpan="7"><center>No Data Found...</center></td></tr>
                                        }
                                    </tbody>
                                </Table>
								 <div className="mobile_pagination">

									<Pagination
										    layout="prev,pager,next"
											pageCount={this.state.totalcount}
											currentPage={this.state.activePage}
											small={true}
											total={this.state.totalcount}
											currentPage={this.state.activePage}
											pageSizes={[25, 50, 75, 100]}
											pageSize={this.state.todosPerPage}
										onCurrentChange={this.state.tags.length > 0 ? '' : this.onPageChange}
									/>



                                </div>
                            </div>

                            <Modal size="md" show={this.state.showFilterModal} onHide={() => { this.setState({ showFilterModal: false }) }} className="right" >
                                <Modal.Header closeButton >
                                    <Modal.Title>Filters</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <label>Select Field<span className="text-danger"></span></label>
                                    <Select onChange={this.setcolumn} value={this.state.searchcolumn} style={{ width: '100%' }}>
                                        {
                                            selcolumns.map(el => {
                                                return <Select.Option key={el.value} label={el.label} value={el.value} />
                                            })
                                        }
                                    </Select>
                                    <br /><br />
                                    <Radio value="1" checked={this.state.optionValue === 1} onChange={this.onRadioChange.bind(this)}>Contains</Radio>
                                    <Radio value="2" checked={this.state.optionValue === 2} onChange={this.onRadioChange.bind(this)}>Not Contains</Radio>
                                    <Radio value="3" checked={this.state.optionValue === 3} onChange={this.onRadioChange.bind(this)}>Not Set</Radio>
                                    <br />
                                    {this.state.optionValue != 3 && this.state.optionValue != '' ?
                                        <input type="text" name="searchcode" id="searchcode" className="form-control" required />
                                        :
                                        ''}

                                    <br /> <br />

                                    {this.state.optionValue != '' && this.state.searchcolumn != '' ?

                                        <div className="text-center">
                                            <Button block onClick={this.onSearchChange} type="primary">
                                                <i className="fa fa-search" />&nbsp;Search
                                                            </Button>
                                        </div>

                                        : ''}
                                </Modal.Body>
                            </Modal>

                            <Modal size="md" show={this.state.showEditModal} onHide={() => { this.setState({ showEditModal: false }) }} className="right" >
                                <Modal.Header closeButton >
                                    <Modal.Title>Update Diocese</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <form action="" method="post" encType="multipart/form-data" id="frmUpdateDio" onSubmit={this.handleSubmit} >

                                        <input type="hidden" name="i__id" value={Sanitize.getStoreageItem('userId')} />
                                        <input type="hidden" name="tid" value={this.getData('id')} />
                                        <input type="hidden" name="d_country_form" value={this.getData('country')} />
                                        <input type="hidden" name="d_type_form" value={this.getData('type')} />
                                        <input type="hidden" name="d_state_form" value={this.getData('state')} />
                                        <input type="hidden" name="d_erected_form" value={this.getdateoforginal(this.getData('erected'))} />
                                        <input type="hidden" name="photoname" value={this.state.photoname} />
                                        <input type="hidden" name="files" value={this.state.files} />
                                        <input type="hidden" name="photo" value={this.getData('bishopphoto')} />
										<input type="hidden" name="i__content" value={this.getData('history')} />

                                        <div className="row" style={{ marginBottom: 10 }} >
                                            <div className="col-md-3" >
                                                <label>Name<span className="text-danger">*</span></label>
                                            </div>
                                            <div className="col-md-9" >
                                                <input type="text" name="d_name" id="d_name" className="form-control" required onChange={this.setData.bind(this, 'name')} value={this.getData('name')} />
                                                {this.validator.message('Diocese Name', this.getData('name'), 'required')}
                                            </div>
                                        </div>

                                        <div className="row" style={{ marginBottom: 10 }} >
                                            <div className="col-md-3" >
                                                <label>Type<span className="text-danger">*</span></label>
                                            </div>
                                            <div className="col-md-9" >

                                                <Select value={this.getData('type')} required onChange={this.setDataSelect.bind(this, 'type')} style={{ width: '100%' }}>
                                                    {
                                                        types.map(el => {
                                                            return <Select.Option key={el.value} label={el.label} value={el.value} />
                                                        })
                                                    }
                                                </Select>

                                                {this.validator.message('Type', this.getData('type'), 'required')}

                                            </div>
                                        </div>

                                        <div className="row" style={{ marginBottom: 10 }} >
                                            <div className="col-md-3" >
                                                <label>Erected<span className="text-danger">*</span></label>
                                            </div>
                                            <div className="col-md-9" >

                                                <DatePicker
                                                    value={this.getData('erected') != '0000-00-00' ? new Date(this.getData('erected')) : new Date()}
                                                    placeholder="Pick a day"
                                                    format="yyyy-MM-dd"
                                                    align="right"
                                                    onChange={(date) => { this.setDataDate(date, 'erected') }}
                                                    style={{ width: '100%' }}
                                                />

                                                {this.validator.message('Erected', this.getData('erected'), 'required')}

                                            </div>
                                        </div>

                                        <div className="row" style={{ marginBottom: 10 }} >
                                            <div className="col-md-3" >
                                                <label>Mobile<span className="text-danger">*</span></label>
                                            </div>
                                            <div className="col-md-9" >
                                                <input type="text" name="d_mobile" id="d_name" className="form-control" required onChange={this.setData.bind(this, 'mobile')} value={this.getData('mobile')} />
                                                {this.validator.message('Mobile', this.getData('mobile'), 'required|integer|min:10|max:10')}
                                            </div>
                                        </div>

                                        <div className="row" style={{ marginBottom: 10 }} >
                                            <div className="col-md-3" >
                                                <label>Country<span className="text-danger">*</span></label>
                                            </div>
                                            <div className="col-md-9" >

                                                <Select value={this.getData('country')} required onChange={this.setDataSelect.bind(this, 'country')} style={{ width: '100%' }} loading={this.state.countryLoading}>
                                                    {
                                                        this.state.countries.map(el => {
                                                            return <Select.Option key={el.id} label={el.name} value={el.id} />
                                                        })
                                                    }
                                                </Select>

                                                {this.validator.message('Country', this.getData('country'), 'required')}

                                            </div>
                                        </div>

                                        <div className="row" style={{ marginBottom: 10 }} >
                                            <div className="col-md-3" >
                                                <label>State<span className="text-danger">*</span></label>
                                            </div>
                                            <div className="col-md-9" >

                                                <Select value={this.getData('state')} required onChange={this.setDataSelect.bind(this, 'state')} style={{ width: '100%' }} loading={this.state.stateLoading}>
                                                    {
                                                        this.state.states.map(el => {
                                                            return <Select.Option key={el.id} label={el.name} value={el.id} />
                                                        })
                                                    }
                                                </Select>

                                                {this.validator.message('State', this.getData('state'), 'required')}

                                            </div>
                                        </div>

                                        <div className="row" style={{ marginBottom: 10 }} >
                                            <div className="col-md-3" >
                                                <label>Address<span className="text-danger"></span></label>
                                            </div>
                                            <div className="col-md-9" >
                                                <textarea name="d_addr" id="d_addr" className="form-control" onChange={this.setData.bind(this, 'address')} value={this.getData('address')} ></textarea>
                                                {this.validator.message('Address', this.getData('address'), '')}
                                            </div>
                                        </div>

                                        <div className="row" style={{ marginBottom: 10 }} >
                                            <div className="col-md-3" >
                                                <label>Website<span className="text-danger"></span></label>
                                            </div>
                                            <div className="col-md-9" >
                                                <input type="text" name="d_website" id="d_website" className="form-control" onChange={this.setData.bind(this, 'website')} value={this.getData('website')} />
                                                {this.validator.message('Website', this.getData('website'), '')}
                                            </div>
                                        </div>

                                        <div className="row" style={{ marginBottom: 10 }} >
                                            <div className="col-md-3" >
                                                <label>Email<span className="text-danger"></span></label>
                                            </div>
                                            <div className="col-md-9" >
                                                <input type="text" name="d_email" id="d_email" className="form-control" onChange={this.setData.bind(this, 'email')} value={this.getData('email')} />
                                                {this.validator.message('Email', this.getData('email'), 'email')}
                                            </div>
                                        </div>

                                        <div className="row" style={{ marginBottom: 10 }} >
                                            <div className="col-md-3" >
                                                <label>Phone<span className="text-danger"></span></label>
                                            </div>
                                            <div className="col-md-9" >
                                                <input type="text" name="d_phone" id="d_phone" className="form-control" onChange={this.setData.bind(this, 'phone')} value={this.getData('phone')} />
                                                {this.validator.message('Phone', this.getData('phone'), 'integer')}
                                            </div>
                                        </div>

                                        <div className="row" style={{ marginBottom: 10 }} >
                                            <div className="col-md-3" >
                                                <label>Bishop Name<span className="text-danger">*</span></label>
                                            </div>
                                            <div className="col-md-9" >
                                                <input type="text" name="b_name" id="b_name" className="form-control" required onChange={this.setData.bind(this, 'bishopname')} value={this.getData('bishopname')} />
                                                {this.validator.message('Bishop Name', this.getData('bishopname'), 'required')}
                                            </div>
                                        </div>

                                        <div className="row" style={{ marginBottom: 10 }} >
                                            <div className="col-md-3" >
                                                <label>Bishop Photo</label>
                                            </div>
                                            <div className="col-md-3" >
                                                <ReactFileReader handleFiles={this.handleFiles} fileTypes='image/*' base64={true}>
                                                    <img src={this.state.files != '' ? this.state.files : (this.getData('bishopphoto') != '' && this.getData('bishopphoto') != null) ? apiUrl + this.getData('bishopphoto') : NOIMAGE} style={{ border: '1px solid #cccccc', width: '100%', cursor: 'pointer' }} />
                                                </ReactFileReader>
                                            </div>
                                        </div>
										<div className="row" style={{ marginBottom: 10 }}>
											<div className="col-md-12">
												<label>History</label>
												<CKEditor onChange={this.handleContentChange} data={this.getData('history')} />
											</div>
										</div>
                                        <div className="row" style={{ marginBottom: 10 }} >
                                            <div className="col-md-12" >
                                                <div className="text-right" >

                                                    <button type="submit" name="i__save" className="btn btn-success btn-sm" disabled={this.state.updateText != 'Update'} >
                                                        <i className="fa fa-check"></i> {this.state.updateText}
                                                    </button>

                                                </div>
                                            </div>
                                        </div>

                                    </form>
                                </Modal.Body>
                            </Modal>
                        </div>
                    </section>
                </div>
            </div>
        );
    }

}
export default DioceseList;