import React, { Component } from 'react';

class Button extends Component {
	
	constructor(props){
		super(props);
		this.state = {
			
		}
	
	}
	
  render(){

		let btnClass = 'btn btn-default';
		if(this.props.color=='info') btnClass = 'btn btn-info';
		if(this.props.color=='primary') btnClass = 'btn btn-primary';
		if(this.props.color=='secondary') btnClass = 'btn btn-secondary';
		else if(this.props.color=='warning') btnClass = 'btn btn-warning';
		else if(this.props.color=='danger') btnClass = 'btn btn-danger';
		else if(this.props.color=='success') btnClass = 'btn btn-success';
		
		return (
		 <button type={this.props.type ? this.props.type : 'button'} className={btnClass} >
			 {this.props.children}
		 </button>
		);
	}
}

export default Button;


